import { defineComponent } from 'vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import SwitchNetworkToEthereumDialog from '@/components/Dialogs/SwitchNetworkToEthereumDialog.vue';
import BaseDialog from '@/components/Dialogs/BaseDialog.vue';
import { retrievePendingNft } from '@/common/contracts/nfts/bridge-nfts';
import { getL1BlockchainIdHex } from '@/common/utils/blockchains';
import { ElMessage } from 'element-plus';
import { connectedWallet, connectedChain } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'PendingNFTsBatchContentDialog',
  components: {
    ShadowedButton,
    SwitchNetworkToEthereumDialog,
    BaseDialog
  },
  data() {
    return {
      connectedWallet,
      connectedChain,
      isSwitchNetworkDialogVisible: false,
      isLoading: false
    };
  },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    closeDialog: {
      type: Function,
      default: () => ({})
    },
    pendingNfts: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    }
  },
  methods: {
    async handleRetrieve() {
      if (this.connectedChain?.id !== getL1BlockchainIdHex()) {
        this.isSwitchNetworkDialogVisible = true;
        return;
      }
      if (!this.connectedWallet || this.isLoading || !this.pendingNfts[0] || this.pendingNfts[0].status === 'awaitingCheckpoint') {
        return;
      }
      this.isLoading = true;
      try {
        await retrievePendingNft(this.connectedWallet, this.walletAddress, this.pendingNfts[0]);
        this.$emit('nft-retrieved');
        ElMessage({
          message: 'NFTs retrieved!',
          grouping: true,
          type: 'success'
        });
        this.closeDialog();
      } catch (e) {
        ElMessage({
          message: 'Looks like there was an error retrieving your NFTs.',
          grouping: true,
          type: 'error'
        });
      }
      this.isLoading = false;
    }
  }
});