import { defineComponent } from 'vue';
import OuterPanel from './OuterPanel.vue';
import InnerPanel from './InnerPanel.vue';
import DemoNFTItem from './DemoNFTItem.vue';
export default defineComponent({
  name: 'NFTsPanel',
  components: {
    OuterPanel,
    InnerPanel,
    DemoNFTItem
  },
  props: {
    nfts: {
      type: Array,
      default: () => []
    }
  }
});