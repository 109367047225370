import { defineComponent } from 'vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import BaseDialog from '@/components/Dialogs/BaseDialog.vue';
import ThreeQuarterRoundLoader from '@/components/Loaders/ThreeQuarterRoundLoader.vue';
import { getStandardGachaponDeployer } from '@/common/contracts/gachapons';
import { connectedWallet, getWalletSigner } from '@/common/utils/web3-onboard';
import { getL2BlockchainExplorerTxLink } from '@/common/utils/blockchains';
export default defineComponent({
  name: 'StandardGachaponDeploymentDialog',
  components: {
    ShadowedButton,
    BaseDialog,
    ThreeQuarterRoundLoader
  },
  data() {
    return {
      connectedWallet,
      isProcessing: false,
      hasFailed: false,
      gachaponAddress: '',
      gachaponDeployer: getStandardGachaponDeployer(),
      DEPLOYER_ADDRESS: process.env.NODE_ENV === 'production' ? '0x5D5F028E92a4BDC365e000D971E7E4673F0FA708' : '0x4439120dC2B88C02FF49771f1FFE8885BD4A094e'
    };
  },
  props: {
    gachaponName: {
      type: String,
      default: ''
    },
    currencyAddress: {
      type: String,
      default: ''
    },
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    closeDialog: {
      type: Function,
      default: () => ({})
    }
  },
  computed: {
    transactionLink() {
      return getL2BlockchainExplorerTxLink() + `/${this.lastTransactionHash}`;
    },
    isWaitingTransaction() {
      if (!this.isProcessing && !this.hasFailed && !this.gachaponAddress) {
        return true;
      }
      return false;
    },
    gachaponDeployerState() {
      return this.gachaponDeployer.state;
    },
    lastTransactionHash() {
      return this.gachaponDeployer.lastTransactionHash;
    },
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    }
  },
  methods: {
    handleConfigure() {
      this.$emit('configure-gachapon', this.gachaponAddress);
      this.closeDialog();
    }
  },
  async created() {
    if (!this.connectedWallet || !this.isDialogVisible || this.isProcessing) {
      return;
    }
    try {
      const walletSigner = await getWalletSigner(this.connectedWallet);
      this.gachaponAddress = await this.gachaponDeployer.deploy(walletSigner, this.walletAddress, this.gachaponName, this.currencyAddress);
      this.$emit('gachapon-deployed', this.gachaponAddress);
    } catch (e) {
      this.hasFailed = true;
    }
    setTimeout(() => this.isProcessing = false, 2250);
  },
  watch: {
    gachaponDeployerState: function (state) {
      if (state === 'transaction-accepted') {
        this.isProcessing = true;
      }
    }
  }
});