import "core-js/modules/es.array.push.js";
import { defineComponent } from 'vue';
import WalletSignIn from '@/components/Wallets/WalletSignIn.vue';
import GachaFactoryTab from './GachaFactoryTab/index.vue';
import NFTLoaderTab from './NFTLoaderTab/index.vue';
import ProfileTab from './ProfileTab/index.vue';
import { getAllGachaponsByOwnerAddress, getGachaponCategories } from '@/common/api/gachapons';
import { getGachaponManagersIndexedByGachaponAddress } from '@/common/contracts/gachapons';
import { connectedWallet } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'FactoryView',
  components: {
    WalletSignIn,
    GachaFactoryTab,
    NFTLoaderTab,
    ProfileTab
  },
  data() {
    return {
      connectedWallet,
      gachapons: new Array(),
      gachaponCategories: new Array(),
      gachaponManagers: {}
    };
  },
  computed: {
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    },
    activeTab() {
      return this.$route.params?.activeTab && this.tabLabels[this.$route.params.activeTab.toString()] ? this.$route.params.activeTab.toString() : 'gacha-factory';
    },
    tabLabels() {
      const labels = {
        'gacha-factory': 'Gacha Factory',
        'nft-loader': 'NFT Loader',
        profile: 'Profile'
      };
      return labels;
    }
  },
  methods: {
    handleTabChange(tab) {
      this.$router.push({
        name: 'factory',
        params: {
          activeTab: tab
        }
      });
    },
    async fetchOwnedGachapons(ownerAddress) {
      if (!ownerAddress) {
        return;
      }
      this.gachapons = await getAllGachaponsByOwnerAddress(ownerAddress);
    },
    async fetchGachaponManagers(gachapons) {
      if (!gachapons) {
        return;
      }
      this.gachaponManagers = await getGachaponManagersIndexedByGachaponAddress(gachapons);
    },
    async fetchGachaponCategories() {
      this.gachaponCategories = await getGachaponCategories();
    },
    async fetchGachaponsData(timeout = 0) {
      setTimeout(async () => {
        await this.fetchOwnedGachapons(this.walletAddress);
        await this.fetchGachaponManagers(this.gachapons);
      }, timeout);
    }
  },
  async created() {
    await this.fetchGachaponsData();
    await this.fetchGachaponCategories();
  },
  watch: {
    async connectedWallet() {
      await this.fetchGachaponsData();
      await this.fetchGachaponCategories();
    }
  }
});