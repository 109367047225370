import httpClient from '@/common/utils/http-client';
import { translateNftDataToNft } from './nfts';
import NFT from '@/common/types/NFT';
import PendingNFT from '@/common/types/PendingNFT';
import {PendingNFTsData} from './types';

const API_URL = process.env.NODE_ENV === 'production' ? 'https://nft-api.dokidoki.com' : 'https://nft-api-dev.dokidoki.com';

export async function getPendingNftsIndexedByTransactionHashByOwnerAddress(ownerAddress: string): Promise<Record<string, PendingNFT[]>> {
  const response = await httpClient.post(`${API_URL}`, {
    query: `{
      pendingNftWithdrawals(address: "${ownerAddress}") {
        transactionHash,
        amount,
        isBatch,
        status,
        nft {
          contractAddress
          tokenId
          chainId
          tokenUri
          artist
          description
          previewImage
          visual
          name
          maxSupply
          attributes {
            name
            value
          }
          standard
        }
      }
    }`,
  });

  const pendingNftsData: PendingNFTsData = response.data as PendingNFTsData;
  const pendingNfts: Record<string, PendingNFT[]> = {};
  if (pendingNftsData?.pendingNftWithdrawals) {
    for (const pendingNftData of pendingNftsData.pendingNftWithdrawals) {
      if (!pendingNfts[pendingNftData.transactionHash]) {
        pendingNfts[pendingNftData.transactionHash] = [];
      }

      const nft: NFT = translateNftDataToNft(pendingNftData.nft);
      pendingNfts[pendingNftData.transactionHash].push({
        ...nft,
        balance: +pendingNftData.amount,
        transactionHash: pendingNftData.transactionHash,
        status: pendingNftData.status,
        isBatch: pendingNftData.isBatch,
      });
    }
  }

  return pendingNfts;
}
