import { defineComponent } from 'vue';
export default defineComponent({
  name: 'EmbedLayout',
  components: {},
  data() {
    return {
      isAsideCollapsed: true
    };
  },
  methods: {
    toggleAside() {
      this.isAsideCollapsed = !this.isAsideCollapsed;
    },
    closeAside() {
      this.isAsideCollapsed = true;
    },
    runOnScroll({
      target: {
        scrollTop,
        clientHeight,
        scrollHeight
      }
    }) {
      if (scrollTop === 0) {
        return;
      }
      if (scrollTop + clientHeight >= scrollHeight) {
        this.$store.commit('setViewportHasReachedBottomOfPage', true);
        return;
      }
      this.$store.commit('setViewportHasReachedBottomOfPage', false);
    }
  },
  watch: {
    $route() {
      this.$store.commit('setViewportHasReachedBottomOfPage', false);
    }
  }
});