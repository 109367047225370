//import { PictureOutline as ElIconPictureOutline } from '@element-plus/icons-vue'
import { defineComponent } from 'vue';
import PendingNFTsBatchContentDialog from './PendingNFTsBatchContentDialog.vue';
import { truncate } from '@/common/utils/truncate';
export default defineComponent({
  name: 'PendingNFTCard',
  components: {
    PendingNFTsBatchContentDialog
  },
  data() {
    return {
      isDialogVisible: false
    };
  },
  props: {
    displayedNft: {
      type: Object
    },
    pendingNfts: {
      type: Array
    }
  },
  methods: {
    truncate
  }
});