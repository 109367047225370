import "core-js/modules/es.array.push.js";
import { defineComponent } from 'vue';
import { getAllVRFGachapons } from '@/common/api/gachapons';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import { getAllGachaponNftsByGachapon } from '@/common/api/nfts/gachapon-nfts';
import { getGachaponManagersIndexedByGachaponAddress } from '@/common/contracts/gachapons';
import VRFGachaponManager from '@/common/contracts/gachapons/VRFGachaponManager';
import ERC721Contract from '@/common/contracts/erc721';
import ERC1155Contract from '@/common/contracts/erc1155';
import { getL2BlockchainIdHex } from '@/common/utils/blockchains';
import SwitchNetworkToPolygonDialog from '@/components/Dialogs/SwitchNetworkToPolygonDialog.vue';
import { ElMessage } from 'element-plus';
import { connectedChain, connectedWallet, getWalletSigner } from '@/common/utils/web3-onboard';
export default defineComponent({
  components: {
    ShadowedButton,
    SwitchNetworkToPolygonDialog
  },
  data() {
    return {
      connectedChain,
      connectedWallet,
      isSwitchToPolygonDialogVisible: false,
      isProcessing: false,
      gachapons: new Array(),
      gachaponManagers: {},
      fromGachapon: null,
      toGachapon: null
    };
  },
  computed: {
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    },
    mustSwitchBlockchain() {
      return getL2BlockchainIdHex() !== this.connectedChain?.id;
    },
    filteredGachapons() {
      return this.gachapons.filter(gachapon => !gachapon.isBanned && !gachapon.isLockedForever);
    }
  },
  methods: {
    async fetchOwnedGachapons(ownerAddress) {
      if (!ownerAddress) {
        return;
      }
      this.gachapons = await getAllVRFGachapons('createdAt', 'desc', {
        ownerAddress: ownerAddress
      });
    },
    async fetchGachaponManagers(gachapons) {
      if (!gachapons) {
        return;
      }
      this.gachaponManagers = await getGachaponManagersIndexedByGachaponAddress(gachapons);
    },
    canMigrate() {
      if (this.mustSwitchBlockchain) {
        this.isSwitchToPolygonDialogVisible = true;
        return false;
      }
      if (!this.fromGachapon || !this.toGachapon || !this.gachaponManagers[this.fromGachapon.address]) {
        return false;
      }
      if (this.fromGachapon === this.toGachapon) {
        ElMessage({
          message: 'You selected twice the same gachapon.',
          grouping: true,
          type: 'error'
        });
        return false;
      }
      if (!this.toGachapon.isLocked) {
        ElMessage({
          message: 'Destination gachapon is unlocked and can\'t receive any NFT. Please lock it first.',
          grouping: true,
          type: 'error'
        });
        return false;
      }
      if (this.toGachapon.isLockedForever) {
        ElMessage({
          message: 'Destination gachapon is locked forever and can\'t receive any NFT.',
          grouping: true,
          type: 'error'
        });
        return false;
      }
      return true;
    },
    async handleMigrate() {
      if (!this.canMigrate() || !this.fromGachapon || !this.toGachapon || !this.connectedWallet) {
        return;
      }
      const gachaponManager = this.gachaponManagers[this.fromGachapon.address];
      if (!(gachaponManager instanceof VRFGachaponManager)) {
        return;
      }
      this.isProcessing = true;
      try {
        const removeNftsPromises = [];
        const erc721GachaponNfts = [];
        const erc1155GachaponNfts = [];
        const gachaponNfts = await getAllGachaponNftsByGachapon(this.fromGachapon);
        const walletSigner = await getWalletSigner(this.connectedWallet);
        for (const gachaponNft of gachaponNfts) {
          if (!gachaponNft.balance) {
            continue;
          }
          removeNftsPromises.push(gachaponManager.removeNft(walletSigner, this.walletAddress, gachaponNft));
          gachaponNft.standard === 'ERC721' ? erc721GachaponNfts.push(gachaponNft) : erc1155GachaponNfts.push(gachaponNft);
        }
        await Promise.all(removeNftsPromises);
        const transferERC721Promises = [];
        for (const erc721GachaponNft of erc721GachaponNfts) {
          transferERC721Promises.push(ERC721Contract.transfer(walletSigner, erc721GachaponNft.collectionAddress, erc721GachaponNft.tokenId, this.walletAddress, this.toGachapon.address));
        }
        const transferERC1155Promises = [];
        for (const erc1155GachaponNft of erc1155GachaponNfts) {
          while (erc1155GachaponNft.balance > 800) {
            // 800 is technical limit
            transferERC1155Promises.push(ERC1155Contract.transfer(walletSigner, erc1155GachaponNft.collectionAddress, erc1155GachaponNft.tokenId, this.walletAddress, this.toGachapon.address, 800));
            erc1155GachaponNft.balance -= 800;
          }
          transferERC1155Promises.push(ERC1155Contract.transfer(walletSigner, erc1155GachaponNft.collectionAddress, erc1155GachaponNft.tokenId, this.walletAddress, this.toGachapon.address, erc1155GachaponNft.balance));
        }
        await Promise.all([...transferERC721Promises, ...transferERC1155Promises]);
      } catch (e) {
        ElMessage({
          message: 'Something went wrong.',
          grouping: true,
          type: 'error'
        });
      }
      this.isProcessing = false;
    }
  },
  async created() {
    if (!this.walletAddress) {
      return;
    }
    await this.fetchOwnedGachapons(this.walletAddress);
    await this.fetchGachaponManagers(this.gachapons);
  },
  watch: {
    connectedWallet: async function () {
      if (!this.walletAddress) {
        return;
      }
      await this.fetchOwnedGachapons(this.walletAddress);
      await this.fetchGachaponManagers(this.gachapons);
    }
  }
});