import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_carousel = _resolveComponent("el-carousel");
  return _openBlock(), _createBlock(_component_el_carousel, {
    class: "gacha-carousel",
    arrow: "never",
    height: _ctx.height,
    trigger: _ctx.trigger,
    onChange: _ctx.onCarouselChange,
    interval: _ctx.interval,
    "indicator-position": _ctx.indicators,
    "initial-index": _ctx.initialIndex,
    type: _ctx.type
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3
  }, 8, ["height", "trigger", "onChange", "interval", "indicator-position", "initial-index", "type"]);
}