import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "buttons-container"
};
const _hoisted_2 = ["href"];
const _hoisted_3 = {
  href: "https://wallet.matic.network/bridge",
  target: "_blank",
  rel: "noopener noreferrer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AzukiSeriouslySays = _resolveComponent("AzukiSeriouslySays");
  const _component_ShadowedButton = _resolveComponent("ShadowedButton");
  const _component_BaseDialog = _resolveComponent("BaseDialog");
  return _openBlock(), _createBlock(_component_BaseDialog, {
    class: "buy-erc20-dialog",
    isDialogVisible: _ctx.isDialogVisible,
    title: "Insufficient Funds",
    width: "638px",
    onClose: _ctx.closeDialog
  }, {
    default: _withCtx(() => [_createVNode(_component_AzukiSeriouslySays, null, {
      default: _withCtx(() => [_createTextVNode(" To play this Gachapon, you need " + _toDisplayString(_ctx.tokenSymbol) + " tokens on the Polygon network. Use below links to buy and bridge the token. ", 1)]),
      _: 1
    }), _createElementVNode("div", _hoisted_1, [_createElementVNode("a", {
      href: _ctx.exchangeLink,
      target: "_blank",
      rel: "noopener noreferrer"
    }, [_createVNode(_component_ShadowedButton, {
      shadowSize: "s",
      theme: "violet-white"
    }, {
      default: _withCtx(() => [_createTextVNode("BUY " + _toDisplayString(_ctx.tokenSymbol), 1)]),
      _: 1
    })], 8, _hoisted_2), _createElementVNode("a", _hoisted_3, [_createVNode(_component_ShadowedButton, {
      shadowSize: "s",
      theme: "violet-white"
    }, {
      default: _withCtx(() => [_createTextVNode("BRIDGE")]),
      _: 1
    })])])]),
    _: 1
  }, 8, ["isDialogVisible", "onClose"]);
}