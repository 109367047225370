import { getL2TokenSymbol } from '@/common/api/erc20-token-api';
import httpClient from '@/common/utils/http-client';
import Gachapon from '@/common/types/Gachapon';
import { LegacyGachaponData } from './types';
import { getL2BlockchainId, L1_MAINNET_ID, L1_TESTNET_ID, L2_MAINNET_ID, L2_TESTNET_ID } from '@/common/utils/blockchains';
import { getAddress } from 'ethers';

const API_URL = process.env.NODE_ENV === 'production' ? 'https://api.dokikaki.com' : 'https://api-dev.dokikaki.com';
const API_CHAIN_ID_MAP: Record<number, number> = {
  [L1_MAINNET_ID]: 0,
  [L1_TESTNET_ID]: 2,
  [L2_MAINNET_ID]: 3,
  [L2_TESTNET_ID]: 4
}; // "Blockchain ID": "API CHAIN ID"

function translateLegacyGachaponDataToGachapon(legacyGachaponData: LegacyGachaponData): Gachapon {
  const currencySymbol = getL2TokenSymbol(legacyGachaponData.currency_token);

  const gachapon: Gachapon = {
    prizesAmountLeft: legacyGachaponData.card_amount,
    isBanned: legacyGachaponData.banned,
    isLocked: legacyGachaponData.maintaining,
    isLockedForever: false,
    isCurated: true,
    address: getAddress(legacyGachaponData.machine_address),
    title: legacyGachaponData.machine_title,
    price: BigInt(legacyGachaponData.play_once_price),
    currency: {
      address: legacyGachaponData.currency_token,
      symbol: currencySymbol,
      name: '',
      decimals: 18,
      isWrappedNativeToken: false,
      isSupported: true
    },
    ownerAddress: legacyGachaponData.owner,
    timesPlayed: +legacyGachaponData.total_round_count,
    ownerProfitsRate: legacyGachaponData.for_artist_rate,
    ownerProfits: BigInt(legacyGachaponData.total_amount_for_artist)+ BigInt(legacyGachaponData.total_amount_for_agent),
    version: 0,
    createdAt: new Date(legacyGachaponData.CreatedAt),
    description: legacyGachaponData.machine_description,
    roundsAmount: +legacyGachaponData.current_round_id_count,
    tokenomicsAmount: currencySymbol === 'AZUKI' || currencySymbol === 'BND'
      ? BigInt(legacyGachaponData.total_amount_for_burn)
      : currencySymbol === 'ETH'
        ? BigInt(legacyGachaponData.total_amount_for_buyback_doki)
        : 0n,
    blockchainId: getL2BlockchainId(),
    payoutAddresses: [],
    category: {
      id: 0,
      name: 'Uncategorized'
    },
    uniqueNftsLoadedAmount: 42,
    spinsAmountLeft: Infinity,
    isAllowlistOnly: false
  };

  return gachapon;
}

export async function getAllLegacyGachaponsIndexedByAddress(): Promise<Record<string, Gachapon>> {
    const response = await httpClient.get(`${API_URL}/v1/machines`, {
        params: {
            network: API_CHAIN_ID_MAP[getL2BlockchainId()]
        },
    });

    const gachapons: Record<string, Gachapon> = {};
    if (response.data) {
        const gachaponsData: LegacyGachaponData[] = response.data as LegacyGachaponData[];
        for (const gachaponData of gachaponsData) {
            const gachapon = translateLegacyGachaponDataToGachapon(gachaponData);
            gachapons[gachapon.address] = gachapon;
        }
    }

    return gachapons;
}

export async function getAllLegacyGachapons(): Promise<Gachapon[]> {
  const legacyGachaponsIndexedByAddress = await getAllLegacyGachaponsIndexedByAddress();

  return Object.values(legacyGachaponsIndexedByAddress);
}

export async function getAllLegacyGachaponsByOwnerAddress(ownerAddress: string): Promise<Gachapon[]> {
  const response = await httpClient.get(`${API_URL}/v1/user/machines`, {
    params: {
      account: ownerAddress,
      network: API_CHAIN_ID_MAP[getL2BlockchainId()]
    },
  });

  const gachapons: Gachapon[] = [];
  if (response.data) {
    const gachaponsData: LegacyGachaponData[] = response.data as LegacyGachaponData[];
    for (const gachaponData of gachaponsData) {
      const gachapon = translateLegacyGachaponDataToGachapon(gachaponData);
      gachapons.push(gachapon);
    }
  }

  return gachapons;
}

export async function syncMachine(machineAddress: string): Promise<Record<string, any>> {
  const apiChainId = API_CHAIN_ID_MAP[getL2BlockchainId()];

  return httpClient.put(`${API_URL}/v1/machine/sync?address=${machineAddress}&network=${apiChainId}`);
}
