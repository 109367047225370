import { Check as ElIconCheck } from '@element-plus/icons-vue';
import { defineComponent } from 'vue';
import { getGachaponCategories } from '@/common/api/gachapons';
export default defineComponent({
  name: 'CategoriesFilterDropdown',
  components: {
    ElIconCheck
  },
  data() {
    return {
      gachaponCategories: new Array(),
      selectedGachaponCategoryId: -1
    };
  },
  methods: {
    async fetchGachaponCategories() {
      const gachaponCategories = await getGachaponCategories();
      this.gachaponCategories = gachaponCategories.sort((categoryA, categoryB) => categoryA.name.localeCompare(categoryB.name));
    },
    handleChange(gachaponCategoryId) {
      this.selectedGachaponCategoryId = gachaponCategoryId;
      this.$emit('change', gachaponCategoryId === -1 ? undefined : gachaponCategoryId);
    }
  },
  async created() {
    await this.fetchGachaponCategories();
  }
});