import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "nft-name"
};
const _hoisted_2 = {
  class: "transfer-area"
};
const _hoisted_3 = {
  class: "transfer-button-container"
};
const _hoisted_4 = {
  key: 0,
  class: "information"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_QuantityInput = _resolveComponent("QuantityInput");
  const _component_ShadowedButton = _resolveComponent("ShadowedButton");
  const _component_BaseDialog = _resolveComponent("BaseDialog");
  return _ctx.ownedNft && _ctx.gachaponManager ? (_openBlock(), _createBlock(_component_BaseDialog, {
    key: 0,
    class: "transfer-nft-to-gachapon-dialog",
    isDialogVisible: _ctx.isDialogVisible,
    title: "Load NFT into Machine",
    width: "475px",
    onClose: _ctx.closeDialog
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.ownedNft.name), 1), _createElementVNode("div", _hoisted_2, [_createVNode(_component_QuantityInput, {
      placeholder: `Owned: ${_ctx.ownedNft.balance}`,
      quantity: _ctx.quantity,
      "onUpdate:quantity": _cache[0] || (_cache[0] = $event => _ctx.quantity = $event),
      minQuantity: 1,
      maxQuantity: _ctx.maxQuantity,
      disabled: !_ctx.isERC1155
    }, null, 8, ["placeholder", "quantity", "maxQuantity", "disabled"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_ShadowedButton, {
      shadowSize: "s",
      theme: "violet-white",
      isLoading: _ctx.loading,
      onClick: _ctx.handleLoad
    }, {
      default: _withCtx(() => [_createTextVNode(" LOAD ")]),
      _: 1
    }, 8, ["isLoading", "onClick"])])]), _ctx.isERC1155 ? (_openBlock(), _createElementBlock("div", _hoisted_4, " You can load a max of 800 copies at a time (make sure \"Gas price\" is set to 1 if you have issues). ")) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["isDialogVisible", "onClose"])) : _createCommentVNode("", true);
}