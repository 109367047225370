import httpClient from '@/common/utils/http-client';
import { getL2Provider } from '@/common/utils/ethers';

const API_URL = process.env.NODE_ENV === 'production' ? 'https://api.thegraph.com/subgraphs/name/dokidokifinance/momiji-matic' : 'https://api.thegraph.com/subgraphs/name/dokidokifinance/momiji-mumbai';

export async function getSubgraphBehindBlocks(): Promise<number> {
  const response = await httpClient.post(
    API_URL,
    {
      query: `{
        _meta {
          block {
            number
          }
        }
      }`,
    },
  );

  if (!response.data.data._meta.block.number) {
    return 0;
  }

  const lastSyncedBlock = +response.data.data._meta.block.number;

  const provider = getL2Provider();
  const latestBlockHead = await provider.getBlockNumber();

  return latestBlockHead - lastSyncedBlock;
}
