import { defineComponent } from 'vue';
import defaultAvatar from '@/app/assets/factory/default-avatar.png';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import SwitchNetworkToEthereumDialog from '@/components/Dialogs/SwitchNetworkToEthereumDialog.vue';
import { getUserNonceByAddress, getUserProfileByAddress, updateUserProfile } from '@/common/api/users';
import { ElMessage } from 'element-plus';
import { connectedWallet, getWalletSigner } from '@/common/utils/web3-onboard';
export default defineComponent({
  components: {
    ShadowedButton,
    SwitchNetworkToEthereumDialog
  },
  data() {
    return {
      connectedWallet,
      user: null,
      profileForm: {},
      showSwitchNetworkDialog: false,
      loadedImageUrl: '',
      isProcessing: false
    };
  },
  computed: {
    defaultAvatar() {
      return defaultAvatar;
    },
    profilePictureUrl() {
      if (this.loadedImageUrl) {
        return this.loadedImageUrl;
      }
      if (this.user?.profilePicture) {
        return this.user.profilePicture;
      }
      return '';
    },
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    }
  },
  methods: {
    openSwitchNetworkDialog() {
      this.showSwitchNetworkDialog = true;
    },
    closeSwitchNetworkDialog() {
      this.showSwitchNetworkDialog = false;
    },
    uploadError() {
      ElMessage({
        message: 'Failed loading image.',
        grouping: true,
        type: 'warning'
      });
    },
    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    async onImageUploadChange(image) {
      if (!image.raw) {
        return false;
      }
      const sizeLimit = image.size / 1024 / 1024 < 3;
      if (!sizeLimit) {
        ElMessage({
          message: 'Image should be less than 3MB!',
          grouping: true,
          type: 'error'
        });
        return false;
      }
      try {
        this.profileForm.profilePicture = await this.convertFileToBase64(image.raw);
        this.loadedImageUrl = URL.createObjectURL(image.raw);
        return true;
      } catch (e) {
        ElMessage({
          message: 'Something went wrong with the image!',
          grouping: true,
          type: 'error'
        });
      }
      return false;
    },
    async onSave() {
      if (!this.connectedWallet || !this.profileForm) {
        return;
      }
      this.isProcessing = true;
      try {
        const userNonce = await getUserNonceByAddress(this.walletAddress);
        if (!userNonce) {
          return;
        }
        const walletSigner = await getWalletSigner(this.connectedWallet);
        const signature = await walletSigner.signMessage(userNonce);
        this.user = await updateUserProfile(signature, this.profileForm);
        this.loadedImageUrl = '';
        ElMessage({
          message: 'Update Successfully.',
          grouping: true,
          type: 'success'
        });
      } catch (e) {
        ElMessage({
          message: 'Update Failed.',
          grouping: true,
          type: 'error'
        });
      }
      this.isProcessing = false;
    },
    async fetchUserProfile(userAddress) {
      if (!userAddress) {
        return;
      }
      this.user = await getUserProfileByAddress(userAddress);
    },
    hydrateProfileForm(userAddress, user) {
      if (!user) {
        this.profileForm = {
          address: userAddress
        };
        return;
      }
      this.profileForm = Object.assign({}, user);
      delete this.profileForm['profilePicture'];
    }
  },
  async created() {
    if (!this.connectedWallet) {
      return;
    }
    await this.fetchUserProfile(this.walletAddress);
    this.hydrateProfileForm(this.walletAddress, this.user);
  },
  watch: {
    connectedWallet: async function () {
      if (!this.connectedWallet) {
        return;
      }
      await this.fetchUserProfile(this.walletAddress);
      this.hydrateProfileForm(this.walletAddress, this.user);
    }
  }
});