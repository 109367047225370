import { defineComponent } from 'vue';
export default defineComponent({
  name: 'GachaponTypeFilter',
  data() {
    return {
      selectedGachaponType: 'all'
    };
  },
  methods: {
    handleGachaponType(type) {
      this.selectedGachaponType = type;
      this.$emit('change', type);
    }
  }
});