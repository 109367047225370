import httpClient from '@/common/utils/http-client';
import { GachaponCategoriesData } from './types';
import GachaponCategory from '@/common/types/GachaponCategory';

const API_URL = process.env.NODE_ENV === 'development'
    ? 'https://api.thegraph.com/subgraphs/name/kirienzoeth/dokidoki-gachapons-mumbai'
    : process.env.VUE_APP_USE_BACKUP_SUBGRAPH === '1'
        ? 'https://graph.dokidoki.com/subgraphs/name/DokiDokiFinance/gachapon-subgraph'
        : 'https://api.thegraph.com/subgraphs/name/kirienzoeth/dokidoki-gachapons';

export async function getGachaponCategories(): Promise<GachaponCategory[]> {
    const response = await httpClient.post(API_URL, {
        query: `{
            categories {
              id
              name
              isDisabled
              gachaponsAmount
            }
        }`,
    });

    const gachaponCategories: GachaponCategory[] = [];
    if (response.data) {
      const gachaponCategoriesData = response.data as GachaponCategoriesData;
        for (const gachaponCategoryData of gachaponCategoriesData.categories) {
          const gachaponCategory: GachaponCategory = {
            id: +gachaponCategoryData.id,
            name: gachaponCategoryData.name,
            isDisabled: gachaponCategoryData.isDisabled,
            gachaponsUsingItAmount: +gachaponCategoryData.gachaponsAmount
          };

          gachaponCategories.push(gachaponCategory);
        }
    }

    return gachaponCategories;
}
