import { defineComponent } from 'vue';
import { bigIntToString } from '@/common/utils/format-numbers';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import BigIntQuantityInput from '@/components/Inputs/BigIntQuantityInput.vue';
import BaseDialog from '@/components/Dialogs/BaseDialog.vue';
import { ElMessage } from 'element-plus';
import { connectedWallet, getWalletSigner } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'UnstakeTokenDialog',
  components: {
    ShadowedButton,
    BigIntQuantityInput,
    BaseDialog
  },
  data() {
    return {
      connectedWallet,
      quantity: 0n,
      loading: false
    };
  },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    closeDialog: {
      type: Function,
      default: () => ({})
    },
    stakingPool: {
      type: Object
    }
  },
  computed: {
    canUnstake() {
      if (!this.loading && this.connectedWallet && this.quantity > 0n && this.stakingPool && this.stakingPool.stakedAmount >= this.quantity) {
        return true;
      }
      return false;
    },
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    }
  },
  methods: {
    bigIntToString,
    async handleUnstake() {
      if (!this.stakingPool || !this.connectedWallet) {
        return;
      }
      if (!this.canUnstake) {
        ElMessage({
          message: 'Not enough token to unstake.',
          grouping: true,
          type: 'error'
        });
        return;
      }
      this.loading = true;
      try {
        const walletSigner = await getWalletSigner(this.connectedWallet);
        await this.stakingPool.unstake(walletSigner, this.walletAddress, this.quantity);
        this.$emit('token-unstaked');
        ElMessage({
          message: 'Unstaked!',
          grouping: true,
          type: 'success'
        });
      } catch (e) {
        if (e instanceof Error) {
          ElMessage({
            message: `Couldn't unstake: ${e.message}`,
            grouping: true,
            type: 'error'
          });
        }
      }
      this.loading = false;
      this.closeDialog();
    }
  }
});