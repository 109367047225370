import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_HeaderContent = _resolveComponent("HeaderContent");
  const _component_router_view = _resolveComponent("router-view");
  const _component_el_main = _resolveComponent("el-main");
  const _component_AsideContent = _resolveComponent("AsideContent");
  const _component_el_container = _resolveComponent("el-container");
  const _component_FooterContent = _resolveComponent("FooterContent");
  return _openBlock(), _createBlock(_component_el_container, {
    id: "main-container",
    direction: "vertical",
    onScroll: _ctx.runOnScroll
  }, {
    default: _withCtx(() => [_createVNode(_component_HeaderContent, {
      isAsideCollapsed: _ctx.isAsideCollapsed,
      toggleAside: _ctx.toggleAside
    }, null, 8, ["isAsideCollapsed", "toggleAside"]), _createVNode(_component_el_container, {
      id: "content-container",
      direction: "horizontal"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_main, {
        id: "content",
        onClick: _ctx.closeAside
      }, {
        default: _withCtx(() => [(_openBlock(), _createBlock(_component_router_view, {
          key: _ctx.$route.fullPath
        }, {
          default: _withCtx(({
            Component
          }) => [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_KeepAlive, {
            key: 0
          }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1024)) : (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
            key: 1
          }))]),
          _: 1
        }))]),
        _: 1
      }, 8, ["onClick"]), _createVNode(_component_AsideContent, {
        isAsideCollapsed: _ctx.isAsideCollapsed,
        closeAside: _ctx.closeAside
      }, null, 8, ["isAsideCollapsed", "closeAside"])]),
      _: 1
    }), _createVNode(_component_FooterContent)]),
    _: 1
  }, 8, ["onScroll"]);
}