import { defineComponent } from 'vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import SwitchNetworkToPolygonDialog from '@/components/Dialogs/SwitchNetworkToPolygonDialog.vue';
import VRFGachaponManager from '@/common/contracts/gachapons/VRFGachaponManager';
import { getL2BlockchainIdHex } from '@/common/utils/blockchains';
import { resolveENStoETHAddress } from '@/common/utils/ethers/ens';
import { bigIntToString } from '@/common/utils/format-numbers';
import { connectedChain, connectedWallet, getWalletSigner } from '@/common/utils/web3-onboard';
import { ElMessage } from 'element-plus';
import { isAddress } from 'ethers';
export default defineComponent({
  name: 'GeneralTab',
  components: {
    ShadowedButton,
    SwitchNetworkToPolygonDialog
  },
  data() {
    return {
      connectedWallet,
      connectedChain,
      isProcessingLock: false,
      isProcessingName: false,
      isProcessingPrice: false,
      isProcessingCategory: false,
      isProcessingDescription: false,
      isProcessingAddresses: false,
      isSwitchToPolygonDialogVisible: false,
      nameInput: this.gachaponManager?.gachapon.title,
      priceInput: bigIntToString(this.gachaponManager?.gachapon.price, this.gachaponManager?.gachapon.currency.decimals),
      selectedCategory: this.gachaponManager?.gachapon.category && this.gachaponManager.gachapon.category.id != 0 ? this.gachaponManager.gachapon.category : null,
      descriptionInput: this.gachaponManager?.gachapon.description,
      rates: this.gachaponManager?.gachapon.payoutAddresses.length > 0 ? this.gachaponManager.gachapon.payoutAddresses : new Array({
        address: '',
        rate: 100
      })
    };
  },
  props: {
    gachaponManager: {
      type: Object,
      default: null
    },
    gachaponCategories: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isWalletConnected() {
      return !!this.connectedWallet;
    },
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    },
    mustSwitchBlockchain() {
      return getL2BlockchainIdHex() !== this.connectedChain?.id;
    },
    isVRFGachapon() {
      return this.gachaponManager instanceof VRFGachaponManager;
    },
    totalPayoutRate() {
      if (!(this.gachaponManager instanceof VRFGachaponManager)) {
        return 0;
      }
      return this.rates.reduce((accumulator, rate) => accumulator + rate.rate, 0);
    },
    filteredGachaponCategories() {
      return this.gachaponCategories?.filter(gachaponCategory => !gachaponCategory.isDisabled && gachaponCategory.id != 0);
    }
  },
  methods: {
    addPayoutAddress() {
      if (this.rates.length > 10) {
        return;
      }
      this.rates = [...this.rates, {
        address: '',
        rate: 100
      }];
    },
    removePayoutAddress(index) {
      if (!this.rates[index] || index === 0) {
        return;
      }
      this.rates.splice(index, 1);
    },
    isValidAddress(address) {
      if (isAddress(address)) {
        return true;
      }
      return false;
    },
    async handleAddressPayoutChange(index) {
      if (this.isValidAddress(this.rates[index].address)) {
        return;
      }
      if (this.mustSwitchBlockchain) {
        this.isSwitchToPolygonDialogVisible = true;
        return;
      }
      try {
        this.rates[index].address = await resolveENStoETHAddress(this.rates[index].address);
      } catch (e) {
        //
      }
    },
    async handleLock() {
      if (!this.gachaponManager || !this.connectedWallet || this.isProcessingLock) {
        return;
      }
      if (this.mustSwitchBlockchain) {
        this.isSwitchToPolygonDialogVisible = true;
        return;
      }
      try {
        this.isProcessingLock = true;
        const walletSigner = await getWalletSigner(this.connectedWallet);
        await this.gachaponManager.lock(walletSigner, this.walletAddress);
        ElMessage({
          message: 'Machine locked!',
          grouping: true,
          type: 'success'
        });
        this.$emit('refresh-gachapons');
      } catch (e) {
        ElMessage({
          message: 'Operation failed',
          grouping: true,
          type: 'error'
        });
      }
      this.isProcessingLock = false;
    },
    async handleUnlock() {
      if (!this.gachaponManager || !this.connectedWallet || this.isProcessingLock) {
        return;
      }
      if (this.mustSwitchBlockchain) {
        this.isSwitchToPolygonDialogVisible = true;
        return;
      }
      try {
        this.isProcessingLock = true;
        const walletSigner = await getWalletSigner(this.connectedWallet);
        await this.gachaponManager.unlock(walletSigner, this.walletAddress);
        ElMessage({
          message: 'Machine unlocked!',
          grouping: true,
          type: 'success'
        });
        this.$emit('refresh-gachapons');
      } catch (e) {
        ElMessage({
          message: 'Operation failed',
          grouping: true,
          type: 'error'
        });
      }
      this.isProcessingLock = false;
    },
    async handleName() {
      if (!this.gachaponManager || !this.connectedWallet || !this.nameInput || this.isProcessingName) {
        return;
      }
      if (this.mustSwitchBlockchain) {
        this.isSwitchToPolygonDialogVisible = true;
        return;
      }
      try {
        this.isProcessingName = true;
        const walletSigner = await getWalletSigner(this.connectedWallet);
        await this.gachaponManager.setName(walletSigner, this.walletAddress, this.nameInput);
        ElMessage({
          message: 'Machine\'s name updated!',
          grouping: true,
          type: 'success'
        });
        this.$emit('refresh-gachapons');
      } catch (e) {
        ElMessage({
          message: 'Operation failed',
          grouping: true,
          type: 'error'
        });
      }
      this.isProcessingName = false;
    },
    async handlePrice() {
      if (!this.gachaponManager || !this.connectedWallet || !this.priceInput || this.isProcessingPrice) {
        return;
      }
      if (this.mustSwitchBlockchain) {
        this.isSwitchToPolygonDialogVisible = true;
        return;
      }
      try {
        this.isProcessingPrice = true;
        const walletSigner = await getWalletSigner(this.connectedWallet);
        await this.gachaponManager.setSpinPrice(walletSigner, this.walletAddress, +this.priceInput);
        ElMessage({
          message: 'Play price updated!',
          grouping: true,
          type: 'success'
        });
        this.$emit('refresh-gachapons');
      } catch (e) {
        ElMessage({
          message: 'Operation failed',
          grouping: true,
          type: 'error'
        });
      }
      this.isProcessingPrice = false;
    },
    async handleCategory() {
      if (!this.gachaponManager || !this.connectedWallet || this.isProcessingCategory || !(this.gachaponManager instanceof VRFGachaponManager) || !this.selectedCategory?.id) {
        return;
      }
      if (this.mustSwitchBlockchain) {
        this.isSwitchToPolygonDialogVisible = true;
        return;
      }
      try {
        this.isProcessingCategory = true;
        const walletSigner = await getWalletSigner(this.connectedWallet);
        await this.gachaponManager.setCategory(walletSigner, this.walletAddress, this.selectedCategory.id);
        ElMessage({
          message: 'Category updated!',
          grouping: true,
          type: 'success'
        });
        this.$emit('refresh-gachapons');
      } catch (e) {
        ElMessage({
          message: 'Operation failed',
          grouping: true,
          type: 'error'
        });
      }
      this.isProcessingCategory = false;
    },
    async handleDescription() {
      if (!this.gachaponManager || !this.connectedWallet || !this.descriptionInput || this.isProcessingDescription) {
        return;
      }
      if (this.mustSwitchBlockchain) {
        this.isSwitchToPolygonDialogVisible = true;
        return;
      }
      try {
        this.isProcessingDescription = true;
        const walletSigner = await getWalletSigner(this.connectedWallet);
        await this.gachaponManager.setDescription(walletSigner, this.walletAddress, this.descriptionInput);
        ElMessage({
          message: 'Machine\'s description updated!',
          grouping: true,
          type: 'success'
        });
        this.$emit('refresh-gachapons');
      } catch (e) {
        ElMessage({
          message: 'Operation failed',
          grouping: true,
          type: 'error'
        });
      }
      this.isProcessingDescription = false;
    },
    async handlePayoutAddresses() {
      if (!this.gachaponManager || !this.connectedWallet || this.isProcessingAddresses || !(this.gachaponManager instanceof VRFGachaponManager) || this.totalPayoutRate !== 100) {
        return;
      }
      if (this.mustSwitchBlockchain) {
        this.isSwitchToPolygonDialogVisible = true;
        return;
      }
      for (const rate of this.rates) {
        if (!this.isValidAddress(rate.address)) {
          return;
        }
      }
      try {
        this.isProcessingAddresses = true;
        const walletSigner = await getWalletSigner(this.connectedWallet);
        await this.gachaponManager.setPayoutAddresses(walletSigner, this.walletAddress, this.rates);
        ElMessage({
          message: 'Payout addresses updated!',
          grouping: true,
          type: 'success'
        });
        this.$emit('refresh-gachapons');
      } catch (e) {
        ElMessage({
          message: 'Operation failed',
          grouping: true,
          type: 'error'
        });
      }
      this.isProcessingAddresses = false;
    }
  }
});