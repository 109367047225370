import "core-js/modules/es.array.push.js";
import { defineComponent } from 'vue';
import PolygonTab from './PolygonTab.vue';
import EthereumTab from './EthereumTab.vue';
import PendingTab from './PendingTab.vue';
import WalletSignIn from '@/components/Wallets/WalletSignIn.vue';
import { connectedWallet } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'BridgeView',
  components: {
    PolygonTab,
    EthereumTab,
    PendingTab,
    WalletSignIn
  },
  data() {
    return {
      connectedWallet,
      pendingTabLoading: false,
      windowWidth: window?.innerWidth > 0 ? window.innerWidth : 0
    };
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    handleTabChange(tab) {
      this.$router.push({
        name: 'bridge',
        params: {
          activeTab: tab
        }
      });
    }
  },
  computed: {
    activeTab() {
      return this.tabLabels[this.$route.params.activeTab.toString()] ? this.$route.params.activeTab.toString() : 'L2';
    },
    tabLabels() {
      const labels = {
        L2: 'Polygon L2',
        L1: 'ETH L1',
        pending: 'L2 -> L1'
      };
      if (this.windowWidth >= 992) {
        labels['L2'] = 'NFTs on Polygon (Matic)';
        labels['L1'] = 'NFTs on ETH Mainnet';
        labels['pending'] = 'NFTs pending L2 -> L1';
      }
      return labels;
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  }
});