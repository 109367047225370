import { syncMachine } from '@/common/api/gachapons/legacy-gachapons';
import { getAllNftsByTokenIdsAndCollectionAddresses } from '@/common/api/nfts';
import Gachapon from '@/common/types/Gachapon';
import NFT from '@/common/types/NFT';
import { PlayableGachapon } from './types';
import { getL2BlockchainId } from '@/common/utils/blockchains';
import { getGasPrice } from '@/common/api/gas-price';
import { ContractTransactionReceipt, parseUnits, Signer } from 'ethers';
import { Erc1155__factory, LegacyGachapon__factory } from '@/contracts';
import { getDokidokiL2CollectionAddress } from '@/common/utils/nfts';
import { getL2Provider } from '@/common/utils/ethers';

const provider = getL2Provider(true);

const COLLECTION_ADDRESS = process.env.NODE_ENV === 'production' ? '0x2d0D9b4075E231bFf33141d69DF49FFcF3be7642' : '0x6674d552c61861e89c603af01775f3514801043a';
const EVENT_NAME_TRANSFER = 'TransferSingle';

async function getTokenIds(receipt: ContractTransactionReceipt): Promise<string[]> {
  const contract = await Erc1155__factory.connect(getDokidokiL2CollectionAddress(), provider);
  const eventFragment = contract.interface.getEvent(EVENT_NAME_TRANSFER);
  const logs = receipt.logs.filter(log => log.topics.indexOf(eventFragment.topicHash) >= 0);
  if (!logs) {
    return [];
  }

  const logDescriptions = logs.map((log) => contract.interface.parseLog({ data: log.data, topics: [...log.topics] }));

  const tokenIds = [];
  for (const logDescription of logDescriptions) {
    tokenIds.push(logDescription?.args[3].toString());
  }

  return tokenIds;
}

export default class LegacyPlayableGachapon implements PlayableGachapon {
    gachapon: Gachapon;
    state: 'ready' | 'waiting';

    constructor(gachapon: Gachapon) {
        this.gachapon = gachapon;
        this.state = 'ready';
    }

    async play(signer: Signer, userAddress: string, times: number): Promise<NFT[]> {
      const contract = LegacyGachapon__factory.connect(this.gachapon.address, signer);
      const gasPrice = await getGasPrice();
      const randomNumber = Math.floor(Math.random() * 10000) + 10;

      try {
        const transaction = await contract.runMachine(randomNumber, times, {
          from: userAddress,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
          gasPrice: gasPrice ? parseUnits(gasPrice.toString(), 'gwei') : null
        });

        const receipt = await transaction.wait(8);
        this.state = 'waiting';

        await syncMachine(this.gachapon.address);
        if (!receipt?.hash) {
          return [];
        }

        const tokenIds = await getTokenIds(receipt);
        if (!tokenIds) {
          return [];
        }

        this.state = 'ready';
        const collectionAddresses = Array.from(tokenIds).fill(COLLECTION_ADDRESS);

        return getAllNftsByTokenIdsAndCollectionAddresses(tokenIds, collectionAddresses, getL2BlockchainId());
      } catch (e) {
        this.state = 'ready';
        throw e;
      }
    }
}
