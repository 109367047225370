import httpClient from '@/common/utils/http-client';
import {isIPFS, extractCIDFromString} from '@/common/utils/ipfs';
import {L1_ETHEREUM_COLLECTION_ADDRESS} from '@/common/utils/nfts';

const ETHEREUM_API_URL = process.env.NODE_ENV === 'production'
  ? `https://eth-mainnet.g.alchemy.com/v2/${process.env.VUE_APP_ALCHEMY_KEY}/getNFTMetadata`
  : `https://eth-goerli.g.alchemy.com/v2/${process.env.VUE_APP_ALCHEMY_KEY}/getNFTMetadata`;

const POLYGON_API_URL = process.env.NODE_ENV === 'production'
  ? `https://polygon-mainnet.g.alchemy.com/v2/${process.env.VUE_APP_ALCHEMY_KEY}/getNFTMetadata`
  : `https://polygon-mumbai.g.alchemy.com/v2/${process.env.VUE_APP_ALCHEMY_KEY}/getNFTMetadata`;

export async function getNftVisualUrl(collectionAddress: string, tokenId: string): Promise<string> {
  if (!collectionAddress || !tokenId) {
    return '';
  }

  const url = `${POLYGON_API_URL}?contractAddress=${collectionAddress}&tokenId=${tokenId}`;
  const response = await httpClient.get(url);

  const nftVisual = response?.metadata?.image_url || response?.metadata?.image;
  let visualUrl = nftVisual;
  if (isIPFS(nftVisual)) {
    const ipfsId = extractCIDFromString(nftVisual);
    visualUrl = `https://cloudflare-ipfs.com/ipfs/${ipfsId}`;
  }

  return visualUrl;
}

export async function getDokidokiNftVisualUrl(tokenId: string): Promise<string> {
  if (!tokenId) {
    return '';
  }

  const url = `${ETHEREUM_API_URL}?contractAddress=${L1_ETHEREUM_COLLECTION_ADDRESS}&tokenId=${tokenId}`;
  const response = await httpClient.get(url);

  const nftVisual = response?.metadata?.image_url || response?.metadata?.image;
  let visualUrl = nftVisual;
  if (isIPFS(nftVisual)) {
    const ipfsId = extractCIDFromString(nftVisual);
    visualUrl = `https://cloudflare-ipfs.com/ipfs/${ipfsId}`;
  }

  return visualUrl;
}
