import httpClient from '@/common/utils/http-client';
import {PolygonGasPriceAPIResponse} from '@/common/api/gas-price/types';

const API_URL = process.env.NODE_ENV === 'production' ? 'https://api.polygonscan.com/api?module=gastracker&action=gasoracle&apikey=A1KF8TS4DTHU19EUP89XFT87XTXUQPTWRR' : '';

export async function getGasPrice(): Promise<null|number> {
  try {
    const response = await httpClient.get(API_URL) as PolygonGasPriceAPIResponse;

    return Math.ceil(+response.result.FastGasPrice);
  } catch (e) {
    //
  }

  return null;
}


