import {
  stakingPoolsMetadata as dokidokiStakingPoolsMetadata,
  rewardableTokens as dokidokiRewardableTokens
} from './metadata/dokidoki';
import { stakingPoolsMetadata as comethStakingPoolsMetadata } from './metadata/cometh';
import DokiDokiABIStakingPool from './DokiDokiABIStakingPool';
import ComethABIStakingPool from './ComethABIStakingPool';
import { StakingPool } from './types';

const L1Pools: StakingPool[] = [];
const L2Pools: StakingPool[] = [];

for (const dokidokiStakingPoolMetadata of dokidokiStakingPoolsMetadata) {
  L1Pools.push(new DokiDokiABIStakingPool(dokidokiStakingPoolMetadata, dokidokiRewardableTokens));
}

for (const comethStakingPoolMetadata of comethStakingPoolsMetadata) {
  L2Pools.push(new ComethABIStakingPool(comethStakingPoolMetadata));
}

export function getL1Pools(): StakingPool[]  {
  return L1Pools;
}

export function getL2Pools(): StakingPool[] {
  return L2Pools;
}
