import { defineComponent } from 'vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import erc20Contract from '@/common/contracts/erc20';
import BaseDialog from './BaseDialog.vue';
import AzukiSeriouslySays from './AzukiSeriouslySays.vue';
import { ElMessage } from 'element-plus';
import { connectedWallet, getWalletSigner } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'ApproveGachaponDialog',
  components: {
    ShadowedButton,
    BaseDialog,
    AzukiSeriouslySays
  },
  data() {
    return {
      connectedWallet,
      loading: false
    };
  },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    closeDialog: {
      type: Function,
      default: () => ({})
    },
    machineAddress: {
      type: String,
      default: ''
    },
    tokenAddress: {
      type: String,
      default: ''
    },
    playPrice: {
      type: BigInt,
      default: 0n
    }
  },
  computed: {
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    }
  },
  methods: {
    async handleApprove() {
      if (!this.connectedWallet) {
        return;
      }
      this.loading = true;
      try {
        const walletSigner = await getWalletSigner(this.connectedWallet);
        const approvedAmount = await erc20Contract.allowance(walletSigner, this.walletAddress, this.machineAddress, this.tokenAddress);
        const isApproved = approvedAmount >= this.playPrice;
        if (!isApproved) {
          await erc20Contract.approveAll(walletSigner, this.walletAddress, this.machineAddress, this.tokenAddress);
          ElMessage({
            message: 'Machine approved!',
            grouping: true,
            type: 'success'
          });
        }
        this.$emit('amount-approved');
        this.closeDialog();
      } catch (e) {
        if (e instanceof Error) {
          ElMessage({
            message: `Couldn't approve the machine: ${e.message}`,
            grouping: true,
            type: 'success'
          });
        }
      }
      this.loading = false;
    }
  }
});