import GachaponNFT from '@/common/types/GachaponNFT';
import Gachapon from '@/common/types/Gachapon';
import { getGachaponNftsByGachapon as getVRFGachaponNftsByGachapon } from './gachapon-nfts';
import { getAllLegacyGachaponNftsByGachapon } from './legacy-gachapon-nfts';
import { VRF_GACHAPON_VERSION_NUMBER } from '@/common/contracts/gachapons/const';

export * from './nfts';
export * from './owned-nfts';

export async function getGachaponNftsByGachapon(gachapon: Gachapon, from = 0, limit = 100): Promise<GachaponNFT[]> {
  if (gachapon.version >= VRF_GACHAPON_VERSION_NUMBER) {
    return getVRFGachaponNftsByGachapon(gachapon, from, limit);
  }

  return getAllLegacyGachaponNftsByGachapon(gachapon);
}
