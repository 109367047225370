import { defineComponent } from 'vue';
import BaseDialog from '@/components/Dialogs/BaseDialog.vue';
import ThreeQuarterRoundLoader from '@/components/Loaders/ThreeQuarterRoundLoader.vue';
import { getUserNonceByAddress } from '@/common/api/users';
import { updateGachaponAllowlist } from '@/common/api/gachapons/allowlists';
import { getL2BlockchainExplorerTxLink } from '@/common/utils/blockchains';
import { connectedWallet, getWalletSigner } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'ApplyCsvDialog',
  components: {
    BaseDialog,
    ThreeQuarterRoundLoader
  },
  data() {
    return {
      connectedWallet,
      isProcessing: false,
      hasFailed: false,
      hasSigned: false,
      isSuccessful: false,
      merkleRoot: ''
    };
  },
  props: {
    allowlistSpots: {
      type: Array,
      default: () => []
    },
    gachaponManager: {
      type: Object,
      default: null
    },
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    closeDialog: {
      type: Function
    }
  },
  computed: {
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    },
    isWaitingTransaction() {
      if (!this.isProcessing && !this.hasFailed) {
        return true;
      }
      return false;
    },
    gachaponManagerState() {
      return this.gachaponManager.state;
    },
    lastTransactionHash() {
      return this.gachaponManager.lastTransactionHash;
    },
    transactionLink() {
      return getL2BlockchainExplorerTxLink() + `/${this.gachaponManager.lastTransactionHash}`;
    }
  },
  async created() {
    if (!this.connectedWallet || !this.isDialogVisible) {
      return;
    }
    try {
      this.hasSigned = false;
      const walletSigner = await getWalletSigner(this.connectedWallet);
      const userNonce = await getUserNonceByAddress(this.walletAddress);
      const signature = await walletSigner.signMessage(userNonce);
      this.hasSigned = true;
      this.merkleRoot = await updateGachaponAllowlist(signature, this.gachaponManager.gachapon, this.allowlistSpots);
    } catch (e) {
      this.hasFailed = true;
    }
  },
  watch: {
    gachaponManagerState: function (state) {
      if (state === 'transaction-accepted') {
        this.isProcessing = true;
      }
    },
    merkleRoot: async function () {
      if (!this.merkleRoot || !this.connectedWallet) {
        return;
      }
      try {
        const walletSigner = await getWalletSigner(this.connectedWallet);
        await this.gachaponManager.setAllowListMerkleRoot(walletSigner, this.walletAddress, this.merkleRoot);
        this.isSuccessful = true;
      } catch (e) {
        this.hasFailed = true;
      }
      this.isProcessing = false;
    }
  }
});