import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "social-buttons-container"
};
const _hoisted_2 = ["href"];
const _hoisted_3 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AzukiSeriouslySays = _resolveComponent("AzukiSeriouslySays");
  const _component_ShadowedButton = _resolveComponent("ShadowedButton");
  const _component_BaseDialog = _resolveComponent("BaseDialog");
  return _ctx.owner ? (_openBlock(), _createBlock(_component_BaseDialog, {
    key: 0,
    class: "locked-gachapon-dialog",
    isDialogVisible: _ctx.isDialogVisible,
    title: "This Gachapon is locked",
    width: "638px",
    onClose: _ctx.closeDialog
  }, {
    default: _withCtx(() => [_createVNode(_component_AzukiSeriouslySays, null, {
      default: _withCtx(() => [_createTextVNode(" The owner of this machine " + _toDisplayString(_ctx.owner.username) + " has locked the machine so no one can play. Follow " + _toDisplayString(_ctx.owner.username) + " on social media for news and updates. ", 1)]),
      _: 1
    }), _createElementVNode("div", _hoisted_1, [_ctx.isWebsiteLinkValid() ? (_openBlock(), _createElementBlock("a", {
      key: 0,
      href: _ctx.owner.twitterLink,
      target: "_blank",
      rel: "noopener noreferrer"
    }, [_createVNode(_component_ShadowedButton, {
      shadowSize: "s",
      theme: "violet-white"
    }, {
      default: _withCtx(() => [_createTextVNode("TWITTER")]),
      _: 1
    })], 8, _hoisted_2)) : _createCommentVNode("", true), _ctx.isWebsiteLinkValid() ? (_openBlock(), _createElementBlock("a", {
      key: 1,
      href: _ctx.owner.websiteLink,
      target: "_blank",
      rel: "noopener noreferrer"
    }, [_createVNode(_component_ShadowedButton, {
      shadowSize: "s",
      theme: "violet-white"
    }, {
      default: _withCtx(() => [_createTextVNode("WEBSITE")]),
      _: 1
    })], 8, _hoisted_3)) : _createCommentVNode("", true)])]),
    _: 1
  }, 8, ["isDialogVisible", "onClose"])) : _createCommentVNode("", true);
}