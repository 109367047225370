import { defineComponent } from 'vue';
import BaseDialog from '@/components/Dialogs/BaseDialog.vue';
import AzukiSays from '@/components/Dialogs/AzukiSays.vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import ThreeQuarterRoundLoader from '@/components/Loaders/ThreeQuarterRoundLoader.vue';
import PasteInput from '@/components/Inputs/PasteInput.vue';
import QuantityInput from '@/components/Inputs/QuantityInput.vue';
import ERC721ContractClass from '@/common/contracts/erc721/ERC721Contract';
import ERC721Contract from '@/common/contracts/erc721';
import ERC1155Contract from '@/common/contracts/erc1155';
import { getDokidokiNftVisualUrl, getNftVisualUrl } from '@/common/api/nfts/nft-metadata';
import { isAddressDokidokiCollection } from '@/common/utils/nfts';
import { ElMessage } from 'element-plus';
import { connectedChain, connectedWallet, getWalletSigner } from '@/common/utils/web3-onboard';
import useClipboard from 'vue-clipboard3';
export default defineComponent({
  name: 'TransferCustomCollectionNFTToGachaponDialog',
  components: {
    QuantityInput,
    BaseDialog,
    AzukiSays,
    PasteInput,
    ShadowedButton,
    ThreeQuarterRoundLoader
  },
  data() {
    return {
      connectedWallet,
      connectedChain,
      platformUrl: '',
      quantity: 0,
      maxQuantity: 800,
      ownedQuantity: 0n,
      nftTokenId: '',
      nftCollectionAddress: '',
      nftVisualUrl: '',
      hasFoundNft: false,
      isLoading: false,
      isPreviewLoading: false,
      imageLoadFailed: false
    };
  },
  props: {
    gachapon: {
      type: Object
    },
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    closeDialog: {
      type: Function
    }
  },
  computed: {
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    },
    isPlatformUrlValid() {
      if (!this.platformUrl) {
        return false;
      }
      const match = this.platformUrl.match(/^(?:http(s)?:\/\/)?(testnets.opensea.io\/assets\/mumbai\/0x[0-9a-fA-F]+\/[0-9a-zA-Z]+|opensea.io\/assets\/matic\/0x[0-9a-fA-F]+\/[0-9a-zA-Z]+)(\/)?$/);
      return !!match;
    },
    hasError() {
      if (!this.platformUrl || !this.isPlatformUrlValid || !this.quantity) {
        return true;
      }
      return false;
    }
  },
  methods: {
    async copyUrlToClipboard() {
      if (!this.gachapon) {
        return;
      }
      await this.copyToClipboard(this.gachapon.address);
    },
    async getNftContract() {
      if (!this.connectedWallet || !this.platformUrl || !this.nftCollectionAddress) {
        return null;
      }
      const walletSigner = await getWalletSigner(this.connectedWallet);
      if (await ERC1155Contract.supportsInterface(walletSigner, this.nftCollectionAddress)) {
        return ERC1155Contract;
      }
      if (await ERC721Contract.supportsInterface(walletSigner, this.nftCollectionAddress)) {
        return ERC721Contract;
      }
      return null;
    },
    async fetchOwnedQuantity() {
      const nftContract = await this.getNftContract();
      if (!nftContract || !this.connectedWallet || !this.walletAddress || !this.nftCollectionAddress || !this.nftTokenId) {
        return;
      }
      const walletSigner = await getWalletSigner(this.connectedWallet);
      this.ownedQuantity = await nftContract.balanceOf(walletSigner, this.walletAddress, this.nftCollectionAddress, this.nftTokenId);
    },
    async fetchNftVisualUrl() {
      if (!this.nftTokenId || !this.nftCollectionAddress) {
        return;
      }
      try {
        this.isPreviewLoading = true;
        this.nftVisualUrl = isAddressDokidokiCollection(this.nftCollectionAddress) ? await getDokidokiNftVisualUrl(this.nftTokenId) : await getNftVisualUrl(this.nftCollectionAddress, this.nftTokenId);
      } catch (e) {
        ElMessage({
          message: 'Couldn\'t fetch NFT preview.',
          grouping: true,
          type: 'error'
        });
      }
    },
    async handleTransfer() {
      const nftContract = await this.getNftContract();
      if (!nftContract || this.hasError || this.isLoading || !this.gachapon || !this.connectedWallet) {
        return;
      }
      this.isLoading = true;
      try {
        const walletSigner = await getWalletSigner(this.connectedWallet);
        await nftContract.transfer(walletSigner, this.nftCollectionAddress, this.nftTokenId, this.walletAddress, this.gachapon.address, this.quantity);
        ElMessage({
          message: 'NFT loaded in gachapon.',
          grouping: true,
          type: 'success'
        });
        this.resetData();
        this.platformUrl = '';
      } catch (e) {
        ElMessage({
          message: 'Couldn\'t load NFT in gachapon.',
          grouping: true,
          type: 'error'
        });
      }
      this.isLoading = false;
    },
    async processGivenUrl() {
      const cleanedUrl = this.platformUrl.replace(/\/$/, '');
      const cleanedUrlParts = cleanedUrl.split('/');
      const tokenId = cleanedUrlParts.pop();
      const collectionAddress = cleanedUrlParts.pop();
      if (!tokenId || !collectionAddress) {
        return;
      }
      this.nftTokenId = tokenId;
      this.nftCollectionAddress = collectionAddress;
    },
    resetData() {
      this.quantity = 0;
      this.ownedQuantity = 0n;
      this.nftTokenId = '';
      this.nftCollectionAddress = '';
      this.nftVisualUrl = '';
      this.hasFoundNft = false;
      this.imageLoadFailed = false;
    }
  },
  watch: {
    platformUrl: async function () {
      this.resetData();
      if (!this.platformUrl || !this.isPlatformUrlValid) {
        return;
      }
      this.isLoading = true;
      try {
        await this.processGivenUrl();
        await this.fetchOwnedQuantity();
        this.hasFoundNft = true;
      } catch (e) {
        ElMessage({
          message: 'Something went wrong with the provided link.',
          grouping: true,
          type: 'error'
        });
        this.hasFoundNft = false;
        console.error(e);
      }
      if (this.hasFoundNft) {
        await this.fetchNftVisualUrl();
      }
      this.isLoading = false;
    },
    ownedQuantity: async function () {
      const nftContract = await this.getNftContract();
      if (!nftContract) {
        return;
      }
      if (this.ownedQuantity === 1n && nftContract instanceof ERC721ContractClass) {
        this.quantity = 1;
      }
    }
  },
  setup() {
    const {
      toClipboard
    } = useClipboard();
    const copyToClipboard = async text => {
      try {
        await toClipboard(text);
        ElMessage({
          message: 'Copied to clipboard!',
          grouping: true,
          type: 'success'
        });
      } catch (e) {
        ElMessage({
          message: 'Failed to copy.',
          grouping: true,
          type: 'error'
        });
      }
    };
    return {
      copyToClipboard
    };
  }
});