import Gachapon from '@/common/types/Gachapon';

export function getGachaponState(gachapon: Gachapon|null): 'empty' | 'available' | 'locked' | 'sold-out' {
  if (!gachapon || gachapon.isLocked || gachapon.isLockedForever) {
    return 'locked';
  }

  if (gachapon.uniqueNftsLoadedAmount === 0) {
    return 'empty';
  }

  if (gachapon.prizesAmountLeft === 0 && gachapon.timesPlayed > 0) {
    return 'sold-out';
  }

  if (gachapon.prizesAmountLeft > 0) {
    return 'available';
  }

  return 'locked';
}

export function getSortedByStateGachapons(gachapons: Gachapon[]): Record<string, Gachapon[]> {
  const orderedByStateGachapons: Record<string, Gachapon[]> = {};
  for (const gachapon of gachapons) {
    const state = getGachaponState(gachapon);
    if (!state) {
      continue;
    }

    if (!orderedByStateGachapons[state]) {
      orderedByStateGachapons[state] = [];
    }

    orderedByStateGachapons[state].push(gachapon);
  }

  return orderedByStateGachapons;
}
