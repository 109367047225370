import { defineComponent } from 'vue';
import wait from '@/common/utils/wait';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import PrizeBall from './PrizeBall.vue';
const prizeOpenMusic = require('@/app/assets/gachapon-machine/audio/prize_open.mp3');
export default defineComponent({
  components: {
    ShadowedButton,
    PrizeBall
  },
  name: 'PrizesDialog',
  data() {
    return {
      openedPrizes: 0,
      openedPrizesIndexesSet: new Set(),
      areAllPrizesOpened: false,
      mutePrizeBalls: false
    };
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function
    },
    nfts: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    canOpenAllPrizes() {
      if (this.nfts.length - this.openedPrizes >= 1) {
        return true;
      }
      return false;
    }
  },
  methods: {
    async openAllPrizes() {
      this.areAllPrizesOpened = true;
      this.mutePrizeBalls = true;
      this.playMusic();
      await wait(1);
      this.mutePrizeBalls = false;
    },
    handlePrizeOpen(prizeIndex) {
      this.openedPrizes++;
      if (this.areAllPrizesOpened) {
        return;
      }
      this.openedPrizesIndexesSet = new Set(this.openedPrizesIndexesSet.add(prizeIndex)); // Have to redeclare a new Set to keep variable reactivity...
    }
  },

  setup() {
    const music = new Audio(prizeOpenMusic);
    function playMusic() {
      music.currentTime = 0;
      music.play();
      music.volume = 0.2;
    }
    return {
      playMusic
    };
  }
});