import { defineComponent } from 'vue';
import NFTVisual from '@/components/NFT/NFTVisual.vue';
const prizeOpenMusic = require('@/app/assets/gachapon-machine/audio/prize_open.mp3');
export default defineComponent({
  name: 'PrizeBall',
  components: {
    NFTVisual
  },
  data() {
    return {
      displayDefaultInitialState: false,
      displayDefaultAnimatedState: false,
      initialStateImageFolder: '/gachapons/prize-balls/initial',
      animatedStateImageFolder: '/gachapons/prize-balls/animated',
      currentState: 'initial',
      isViewerOpened: false
    };
  },
  props: {
    nft: {
      type: Object
    },
    gachaponAddress: {
      type: String,
      default: 'default'
    },
    index: {
      type: Number,
      default: 0
    },
    isMuted: {
      type: Boolean,
      default: false
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    initialStateImage() {
      return `${this.initialStateImageFolder}/${this.gachaponAddress}.png`;
    },
    animatedStateImage() {
      return `${this.animatedStateImageFolder}/${this.gachaponAddress}.gif?${this.index}`;
    },
    defaultInitialStateImage() {
      return `${this.initialStateImageFolder}/default.png`;
    },
    defaultAnimatedStateImage() {
      return `${this.animatedStateImageFolder}/default.gif?${this.index}`;
    }
  },
  methods: {
    preloadImages() {
      const initialStateImage = new Image();
      initialStateImage.src = this.initialStateImage;
      initialStateImage.addEventListener('error', () => {
        const defaultImage = new Image();
        defaultImage.src = this.defaultInitialStateImage;
      });
      const animatedStateImage = new Image();
      animatedStateImage.src = this.animatedStateImage;
      animatedStateImage.addEventListener('error', () => {
        const defaultImage = new Image();
        defaultImage.src = this.defaultAnimatedStateImage;
      });
    },
    openPrize() {
      if (this.currentState !== 'initial') {
        return;
      }
      this.$emit('opened');
      this.currentState = 'animated';
      if (!this.isMuted) {
        this.playMusic();
      }
      setTimeout(() => {
        this.currentState = 'final';
      }, 1950);
    },
    openViewer() {
      this.isViewerOpened = true;
    },
    closeViewer() {
      this.isViewerOpened = false;
    }
  },
  mounted() {
    this.preloadImages();
  },
  setup() {
    const music = new Audio(prizeOpenMusic);
    function playMusic() {
      music.currentTime = 0;
      music.play();
      music.volume = 0.2;
    }
    return {
      playMusic
    };
  },
  watch: {
    open: function () {
      if (!this.open) {
        return;
      }
      this.openPrize();
    }
  }
});