import { defineComponent } from 'vue';
import { getL1BlockchainIdHex } from '@/common/utils/blockchains';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import BaseDialog from './BaseDialog.vue';
import { approveL1Contract, depositNfts, isL1ContractApproved } from '@/common/contracts/nfts/bridge-nfts';
import { ElMessage } from 'element-plus';
import { connectedWallet, connectedChain } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'TransferNFTsToPolygonDialog',
  components: {
    ShadowedButton,
    BaseDialog
  },
  data() {
    return {
      connectedWallet,
      connectedChain,
      mustApproveFirst: true,
      loading: false
    };
  },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    closeDialog: {
      type: Function,
      default: () => ({})
    },
    selectedNfts: {
      type: Array,
      default: []
    }
  },
  computed: {
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    }
  },
  methods: {
    async handleApprove() {
      if (this.connectedChain?.id !== getL1BlockchainIdHex() || !this.connectedWallet) {
        return;
      }
      await approveL1Contract(this.connectedWallet, this.walletAddress);
    },
    async checkApproval() {
      if (this.connectedChain?.id !== getL1BlockchainIdHex() || !this.connectedWallet) {
        return;
      }
      this.mustApproveFirst = !(await isL1ContractApproved(this.connectedWallet, this.walletAddress));
    },
    async handleDeposit() {
      if (this.connectedChain?.id !== getL1BlockchainIdHex() || this.selectedNfts.length <= 0 || !this.connectedWallet || this.loading) {
        return;
      }
      this.loading = true;
      try {
        const nftBalances = this.selectedNfts.map(ownedNft => ownedNft.balance);
        await depositNfts(this.connectedWallet, this.walletAddress, this.selectedNfts, nftBalances);
        this.$emit('nfts-transferred');
        ElMessage({
          message: 'Transfer inbound!',
          grouping: true,
          type: 'success'
        });
        this.closeDialog();
      } catch (e) {
        ElMessage({
          message: 'Couldn\'t start the transfer',
          grouping: true,
          type: 'error'
        });
      }
      this.loading = false;
    }
  },
  async created() {
    await this.checkApproval();
  },
  watch: {
    connectedWallet: async function () {
      await this.checkApproval();
    },
    connectedChain: async function () {
      await this.checkApproval();
    }
  }
});