import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "home-view-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_GachaponSection = _resolveComponent("GachaponSection");
  const _component_DescriptionSection = _resolveComponent("DescriptionSection");
  const _component_FactorySection = _resolveComponent("FactorySection");
  const _component_StepsSection = _resolveComponent("StepsSection");
  const _component_MarketplaceSection = _resolveComponent("MarketplaceSection");
  const _component_FeaturesSection = _resolveComponent("FeaturesSection");
  const _component_FeesSection = _resolveComponent("FeesSection");
  const _component_TokenSection = _resolveComponent("TokenSection");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_GachaponSection), _createVNode(_component_DescriptionSection), _createVNode(_component_FactorySection), _createVNode(_component_StepsSection), _createVNode(_component_MarketplaceSection), _createVNode(_component_FeaturesSection), _createVNode(_component_FeesSection), _createVNode(_component_TokenSection)]);
}