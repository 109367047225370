import { getL1TokenSymbol } from '@/common/api/erc20-token-api';
import { StakingPoolMetadata } from '@/common/contracts/staking-pools/types';
import { L1_MAINNET_ID } from '@/common/utils/blockchains';
import ERC20Token from '@/common/types/ERC20Token';

export const rewardableTokens: ERC20Token[] = [{
  address: '0x910524678C0B1B23FFB9285a81f99C29C11CBaEd',
  name: '',
  symbol: getL1TokenSymbol('0x910524678C0B1B23FFB9285a81f99C29C11CBaEd'),
  decimals: 18,
  isWrappedNativeToken: false,
  isSupported: false
}];

export const stakingPoolsMetadata: StakingPoolMetadata[] = [
    {
        address: '0x4a5573eE3F333260DB50A385F6fFDAc440fc80b1', // DOKI
        stakingToken: {
          address: '0x9cEB84f92A0561fa3Cc4132aB9c0b76A59787544',
          name: 'doki',
          symbol: getL1TokenSymbol('0x9cEB84f92A0561fa3Cc4132aB9c0b76A59787544'),
          decimals: 18,
          isWrappedNativeToken: false,
          isSupported: false
        },
        exchangeURL: 'https://v2.info.uniswap.org/token/0x9ceb84f92a0561fa3cc4132ab9c0b76a59787544',
        isClaimAndWithdraw: false,
        chainId: L1_MAINNET_ID,
        isDepreciated: false,
    },
    {
        address: '0xB89cf3528A3a62C2f58BDbcFd7C15312a33ce91D', // DOKI-ETH LP
        stakingToken: {
          address: '0x1D4b2B2a2Ca8762410801b51f128B73743439E39',
          name: 'doki-eth',
          symbol: getL1TokenSymbol('0x1D4b2B2a2Ca8762410801b51f128B73743439E39'),
          decimals: 18,
          isWrappedNativeToken: false,
          isSupported: false
        },
        exchangeURL: 'https://v2.info.uniswap.org/pair/0x1d4b2b2a2ca8762410801b51f128b73743439e39',
        isClaimAndWithdraw: false,
        chainId: L1_MAINNET_ID,
        isDepreciated: false,
    },
    {
        address: '0xdf4F609134a84aae1D18dCe8d863b099c6455598', // AZUKI
        stakingToken: {
          address: '0x910524678C0B1B23FFB9285a81f99C29C11CBaEd',
          name: 'azuki',
          symbol: getL1TokenSymbol('0x910524678C0B1B23FFB9285a81f99C29C11CBaEd'),
          decimals: 18,
          isWrappedNativeToken: false,
          isSupported: false
        },
        exchangeURL: 'https://v2.info.uniswap.org/token/0x910524678C0B1B23FFB9285a81f99C29C11CBaEd',
        isClaimAndWithdraw: false,
        chainId: L1_MAINNET_ID,
        isDepreciated: false,
    },
    {
        address: '0x27599F0b45008dAD28899e8E278ab191673C9179', // AZUKI-ETH LP
        stakingToken: {
          address: '0x654def3E97C3F4218C3f49ace81687483C361b2b',
          name: 'azuki-eth',
          symbol: getL1TokenSymbol('0x654def3E97C3F4218C3f49ace81687483C361b2b'),
          decimals: 18,
          isWrappedNativeToken: false,
          isSupported: false
        },
        exchangeURL: 'https://v2.info.uniswap.org/pair/0x654def3e97c3f4218c3f49ace81687483c361b2b',
        isClaimAndWithdraw: false,
        chainId: L1_MAINNET_ID,
        isDepreciated: false,
    }
];
