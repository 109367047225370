import { defineComponent } from 'vue';
import { getGachaponColors } from '@/common/api/gachapons/customization';
import { getGachaponState } from '@/common/utils/gachapons';
import { bigIntToDisplayableString } from '@/common/utils/format-numbers';
export default defineComponent({
  name: 'GachaponCard',
  data() {
    return {
      displayDefaultInitialState: false,
      initialStateImageFolder: '/gachapons/machines/initial',
      ownerData: null,
      backgroundColor: '#FFD6E7',
      gachaponState: getGachaponState(this.gachapon || null)
    };
  },
  props: {
    gachapon: {
      type: Object
    },
    isDirectAccessLinkEnabled: {
      type: Boolean,
      default: true
    },
    owner: {
      type: Object
    }
  },
  computed: {
    initialStateImage() {
      if (!this.gachapon) {
        return '';
      }
      return `${this.initialStateImageFolder}/${this.gachapon.address}.png`;
    },
    defaultInitialStateImage() {
      return `${this.initialStateImageFolder}/default.png`;
    },
    gachaponPrice() {
      if (!this.gachapon) {
        return '0';
      }
      return bigIntToDisplayableString(this.gachapon.price, this.gachapon.currency.decimals);
    },
    isGachaponAvailable() {
      return this.gachaponState === 'available';
    },
    ownerProfilePicture() {
      return this.owner?.profilePicture || '';
    }
  },
  methods: {
    fetchBackgroundColor() {
      if (!this.gachapon) {
        return;
      }
      const gachaponColor = getGachaponColors(this.gachapon.address);
      if (!gachaponColor.textBoxColor) {
        return;
      }
      this.backgroundColor = gachaponColor.textBoxColor;
    }
  },
  mounted() {
    this.fetchBackgroundColor();
  }
});