import { defineComponent } from 'vue';
import MusicNoteIcon from '@/components/Icons/MusicNote.vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
export default defineComponent({
  name: 'Music',
  components: {
    ShadowedButton,
    MusicNoteIcon
  },
  data() {
    return {
      playDefaultMusic: false,
      musicFolder: '/gachapons/machines/music',
      isPlaying: false
    };
  },
  props: {
    gachaponAddress: String
  },
  computed: {
    music() {
      return `${this.musicFolder}/${this.gachaponAddress}.mp3`;
    },
    defaultMusic() {
      return `${this.musicFolder}/default.mp3`;
    }
  },
  methods: {
    togglePlayMusic() {
      if (!this.$refs.music) {
        return;
      }
      if (this.isPlaying) {
        this.isPlaying = false;
        this.$refs.music.pause();
      } else {
        this.isPlaying = true;
        this.$refs.music.currentTime = 0;
        this.$refs.music.loop = true;
        this.$refs.music.volume = 0.1;
        this.$refs.music.play();
      }
    }
  }
});