import coinbaseWalletModule from '@web3-onboard/coinbase';
import injectedModule from '@web3-onboard/injected-wallets';
import ledgerModule from '@web3-onboard/ledger';
import mewWalletModule from '@web3-onboard/mew-wallet';
import portisModule from '@web3-onboard/portis';
import walletConnectModule from '@web3-onboard/walletconnect';
import { init, useOnboard } from '@web3-onboard/vue';
import {
  getL1BlockchainExplorerLink,
  getL2BlockchainExplorerLink,
  L1_MAINNET_ID,
  L1_TESTNET_ID,
  L2_MAINNET_ID,
  L2_TESTNET_ID
} from '@/common/utils/blockchains';
import { ConnectOptions } from '@web3-onboard/core';
import { ethers, Signer } from 'ethers';
import { WalletState } from '@web3-onboard/core/dist/types';
import { Chain } from '@web3-onboard/common/dist/types';

export const L1_CHAIN: Chain = process.env.NODE_ENV === 'production'
  ? {
  id: `0x${L1_MAINNET_ID.toString(16)}`,
  token: 'ETH', label: 'Ethereum Mainnet',
  rpcUrl: process.env.VUE_APP_ETHEREUM_RPC_WSS_URL || '',
  blockExplorerUrl: getL1BlockchainExplorerLink()
}
  : {
  id: `0x${L1_TESTNET_ID.toString(16)}`,
  token: 'ETH',
  label: 'Goerli',
  rpcUrl: process.env.VUE_APP_ETHEREUM_RPC_WSS_URL || '',
  blockExplorerUrl: getL1BlockchainExplorerLink()
};

export const L2_CHAIN: Chain = process.env.NODE_ENV === 'production'
  ? {
  id: `0x${L2_MAINNET_ID.toString(16)}`,
  token: 'MATIC',
  label: 'Polygon',
  rpcUrl: process.env.VUE_APP_POLYGON_RPC_WSS_URL || '',
  blockExplorerUrl: getL2BlockchainExplorerLink()
}
  : {
  id: `0x${L2_TESTNET_ID.toString(16)}`,
  token: 'MATIC',
  label: 'Polygon - Mumbai',
  rpcUrl: process.env.VUE_APP_POLYGON_RPC_WSS_URL || '',
  blockExplorerUrl: getL2BlockchainExplorerLink()
};

const coinbaseWallet = coinbaseWalletModule({ darkMode: true });
const injected = injectedModule();
const ledger = ledgerModule();
const mewWallet = mewWalletModule();
const portis = portisModule({ apiKey: process.env.VUE_APP_WALLET_PORTIS_API_KEY || '' });
const walletConnect = walletConnectModule({ connectFirstChainId: true });

const onboard = init({
    wallets: [coinbaseWallet, injected, ledger, mewWallet, portis, walletConnect],
    chains: [L1_CHAIN, L2_CHAIN],
    accountCenter: {
      desktop: { enabled: false },
      mobile: { enabled: false }
    }
});

export const { wallets, connectWallet: connect, connectedWallet, connectedChain, setChain } = useOnboard();

export const connectWallet = (options?: ConnectOptions) => connect(options);

export const rememberWallets = () => {
    const walletsSub = onboard.state.select('wallets');
    const { unsubscribe } = walletsSub.subscribe((wallets) => {
        const connectedWallets = wallets.map(({ label }) => label);
        window.localStorage.setItem('connectedWallets', JSON.stringify(connectedWallets));
    });

    return unsubscribe;
};

export const connectToRememberedWallets = async () => {
    if (!window?.localStorage) {
        return;
    }

    const savedWallets = window.localStorage.getItem('connectedWallets');
    if (!savedWallets) {
        return;
    }

    const previouslyConnectedWallets = JSON.parse(savedWallets);
    if (previouslyConnectedWallets) {
        await onboard.connectWallet({
            autoSelect: { label: previouslyConnectedWallets[0], disableModals: true }
        });
    }
};

export const getWalletSigner = async (connectedWallet: WalletState): Promise<Signer> => {
  const provider = new ethers.BrowserProvider(connectedWallet.provider);
  return provider.getSigner();
};
