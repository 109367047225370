import httpClient from '@/common/utils/http-client';
import ERC20Token from '@/common/types/ERC20Token';
import { CurrenciesData } from '@/common/api/currencies/types';

const API_URL = process.env.NODE_ENV === 'development'
    ? 'https://api.thegraph.com/subgraphs/name/kirienzoeth/dokidoki-gachapons-mumbai'
    : process.env.VUE_APP_USE_BACKUP_SUBGRAPH === '1'
        ? 'https://graph.dokidoki.com/subgraphs/name/DokiDokiFinance/gachapon-subgraph'
        : 'https://api.thegraph.com/subgraphs/name/kirienzoeth/dokidoki-gachapons';

export async function getCurrencies(): Promise<ERC20Token[]> {
    const response = await httpClient.post(API_URL, {
      query: `{
        currencies(where: {isSupported: true}) {
          id
          name
          symbol
          decimals
          isWrappedNativeToken
          isSupported
        }
      }`
    });

    const currenciesData = response.data as CurrenciesData;
    const currencies: ERC20Token[] = [];
    if (!currenciesData.currencies) {
      return currencies;
    }

    for (const currencyData of currenciesData.currencies) {
      const currency: ERC20Token = {
        address: currencyData.id,
        symbol: currencyData.symbol,
        name: currencyData.name,
        decimals: currencyData.decimals,
        isSupported: currencyData.isSupported,
        isWrappedNativeToken: currencyData.isWrappedNativeToken
      };
      currencies.push(currency);
    }

    return currencies;
}
