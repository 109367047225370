import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "pending-tab"
};
const _hoisted_2 = {
  class: "pending-nfts-list nfts-list"
};
const _hoisted_3 = {
  class: "pending-nfts-list-header"
};
const _hoisted_4 = {
  key: 0,
  class: "collection-list"
};
const _hoisted_5 = {
  class: "button"
};
const _hoisted_6 = {
  key: 1,
  class: "loading-nfts"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_RefreshButton = _resolveComponent("RefreshButton");
  const _component_ShadowedButton = _resolveComponent("ShadowedButton");
  const _component_PendingNFTCard = _resolveComponent("PendingNFTCard");
  const _component_ElIconLoading = _resolveComponent("ElIconLoading");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_RetrieveNFTDialog = _resolveComponent("RetrieveNFTDialog");
  const _component_SwitchNetworkToEthereumDialog = _resolveComponent("SwitchNetworkToEthereumDialog");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_RefreshButton, {
    onClick: _ctx.fetchNfts
  }, null, 8, ["onClick"])]), _ctx.pendingNftsIndexedByTransactionHash ? (_openBlock(), _createElementBlock("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pendingNftsIndexedByTransactionHash, (pendingNfts, transactionHash) => {
    return _openBlock(), _createBlock(_component_PendingNFTCard, {
      key: transactionHash,
      displayedNft: pendingNfts[0],
      pendingNfts: pendingNfts,
      onNftRetrieved: _ctx.fetchNfts
    }, {
      button: _withCtx(() => [_createElementVNode("div", _hoisted_5, [pendingNfts[0].status === 'awaitingCheckpoint' ? (_openBlock(), _createBlock(_component_ShadowedButton, {
        key: 0,
        isDisabled: true,
        theme: "violet-white",
        shadowSize: "s"
      }, {
        default: _withCtx(() => [_createTextVNode(" IN PROGRESS ")]),
        _: 1
      })) : pendingNfts[0].status === 'claimable' ? (_openBlock(), _createBlock(_component_ShadowedButton, {
        key: 1,
        theme: "violet-white",
        shadowSize: "s",
        onClick: $event => _ctx.openRetrieveNFTDialog(pendingNfts[0])
      }, {
        default: _withCtx(() => [_createTextVNode(" RETRIEVE ")]),
        _: 2
      }, 1032, ["onClick"])) : _createCommentVNode("", true)])]),
      _: 2
    }, 1032, ["displayedNft", "pendingNfts", "onNftRetrieved"]);
  }), 128))])) : _createCommentVNode("", true), _ctx.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_el_icon, null, {
    default: _withCtx(() => [_createVNode(_component_ElIconLoading)]),
    _: 1
  })])) : _createCommentVNode("", true)]), _ctx.selectedNft ? (_openBlock(), _createBlock(_component_RetrieveNFTDialog, {
    key: 0,
    isDialogVisible: !!_ctx.selectedNft,
    closeDialog: _ctx.closeRetrieveNFTDialog,
    selectNft: _ctx.selectedNft,
    onNftRetrieved: _ctx.fetchNfts
  }, null, 8, ["isDialogVisible", "closeDialog", "selectNft", "onNftRetrieved"])) : _createCommentVNode("", true), _createVNode(_component_SwitchNetworkToEthereumDialog, {
    isDialogVisible: _ctx.isSwitchNetworkDialogVisible,
    closeDialog: _ctx.closeSwitchNetworkDialog
  }, null, 8, ["isDialogVisible", "closeDialog"])]);
}