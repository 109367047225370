import { defineComponent } from 'vue';
import MustToken from '@/app/assets/currencies/must_token_icon.png';
import AzukiToken from '@/app/assets/currencies/azuki_token_icon.png';
import DokiToken from '@/app/assets/currencies/doki_token_icon.png';
import EthereumToken from '@/app/assets/currencies/eth_token_icon.png';
import ChainbindersToken from '@/app/assets/currencies/chainbinders_token_icon.png';
import LinkToken from '@/app/assets/currencies/link_token_icon.png';
import TitanToken from '@/app/assets/currencies/titan_token_icon.png';
import UsdcToken from '@/app/assets/currencies/usdc_token_icon.png';
import DaiToken from '@/app/assets/currencies/dai_token_icon.png';
import GhstToken from '@/app/assets/currencies/ghst_token_icon.png';
import MaticToken from '@/app/assets/currencies/matic_token_icon.png';
export default defineComponent({
  name: 'CurrencyIcon',
  props: {
    name: {
      type: String,
      default: 'ethereum'
    }
  },
  computed: {
    currencyImage() {
      switch (this.name.toLowerCase()) {
        case 'doki':
          return DokiToken;
        case 'azuki':
          return AzukiToken;
        case 'bnd':
          return ChainbindersToken;
        case 'must':
          return MustToken;
        case 'link':
          return LinkToken;
        case 'titan':
          return TitanToken;
        case 'usdc':
          return UsdcToken;
        case 'dai':
          return DaiToken;
        case 'ghst':
          return GhstToken;
        case 'matic' || 'wmatic':
          return MaticToken;
        default:
          return EthereumToken;
      }
    }
  }
});