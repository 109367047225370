import OwnedNFT from '@/common/types/OwnedNFT';
import { TransferableNFT } from './types';
import { getGasPrice } from '@/common/api/gas-price';
import { Erc721__factory } from '@/contracts';
import { parseUnits, Signer } from 'ethers';

export default class ERC721Token implements TransferableNFT {
  nft: OwnedNFT;

  constructor(nft: OwnedNFT) {
    this.nft = nft;
  }

  async transfer(signer: Signer, fromAddress: string, toAddress: string): Promise<void> {
    const gasPrice = await getGasPrice();

    const contract = Erc721__factory.connect(this.nft.collectionAddress, signer);
    const transaction = await contract['safeTransferFrom(address,address,uint256)'](fromAddress, toAddress, this.nft.tokenId, {
      from: fromAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: gasPrice ? parseUnits(gasPrice.toString(), 'gwei') : null
    });

    await transaction.wait();
  }
}
