import { defineComponent } from 'vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import { ElMessage } from 'element-plus';
export default defineComponent({
  name: 'PasteInput',
  components: {
    ShadowedButton
  },
  data() {
    return {
      localValue: this.value
    };
  },
  props: {
    value: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleChange(newValue) {
      this.$emit('update:value', newValue);
    },
    async handlePaste() {
      if (!navigator?.clipboard) {
        return;
      }
      try {
        const clipboardValue = await navigator.clipboard.readText();
        if (!clipboardValue) {
          return;
        }
        this.localValue = clipboardValue;
        this.$emit('update:value', clipboardValue);
      } catch (e) {
        ElMessage({
          message: 'Your browser does not handle this feature.',
          grouping: true,
          type: 'error'
        });
      }
    }
  },
  watch: {
    value: async function () {
      this.localValue = this.value;
    }
  }
});