import { Histogram as ElIconHistogram } from '@element-plus/icons-vue';
import { defineComponent } from 'vue';
import VRFGachaponManager from '@/common/contracts/gachapons/VRFGachaponManager';
import GeneralTab from './GeneralTab.vue';
import AllowlistTab from './AllowlistTab/index.vue';
import StatsTab from './StatsTab.vue';
import { ALLOWLIST_SUPPORT_GACHAPON_VERSION_NUMBER } from '@/common/contracts/gachapons/const';
export default defineComponent({
  name: 'GachaponConfigurationDrawer',
  components: {
    GeneralTab,
    AllowlistTab,
    StatsTab,
    ElIconHistogram
  },
  data() {
    return {
      ALLOWLIST_SUPPORT_GACHAPON_VERSION_NUMBER
    };
  },
  props: {
    gachaponManager: {
      type: Object
    },
    gachaponCategories: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isVRFGachaponManager() {
      return this.gachaponManager instanceof VRFGachaponManager;
    }
  }
});