import { defineComponent } from 'vue';
export default defineComponent({
  name: 'NFTVisual',
  props: {
    source: {
      type: String
    },
    isImage: {
      type: Boolean,
      default: false
    },
    isVideo: {
      type: Boolean,
      default: false
    },
    controls: {
      type: Boolean,
      default: true
    }
  }
});