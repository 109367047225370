import { defineComponent } from 'vue';
import BaseDialog from '@/components/Dialogs/BaseDialog.vue';
import AzukiSays from '@/components/Dialogs/AzukiSays.vue';
export default defineComponent({
  name: 'CSVGeneralGuideDialog',
  components: {
    BaseDialog,
    AzukiSays
  },
  data() {
    return {
      isInstructionsDialogVisible: false,
      currentStep: 'general',
      dialogTitle: {
        general: 'CSV General Guide',
        instructions: 'Example .csv and instructions',
        allowlist: 'Allowlist Example',
        discount: 'Discount Example',
        freePlay: 'Free Play Example',
        combination: 'Combination Example'
      }
    };
  },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    closeDialog: {
      type: Function
    }
  }
});