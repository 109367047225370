import "core-js/modules/es.array.push.js";
import { defineComponent } from 'vue';
import Carousel from '@/components/Carousel/index.vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import { getGachaponNftsByGachapon } from '@/common/api/nfts';
import GachaponCard from '@/components/Gachapon/GachaponCard.vue';
import { getLegacyGachaponsGlobalPlayStats, getVRFGachapons, getVRFGachaponsGlobalPlayStats } from '@/common/api/gachapons';
export default defineComponent({
  name: 'HeaderCarousel',
  components: {
    Carousel,
    GachaponCard,
    ShadowedButton
  },
  data() {
    return {
      carouselActiveIndex: 0,
      gachapons: [],
      carouselGachaponNfts: [],
      gachaponsGlobalPlayStats: null
    };
  },
  props: {
    owners: {
      type: Object
    }
  },
  methods: {
    async fetchGachapons() {
      this.gachapons = await getVRFGachapons(0, 3, 'createdAt', 'desc', {
        locked: false,
        empty: false,
        curated: true,
        banned: false
      });
    },
    async fetchCarouselGachaponNfts(gachapons) {
      for (const carouselGachapon of gachapons) {
        const gachaponNfts = await getGachaponNftsByGachapon(carouselGachapon, 0, 4);
        this.carouselGachaponNfts.push(gachaponNfts.slice(0, 4));
      }
    },
    async fetchGachaponsGlobalPlayStats() {
      const legacyGachaponsGlobalPlayStats = await getLegacyGachaponsGlobalPlayStats();
      const VRFGachaponsGlobalPlayStats = await getVRFGachaponsGlobalPlayStats();
      this.gachaponsGlobalPlayStats = {
        createdGachaponsAmount: legacyGachaponsGlobalPlayStats.createdGachaponsAmount + VRFGachaponsGlobalPlayStats.createdGachaponsAmount,
        distributedNftsAmount: legacyGachaponsGlobalPlayStats.distributedNftsAmount + VRFGachaponsGlobalPlayStats.distributedNftsAmount,
        uniquePlayersAmount: legacyGachaponsGlobalPlayStats.uniquePlayersAmount + VRFGachaponsGlobalPlayStats.uniquePlayersAmount
      };
    },
    carouselChange(active) {
      this.carouselActiveIndex = active;
    }
  },
  async created() {
    await this.fetchGachapons();
    await this.fetchCarouselGachaponNfts(this.gachapons);
    await this.fetchGachaponsGlobalPlayStats();
  }
});