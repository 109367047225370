import { MutationTree } from 'vuex';
import { State } from './state';

export interface Mutations extends MutationTree<State> {
  setViewportHasReachedBottomOfPage(state: State, hasReachedBottomOfPage: boolean): void;
}

const mutations: Mutations = {
  setViewportHasReachedBottomOfPage(state: State, hasReachedBottomOfPage: boolean) {
    if (state.viewport.hasReachedBottomOfPage === hasReachedBottomOfPage) {
      return;
    }

    state.viewport.hasReachedBottomOfPage = hasReachedBottomOfPage;
  }
};

export default mutations;
