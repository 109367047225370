import { defineComponent } from 'vue';
import { connectWallet, connectedWallet } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'Wallet',
  components: {},
  data() {
    return {
      connectedWallet
    };
  },
  methods: {
    connectWallet
  },
  computed: {
    isWalletConnected() {
      return !!this.connectedWallet;
    },
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    },
    truncatedWalletAddress() {
      if (!this.connectedWallet) {
        return '';
      }
      return `${this.walletAddress.substring(0, 4)}...${this.walletAddress.substring(this.walletAddress.length - 4)}`;
    }
  }
});