import { defineComponent } from 'vue';
import { bigIntToString } from '@/common/utils/format-numbers';
export default defineComponent({
  name: 'StatsTab',
  props: {
    gachapon: {
      type: Object,
      default: null
    }
  },
  computed: {
    ownerProfits() {
      return bigIntToString(this.gachapon.ownerProfits, this.gachapon.currency.decimals);
    },
    timesPlayed() {
      if (!this.gachapon) {
        return 0;
      }
      return this.gachapon.timesPlayed || 0;
    },
    nftsLeft() {
      if (!this.gachapon) {
        return 0;
      }
      return this.gachapon.prizesAmountLeft || 0;
    }
  }
});