import axios from 'axios';

const coingeckoAPIUrl = 'https://api.coingecko.com/api/v3';

export interface CoingeckoPrices {
  [address: string]: {
    [currency: string]: number;
  };
}

/**
 * Get the prices of the provided tokens
 *
 * @param tokenAddresses Addresses of the contracts of the tokens you want the price of
 * @returns A dictionary with key: contract address and for each contract address currency: price
 */
export async function getTokensPrices(tokenAddresses: string[]): Promise<CoingeckoPrices> {
  if (tokenAddresses.length === 0) {
    return {};
  }

  const currencies = ['usd'];
  return (await axios.get(`${coingeckoAPIUrl}/simple/token_price/ethereum?contract_addresses=${tokenAddresses.join()}&vs_currencies=${currencies.join()}`)).data;
}

