import { ERC1155 } from './types';
import { getGasPrice } from '@/common/api/gas-price';
import { parseUnits, Signer } from 'ethers';
import { Erc1155__factory } from '@/contracts';

export default class ERC1155Contract implements ERC1155 {
  async uri(signer: Signer, collectionAddress: string, tokenId: string): Promise<string> {
    const contract = Erc1155__factory.connect(collectionAddress, signer);

    return contract.uri(tokenId);
  }

  async supportsInterface(signer: Signer, collectionAddress: string): Promise<boolean> {
    const contract = Erc1155__factory.connect(collectionAddress, signer);

    return contract.supportsInterface('0xd9b67a26');
  }

  async balanceOf(signer: Signer, userAddress: string, collectionAddress: string, tokenId: string): Promise<bigint> {
    const contract = Erc1155__factory.connect(collectionAddress, signer);

    return contract.balanceOf(userAddress, tokenId);
  }

  async transfer(signer: Signer, collectionAddress: string, tokenId: string, fromAddress: string, toAddress: string, quantity: number): Promise<void> {
    const gasPrice = await getGasPrice();

    const contract = Erc1155__factory.connect(collectionAddress, signer);

    const transaction = await contract.safeTransferFrom(fromAddress, toAddress, tokenId, quantity, '0x', {
      from: fromAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: gasPrice ? parseUnits(gasPrice.toString(), 'gwei') : null
    });

    await transaction.wait();
  }
}
