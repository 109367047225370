import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "big-int-quantity-input-container"
};
const _hoisted_2 = ["placeholder", "value", "disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ShadowedButton = _resolveComponent("ShadowedButton");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("input", {
    class: "big-number-quantity-input",
    type: "text",
    placeholder: _ctx.placeholder,
    value: _ctx.bigIntToString(_ctx.quantity, _ctx.decimals) === '0n' ? undefined : _ctx.bigIntToString(_ctx.quantity, _ctx.decimals),
    disabled: _ctx.disabled,
    onChange: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => _ctx.handleChange && _ctx.handleChange(...args))
  }, null, 40, _hoisted_2), _createElementVNode("div", null, [_createVNode(_component_ShadowedButton, {
    shadowSize: "xs",
    theme: "violet-white",
    isDisabled: _ctx.disabled,
    onClick: _ctx.setMaxQuantity
  }, {
    default: _withCtx(() => [_createTextVNode(" MAX ")]),
    _: 1
  }, 8, ["isDisabled", "onClick"])])]);
}