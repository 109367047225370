import CsvAllowlistSpot from '@/common/types/CsvAllowlistSpot';
import AllowlistSpot from '@/common/types/AllowlistSpot';
import { isAddress } from 'ethers';

export function remapCsvAllowlistSpotToAllowlistSpot(csvAllowlistSpot: CsvAllowlistSpot): AllowlistSpot {
  if (!isAddress(csvAllowlistSpot.address)) {
    throw new Error('Invalid wallet address');
  }

  const price = BigInt(csvAllowlistSpot.price);
  if (csvAllowlistSpot.price === '' || price < 0n) {
    throw new Error('Price is not a valid number');
  }

  const playAmountAllowed = BigInt(csvAllowlistSpot.playcount);
  if (playAmountAllowed <= 0n) {
    throw new Error('Playcount is not a valid number');
  }

  return {
    walletAddress: csvAllowlistSpot.address,
    playAmountAllowed: playAmountAllowed,
    price: price
  };
}
