import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "gachapon-stats-container"
};
const _hoisted_2 = {
  class: "stat"
};
const _hoisted_3 = {
  key: 0,
  class: "stat"
};
const _hoisted_4 = {
  key: 1,
  class: "stat"
};
const _hoisted_5 = {
  class: "stat"
};
const _hoisted_6 = {
  class: "stat"
};
const _hoisted_7 = {
  class: "stat"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("p", _hoisted_2, " Artist earnings: " + _toDisplayString(_ctx.ownerProfits) + " " + _toDisplayString(this.gachapon.currency.symbol), 1), _ctx.burnedAmount ? (_openBlock(), _createElementBlock("p", _hoisted_3, " Burned amount: " + _toDisplayString(_ctx.burnedAmount) + " " + _toDisplayString(this.gachapon.currency.symbol), 1)) : _ctx.buybackAmount ? (_openBlock(), _createElementBlock("p", _hoisted_4, " $DOKI buyback: " + _toDisplayString(_ctx.buybackAmount) + " " + _toDisplayString(this.gachapon.currency.symbol), 1)) : _createCommentVNode("", true), _createElementVNode("p", _hoisted_5, "Number of plays: " + _toDisplayString(_ctx.timesPlayed), 1), _createElementVNode("p", _hoisted_6, " NFTs remaining: " + _toDisplayString(_ctx.nftsLeft) + " (" + _toDisplayString(_ctx.totalAddedNfts > 0 ? (_ctx.nftsLeft / _ctx.totalAddedNfts * 100).toFixed(1) : 0) + "%) ", 1), _createElementVNode("p", _hoisted_7, "Total NFTs loaded: " + _toDisplayString(_ctx.totalAddedNfts), 1)]);
}