import { defineComponent } from 'vue';
export default defineComponent({
  name: 'FooterContent',
  data() {
    return {
      socialMedias: [{
        name: 'Twitter',
        icon: 'icon-twitter',
        link: 'https://twitter.com/dokidokinft'
      }, {
        name: 'Discord',
        icon: 'icon-discord',
        link: 'https://discord.com/invite/K32BeEY'
      }, {
        name: 'Instagram',
        icon: 'icon-instagram',
        link: 'https://www.instagram.com/dokidoki.degacha/'
      }]
    };
  }
});