import httpClient from '@/common/utils/http-client';
import { NFTsData, NFTData } from './types';
import NFT from '@/common/types/NFT';
import { isVideo } from '@/common/utils/videos';

const API_URL = process.env.NODE_ENV === 'production' ? 'https://nft-api.dokidoki.com' : 'https://nft-api-dev.dokidoki.com';

export function translateNftDataToNft(nftData: NFTData): NFT {
  return {
    tokenId: nftData.tokenId,
    name: nftData.name || nftData.tokenId,
    description: nftData.description || '',
    storageUri: nftData.tokenUri || '',
    maxSupply: +nftData.maxSupply || 0,
    visual: {
      previewSource: nftData.previewImage,
      source: nftData.visual,
      type: isVideo(nftData.visual) ? 'video' : 'image',
    },
    artistAddress: nftData.artist || '',
    artist: {
      username: '',
      address: ''
    },
    collectionAddress: nftData.contractAddress,
    blockchainId: +nftData.chainId,
    attributes: nftData.attributes,
    standard: nftData.standard
  };
}

async function translateNftsDataToNftsIndexedByCollectionAndTokenId(nftsData: NFTsData): Promise<Record<string, NFT>> {
  const nftsIndexedByCollectionAndTokenId: Record<string, NFT> = {};
  if (!nftsData.nfts) {
    return nftsIndexedByCollectionAndTokenId;
  }

  for (const nftData of nftsData.nfts) {
    const nft = translateNftDataToNft(nftData);

    if (nftData.attributes && nftData.attributes.length) {
      nft.attributes = nftData.attributes.map(attribute => ({ name: attribute.name, value: attribute.value }));
    }

    const index = `${nft.collectionAddress.toLowerCase()}_${nft.tokenId.toLowerCase()}`;
    nftsIndexedByCollectionAndTokenId[index] = nft;
  }

  return nftsIndexedByCollectionAndTokenId;
}

// API limitation: can't request more than 100 token IDs at once
export async function getNftsByTokenIdsAndCollectionAddresses(tokenIds: string[], collectionAddresses: string[], blockchainId: number): Promise<NFT[]> {
  const response = await httpClient.post(`${API_URL}`, {
    query: `{
    nfts(contractAddresses: ["${collectionAddresses.join('", "')}"], tokenIds: ["${tokenIds.join('", "')}"], chainId: ${blockchainId}) {
      contractAddress
      tokenId
      chainId
      tokenUri
      artist
      description
      previewImage
      visual
      name
      maxSupply
      attributes {
        name
        value
      }
      standard
    }
  }`,
  });

  const nftsData: NFTsData = response.data as NFTsData;
  const nftsIndexedByCollectionAndTokenId = await translateNftsDataToNftsIndexedByCollectionAndTokenId(nftsData);
  const nfts: NFT[] = [];
  for (let i = 0; tokenIds[i] && collectionAddresses[i]; i++) {
    const index = `${collectionAddresses[i].toLowerCase()}_${tokenIds[i].toLowerCase()}`;
    if (!nftsIndexedByCollectionAndTokenId[index]) {
      continue;
    }

    nfts.push(nftsIndexedByCollectionAndTokenId[index]);
  }

  return nfts;
}

export async function getAllNftsByTokenIdsAndCollectionAddresses(tokenIds: string[], collectionAddresses: string[], blockchainId: number): Promise<NFT[]> {
  const limit = 50;
  let from = 0;
  let nfts: NFT[] = [];
  let tokenIdsSlice = tokenIds.slice(from, from + limit);
  let collectionAddressesSlice = collectionAddresses.slice(from, from + limit);

  let nftsBatch = [];
  if (tokenIds.length > 0 && collectionAddresses.length > 0 && tokenIds.length === collectionAddresses.length) {
    do {
      nftsBatch = await getNftsByTokenIdsAndCollectionAddresses(tokenIdsSlice, collectionAddressesSlice, blockchainId);
      nfts = [...nfts, ...nftsBatch];
      from += limit;
      tokenIdsSlice = tokenIds.slice(from, from + limit);
      collectionAddressesSlice = collectionAddresses.slice(from, from + limit);
    } while (tokenIdsSlice.length > 1);
  }

  return nfts;
}

export async function getNftByTokenIdAndCollectionAddress(tokenId: string, collectionAddress: string, blockchainId: number): Promise<NFT|null> {
  const nft = await getNftsByTokenIdsAndCollectionAddresses([tokenId], [collectionAddress], blockchainId);
  if (!nft[0]) {
    return null;
  }

  return nft[0];
}
