import { defineComponent } from 'vue';
import { bigIntToString } from '@/common/utils/format-numbers';
export default defineComponent({
  name: 'GachaponStats',
  props: {
    gachapon: {
      type: Object,
      default: null
    }
  },
  computed: {
    burnedAmount() {
      if (this.gachapon.currency.symbol !== 'AZUKI' && this.gachapon.currency.symbol !== 'BND') {
        return '0';
      }
      return bigIntToString(this.gachapon.tokenomicsAmount, this.gachapon.currency.decimals);
    },
    buybackAmount() {
      if (this.gachapon.currency.symbol !== 'ETH') {
        return '0';
      }
      return bigIntToString(this.gachapon.tokenomicsAmount, this.gachapon.currency.decimals);
    },
    ownerProfits() {
      return bigIntToString(this.gachapon.ownerProfits, this.gachapon.currency.decimals);
    },
    timesPlayed() {
      if (!this.gachapon) {
        return 0;
      }
      return this.gachapon.timesPlayed || 0;
    },
    nftsLeft() {
      if (!this.gachapon) {
        return 0;
      }
      return this.gachapon.prizesAmountLeft || 0;
    },
    totalAddedNfts() {
      if (!this.gachapon) {
        return 0;
      }
      return this.gachapon.prizesAmountLeft + this.gachapon.timesPlayed || 0;
    }
  }
});