import { ERC721 } from './types';
import { getGasPrice } from '@/common/api/gas-price';
import { parseUnits, Signer } from 'ethers';
import { Erc721__factory} from '@/contracts';

export default class ERC721Contract implements ERC721 {
  async uri(signer: Signer, collectionAddress: string, tokenId: string): Promise<string> {
    const contract = Erc721__factory.connect(collectionAddress, signer);

    return contract.tokenURI(tokenId);
  }

  async supportsInterface(signer: Signer, collectionAddress: string): Promise<boolean> {
    const contract = Erc721__factory.connect(collectionAddress, signer);

    return contract.supportsInterface('0x80ac58cd');
  }

  async balanceOf(signer: Signer, userAddress: string, collectionAddress: string, tokenId: string): Promise<bigint> {
    const contract = Erc721__factory.connect(collectionAddress, signer);

    const ownerAddress = await contract.ownerOf(tokenId);

    return ownerAddress.toLowerCase() === userAddress.toLowerCase() ? 1n : 0n;
  }

  async transfer(signer: Signer, collectionAddress: string, tokenId: string, fromAddress: string, toAddress: string): Promise<void> {
    const gasPrice = await getGasPrice();
    const contract = Erc721__factory.connect(collectionAddress, signer);

    const transaction = await contract['safeTransferFrom(address,address,uint256)'](fromAddress, toAddress, tokenId, {
      from: fromAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: gasPrice ? parseUnits(gasPrice.toString(), 'gwei') : null
    });

    await transaction.wait();
  }
}
