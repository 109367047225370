import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import MainLayout from '../layouts/MainLayout/index.vue';
import EmbedLayout from '../layouts/EmbedLayout/index.vue';
import HomeView from '../views/home/index.vue';
import GachaView from '../views/gacha/index.vue';
import GachaponView from '../views/gachapon/index.vue';
import BridgeView from '../views/bridge/index.vue';
import ClaimView from '../views/claim/index.vue';
import FactoryView from '../views/factory/index.vue';
import NftView from '../views/nft/index.vue';
import StakingView from '../views/staking/index.vue';
import GachaponMigrationView from '../views/gachapon-migration/index.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView
      },
      {
        path: '/gacha',
        name: 'gacha',
        component: GachaView
      },
      {
        path: '/gachapon/:address',
        name: 'gachapon',
        component: GachaponView,
      },
      {
        path: '/claim',
        name: 'claim',
        component: ClaimView,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: '/staking',
        name: 'staking',
        component: StakingView,
      },
      {
        path: '/bridge/:activeTab?',
        name: 'bridge',
        component: BridgeView,
      },
      {
        path: '/factory/:activeTab?',
        name: 'factory',
        component: FactoryView
      },
      {
        path: 'nft/:blockchainId/:collectionAddress/:tokenId',
        name: 'NFTDetail',
        component: NftView,
      },
      {
        // must be kept for existing NFTs which are using this URL in their metadata
        path: '/token/:tokenId',
        redirect: '/nft/1/0x7CdC0421469398e0F3aA8890693d86c840Ac8931/:tokenId',
      },
      {
        // must be kept for existing NFTs which are using this URL in their metadata
        path: 'nft/:tokenId',
        redirect: '/nft/1/0x7CdC0421469398e0F3aA8890693d86c840Ac8931/:tokenId',
      },
      {
        // must be kept for existing NFTs which are using this URL in their metadata
        path: '/token/:blockchainId/:collectionAddress/:tokenId',
        redirect: '/nft/:blockchainId/:collectionAddress/:tokenId',
      },
      {
        path: 'gachapon-migration',
        name: 'gachapon-migration',
        component: GachaponMigrationView,
      }
    ],
  },
  {
    path: '/embed',
    component: EmbedLayout,
    children: [
      {
        path: '/embed/gachapon/:address',
        name: 'embed_gachapon',
        component: GachaponView,
      }
    ]
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
