import httpClient from '@/common/utils/http-client';
import GachaponsGlobalPlayStats from '@/common/types/GachaponsGlobalPlayStats';
import {VRFGachaponsGlobalPlayStatsData} from './types';

const API_URL = process.env.NODE_ENV === 'development'
    ? 'https://api.thegraph.com/subgraphs/name/kirienzoeth/dokidoki-gachapons-mumbai'
    : process.env.VUE_APP_USE_BACKUP_SUBGRAPH === '1'
        ? 'https://graph.dokidoki.com/subgraphs/name/DokiDokiFinance/gachapon-subgraph'
        : 'https://api.thegraph.com/subgraphs/name/kirienzoeth/dokidoki-gachapons';

export async function getVRFGachaponsGlobalPlayStats(): Promise<GachaponsGlobalPlayStats> {
  const globalPlayStats: GachaponsGlobalPlayStats = {
    createdGachaponsAmount: 0,
    distributedNftsAmount: 0,
    uniquePlayersAmount: 0
  };

  const response = await httpClient.post(API_URL, {
    query: `{
      gachaponControlTower(id: "0") {
        gachaponsCreatedAmount
        timesPlayedAmount
        uniquePlayersAmount
      }
    }`,
  });

  if (!response.data?.gachaponControlTower) {
    return globalPlayStats;
  }

  const gachaponsGlobalPlayStatsData: VRFGachaponsGlobalPlayStatsData = response.data;
  globalPlayStats.createdGachaponsAmount = +gachaponsGlobalPlayStatsData.gachaponControlTower.gachaponsCreatedAmount;
  globalPlayStats.distributedNftsAmount = +gachaponsGlobalPlayStatsData.gachaponControlTower.timesPlayedAmount;
  globalPlayStats.uniquePlayersAmount = +gachaponsGlobalPlayStatsData.gachaponControlTower.uniquePlayersAmount;

  return globalPlayStats;
}
