import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "nft-name"
};
const _hoisted_2 = {
  class: "transfer-area"
};
const _hoisted_3 = {
  key: 0,
  class: "information"
};
const _hoisted_4 = {
  key: 1,
  class: "information"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_QuantityInput = _resolveComponent("QuantityInput");
  const _component_ShadowedButton = _resolveComponent("ShadowedButton");
  const _component_BaseDialog = _resolveComponent("BaseDialog");
  return _ctx.selectedNft ? (_openBlock(), _createBlock(_component_BaseDialog, {
    key: 0,
    class: "transfer-nft-to-polygon-dialog",
    isDialogVisible: _ctx.isDialogVisible,
    title: "Deposit NFT to Polygon L2",
    width: "475px",
    onClose: _ctx.closeDialog
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.selectedNft.name), 1), _createElementVNode("div", _hoisted_2, [_createVNode(_component_QuantityInput, {
      disabled: _ctx.loading,
      quantity: _ctx.quantity,
      "onUpdate:quantity": _cache[0] || (_cache[0] = $event => _ctx.quantity = $event),
      placeholder: `Owned: ${_ctx.selectedNft.balance}`,
      minQuantity: 1,
      maxQuantity: _ctx.selectedNft.balance < _ctx.selectedNft.maxSupply ? _ctx.selectedNft.balance : _ctx.selectedNft.maxSupply
    }, null, 8, ["disabled", "quantity", "placeholder", "maxQuantity"]), _ctx.mustApproveFirst ? (_openBlock(), _createBlock(_component_ShadowedButton, {
      key: 0,
      class: "approve-button-container",
      shadowSize: "s",
      theme: "violet-white",
      isLoading: _ctx.loading,
      onClick: _ctx.handleApprove
    }, {
      default: _withCtx(() => [_createTextVNode(" APPROVE ")]),
      _: 1
    }, 8, ["isLoading", "onClick"])) : (_openBlock(), _createBlock(_component_ShadowedButton, {
      key: 1,
      class: "transfer-button-container",
      shadowSize: "s",
      theme: "violet-white",
      isLoading: _ctx.loading,
      onClick: _ctx.handleDeposit
    }, {
      default: _withCtx(() => [_createTextVNode(" DEPOSIT ")]),
      _: 1
    }, 8, ["isLoading", "onClick"]))]), _ctx.mustApproveFirst ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Approve deposit first. You only have to do this once. ")) : (_openBlock(), _createElementBlock("div", _hoisted_4, " Post confirmation, deposits from Ethereum Mainnet to Polygon (Matic) L2 take approximately 5 ~ 7 minutes. "))]),
    _: 1
  }, 8, ["isDialogVisible", "onClose"])) : _createCommentVNode("", true);
}