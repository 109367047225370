import { defineComponent } from 'vue';
import { Histogram as ElIconHistogram, Share as ElIconShare } from '@element-plus/icons-vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import CurrencyIcon from '@/components/Icons/Currency.vue';
import Gachapon from './Gachapon.vue';
import GachaponStats from './GachaponStats.vue';
import InnerPanel from './InnerPanel.vue';
import OuterPanel from './OuterPanel.vue';
import PlayState from './PlayState.vue';
import Music from './Music.vue';
import wait from '@/common/utils/wait';
import { getUserProfileByAddress } from '@/common/api/users';
import { bigIntToDisplayableString } from '@/common/utils/format-numbers';
import { getWalletAllowlistSpots } from '@/common/api/gachapons/allowlists';
import { ElMessage } from 'element-plus';
import useClipboard from 'vue-clipboard3';
import { connectWallet, connectedWallet, connectedChain, getWalletSigner } from '@/common/utils/web3-onboard';
import erc20Contract from '@/common/contracts/erc20';
import { getL2Provider } from '@/common/utils/ethers';
import { Erc20__factory } from '@/contracts';
import { parseUnits } from "ethers";
export default defineComponent({
  name: 'GachaponPanel',
  components: {
    ElIconHistogram,
    ElIconShare,
    GachaponStats,
    Gachapon,
    Music,
    ShadowedButton,
    CurrencyIcon,
    InnerPanel,
    OuterPanel,
    PlayState
  },
  data() {
    return {
      connectedWallet,
      connectedChain,
      owner: null,
      walletAllowlistSpots: [],
      playEndingAnimation: false,
      isNFTMachineAnimated: false,
      isFetchingData: false,
      isGachaponReady: false,
      showDialogIfNeeded: false,
      approvedAmount: 0n,
      userBalance: 0n,
      spinsAmount: 1,
      elMessage: ElMessage
    };
  },
  props: {
    customStyle: {
      type: Object
    },
    playableGachapon: {
      type: Object,
      default: null
    },
    prizes: {
      type: Array
    }
  },
  computed: {
    erc20Contract() {
      return Erc20__factory.connect(this.playableGachapon.gachapon.currency.address, getL2Provider(true));
    },
    exchangeLink() {
      if (!this.playableGachapon?.gachapon) {
        return 'https://app.paraswap.io/#/?network=polygon';
      }
      return `https://app.paraswap.io/#/${this.playableGachapon.gachapon.currency.address}?network=polygon`;
    },
    currentUrl() {
      return window.location?.origin ? window.location.origin + this.$route.fullPath : this.$route.fullPath;
    },
    balance() {
      if (!this.playableGachapon.gachapon.currency.symbol || !this.playableGachapon.gachapon.currency.address) {
        return '-';
      }
      return bigIntToDisplayableString(this.userBalance, this.playableGachapon?.gachapon.currency.decimals);
    },
    playPrice() {
      if (!this.playableGachapon?.gachapon.price) {
        return 0n;
      }
      return this.playableGachapon.gachapon.price * BigInt(this.spinsAmount);
    },
    allowlistPlayPrice() {
      if (!this.walletAllowlistSpotToUse?.price) {
        return 0n;
      }
      return this.walletAllowlistSpotToUse.price * BigInt(this.spinsAmount);
    },
    gachaponState() {
      return this.playableGachapon.state;
    },
    walletAllowlistSpotToUse() {
      if (!this.walletAllowlistSpots.length || !this.mustUseAllowlistSpot) {
        return undefined;
      }
      return this.walletAllowlistSpots[0];
    },
    walletAllowlistSpotRollsLeft() {
      if (!this.walletAllowlistSpotToUse) {
        return 0n;
      }
      return this.walletAllowlistSpotToUse.playAmountAllowed - this.walletAllowlistSpotToUse.playedAmount;
    },
    mustUseAllowlistSpot() {
      if (this.walletAllowlistSpots[0] && this.playableGachapon?.gachapon && (this.walletAllowlistSpots[0].price < this.playableGachapon.gachapon.price || this.playableGachapon.gachapon.isAllowlistOnly)) {
        return true;
      }
      return false;
    },
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    }
  },
  methods: {
    connectWallet,
    bigIntToDisplayableString,
    async copyUrlToClipboard() {
      await this.copyToClipboard(this.currentUrl);
    },
    async fetchOwner(ownerAddress) {
      if (!ownerAddress) {
        return;
      }
      this.owner = await getUserProfileByAddress(ownerAddress);
    },
    async fetchUserBalance() {
      if (!this.playableGachapon.gachapon.currency.symbol || !this.connectedWallet) {
        return;
      }
      const provider = getL2Provider(true);
      this.userBalance = await erc20Contract.balanceOf(provider, this.walletAddress, this.playableGachapon.gachapon.currency.address);
      if (this.playableGachapon.gachapon.currency.isWrappedNativeToken) {
        this.userBalance += await provider.getBalance(this.walletAddress);
      }
    },
    async fetchWalletAllowlistSpots() {
      if (!this.playableGachapon.gachapon || !this.connectedWallet) {
        return;
      }
      this.walletAllowlistSpots = await getWalletAllowlistSpots(this.playableGachapon.gachapon, this.walletAddress);
    },
    async fetchApprovedAmount() {
      if (!this.connectedWallet || !this.playableGachapon?.gachapon) {
        return;
      }
      const provider = getL2Provider(true);
      this.approvedAmount = await erc20Contract.allowance(provider, this.walletAddress, this.playableGachapon.gachapon.address, this.playableGachapon.gachapon.currency.address);
    },
    async play() {
      if (!this.connectedWallet) {
        await this.connectWallet();
        return;
      }
      const priceToPay = this.mustUseAllowlistSpot ? this.allowlistPlayPrice : this.playPrice;
      if (priceToPay > this.userBalance) {
        return;
      }
      this.showDialogIfNeeded = true;
      if (!this.isGachaponReady || this.isNFTMachineAnimated) {
        return;
      }
      this.isNFTMachineAnimated = true;
      try {
        const walletSigner = await getWalletSigner(this.connectedWallet);
        let nativeTokenAmount = 0n;
        if (this.playableGachapon.gachapon.currency.isWrappedNativeToken) {
          const nativeTokenBalance = (await walletSigner.provider?.getBalance(this.walletAddress)) || 0n;
          nativeTokenAmount = nativeTokenBalance - priceToPay - parseUnits('0.1', this.playableGachapon.gachapon.currency.decimals) > 0n ? priceToPay : nativeTokenBalance - parseUnits('0.1', this.playableGachapon.gachapon.currency.decimals);
        }
        const prizes = await this.playableGachapon.play(walletSigner, this.walletAddress, this.spinsAmount, nativeTokenAmount, this.walletAllowlistSpotToUse);
        if (this.walletAllowlistSpotToUse?.playedAmount) {
          this.walletAllowlistSpotToUse.playedAmount = this.walletAllowlistSpotToUse.playedAmount + BigInt(this.spinsAmount);
        }
        this.playEndingAnimation = true;
        await wait(2000);
        this.playEndingAnimation = false;
        this.$emit('update:prizes', prizes);
      } catch (e) {
        if (e instanceof Error) {
          ElMessage({
            message: `Operation failed, reason: ${e.message}`,
            grouping: true,
            type: 'error'
          });
        }
      }
      this.isNFTMachineAnimated = false;
    },
    subscribeBalanceEvents() {
      if (!this.connectedWallet || !this.playableGachapon.gachapon) {
        return;
      }
      const filter = this.erc20Contract.filters.Transfer(this.walletAddress);
      this.erc20Contract.on(filter, async () => await this.fetchUserBalance());
    },
    unsubscribeBalanceEvents() {
      this.erc20Contract.removeAllListeners();
    },
    onSliderInputChange(value) {
      const element = document.querySelector('.el-slider__button');
      if (element) {
        element.innerHTML = `${value}x`;
      }
    }
  },
  async created() {
    this.isFetchingData = true;
    await this.fetchUserBalance();
    await this.fetchApprovedAmount();
    await this.fetchWalletAllowlistSpots();
    this.isFetchingData = false;
    this.subscribeBalanceEvents();
    await this.fetchOwner(this.playableGachapon.gachapon.ownerAddress);
  },
  async mounted() {
    this.onSliderInputChange(1);
  },
  beforeUnmount() {
    this.unsubscribeBalanceEvents();
  },
  watch: {
    gachaponState: function (state) {
      if (state === 'ready') {
        this.elMessage.closeAll();
      }
      if (state === 'waiting') {
        this.elMessage.success({
          message: 'Transaction processed, waiting for your prizes to be revealed (◕‿◕✿)',
          duration: 0
        });
      }
    },
    walletAddress: async function () {
      this.isFetchingData = true;
      this.unsubscribeBalanceEvents();
      this.subscribeBalanceEvents();
      await this.fetchUserBalance();
      await this.fetchApprovedAmount();
      await this.fetchWalletAllowlistSpots();
      this.isFetchingData = false;
    },
    connectedChain: async function () {
      this.isFetchingData = true;
      this.unsubscribeBalanceEvents();
      this.subscribeBalanceEvents();
      await this.fetchUserBalance();
      await this.fetchApprovedAmount();
      await this.fetchWalletAllowlistSpots();
      this.isFetchingData = false;
    },
    walletAllowlistSpotRollsLeft: function () {
      if (this.walletAllowlistSpotRollsLeft > 0 || !this.walletAllowlistSpots.length) {
        return;
      }
      this.walletAllowlistSpots.shift();
    }
  },
  setup() {
    const {
      toClipboard
    } = useClipboard();
    const copyToClipboard = async text => {
      try {
        await toClipboard(text);
        ElMessage({
          message: 'Copied to clipboard!',
          grouping: true,
          type: 'success'
        });
      } catch (e) {
        ElMessage({
          message: 'Failed to copy.',
          grouping: true,
          type: 'error'
        });
      }
    };
    return {
      copyToClipboard
    };
  }
});