import { defineComponent } from 'vue';
import OwnedNFTCard from '@/components/NFT/OwnedNFTCard.vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import TransferNFTToGachaponDialog from './TransferNFTToGachaponDialog.vue';
import RemoveGachaponNFTsDialog from './RemoveGachaponNFTsDialog.vue';
import TransferCustomCollectionNFTToGachaponDialog from './TransferCustomCollectionNFTToGachaponDialog.vue';
import SwitchNetworkToPolygonDialog from '@/components/Dialogs/SwitchNetworkToPolygonDialog.vue';
import { getAllL2OwnedNftsByOwnerAddress } from '@/common/api/nfts';
import { getUserProfilesIndexedByAddress } from '@/common/api/users';
import { getL2BlockchainIdHex } from '@/common/utils/blockchains';
import { ElMessage } from 'element-plus';
import { connectedWallet, connectedChain, getWalletSigner } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'NFTLoaderTab',
  components: {
    OwnedNFTCard,
    TransferNFTToGachaponDialog,
    TransferCustomCollectionNFTToGachaponDialog,
    SwitchNetworkToPolygonDialog,
    RemoveGachaponNFTsDialog,
    ShadowedButton
  },
  data() {
    return {
      connectedWallet,
      connectedChain,
      isProcessing: false,
      isRemoveGachaponNFTsDialogVisible: false,
      isNonDokiNftTransferDialogVisible: false,
      isSwitchToPolygonDialogVisible: false,
      selectedGachaponAddress: '',
      selectedNft: null,
      ownedNfts: new Array(),
      artists: {}
    };
  },
  props: {
    gachapons: {
      type: Array,
      default: () => []
    },
    gachaponManagers: {
      type: Object,
      default: null
    }
  },
  computed: {
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    },
    mustSwitchBlockchain() {
      return getL2BlockchainIdHex() !== this.connectedChain?.id;
    },
    isLoadDialogVisible() {
      if (!this.selectedNft || !this.selectedGachapon) {
        return false;
      }
      return true;
    },
    canLoadNftsInGachapon() {
      if (!this.selectedGachapon || this.selectedGachapon.isLockedForever || this.selectedGachapon.isBanned) {
        return false;
      }
      return this.selectedGachapon.isLocked;
    },
    selectedGachapon() {
      if (!this.selectedGachaponAddress || !this.gachaponManagers[this.selectedGachaponAddress]) {
        return null;
      }
      return this.gachaponManagers[this.selectedGachaponAddress].gachapon;
    }
  },
  methods: {
    async fetchOwnedNfts(ownerAddress) {
      if (!ownerAddress) {
        return;
      }
      this.ownedNfts = await getAllL2OwnedNftsByOwnerAddress(ownerAddress, 'lastReceived', 'desc');
    },
    async fetchArtists(nfts) {
      if (!nfts.length) {
        return;
      }
      this.artists = await getUserProfilesIndexedByAddress(nfts.map(nft => nft.artistAddress));
    },
    async handleLock() {
      if (!this.selectedGachapon || !this.gachaponManagers[this.selectedGachapon.address] || !this.connectedWallet || this.isProcessing) {
        return;
      }
      if (this.mustSwitchBlockchain) {
        this.isSwitchToPolygonDialogVisible = true;
        return;
      }
      try {
        this.isProcessing = true;
        const walletSigner = await getWalletSigner(this.connectedWallet);
        await this.gachaponManagers[this.selectedGachapon.address].lock(walletSigner, this.walletAddress);
        ElMessage({
          message: 'Machine locked!',
          grouping: true,
          type: 'success'
        });
        this.$emit('refresh-gachapons');
      } catch (e) {
        ElMessage({
          message: 'Operation failed',
          grouping: true,
          type: 'error'
        });
      }
      this.isProcessing = false;
    },
    async handleUnlock() {
      if (!this.selectedGachapon || !this.gachaponManagers[this.selectedGachapon.address] || !this.connectedWallet || this.isProcessing) {
        return;
      }
      if (this.mustSwitchBlockchain) {
        this.isSwitchToPolygonDialogVisible = true;
        return;
      }
      try {
        this.isProcessing = true;
        const walletSigner = await getWalletSigner(this.connectedWallet);
        await this.gachaponManagers[this.selectedGachapon.address].unlock(walletSigner, this.walletAddress);
        ElMessage({
          message: 'Machine unlocked!',
          grouping: true,
          type: 'success'
        });
        this.$emit('refresh-gachapons');
      } catch (e) {
        ElMessage({
          message: 'Operation failed',
          grouping: true,
          type: 'error'
        });
      }
      this.isProcessing = false;
    },
    handleLoadCustomCollection() {
      if (this.mustSwitchBlockchain) {
        this.isSwitchToPolygonDialogVisible = true;
        return;
      }
      this.isNonDokiNftTransferDialogVisible = !!this.selectedGachapon && this.selectedGachapon.isLocked;
    },
    handleLoad(ownedNft) {
      if (!ownedNft || !this.canLoadNftsInGachapon) {
        return;
      }
      if (this.mustSwitchBlockchain) {
        this.isSwitchToPolygonDialogVisible = true;
        return;
      }
      this.selectedNft = ownedNft;
    },
    async loadNftsData() {
      await this.fetchOwnedNfts(this.walletAddress);
      await this.fetchArtists(this.ownedNfts);
    }
  },
  async created() {
    await this.loadNftsData();
  },
  watch: {
    async connectedWallet() {
      await this.loadNftsData();
    }
  }
});