import { defineComponent } from 'vue';
import { inject } from '@vercel/analytics';
import { connectToRememberedWallets, rememberWallets } from '@/common/utils/web3-onboard';
export default defineComponent({
  data() {
    return {
      walletsConnectUnsubscriber: () => {}
    };
  },
  created() {
    this.walletsConnectUnsubscriber = rememberWallets();
  },
  mounted() {
    inject();
    connectToRememberedWallets();
  },
  unmounted() {
    this.walletsConnectUnsubscriber();
  }
});