import { GachaponDeployer } from './types';
import { TransactionEvents } from '@/common/contracts/events/types';
import { Signer } from 'ethers';
import { GachaponDeployer__factory } from '@/contracts';

const DEPLOYER_ADDRESS = process.env.NODE_ENV === 'production' ? '0x5D5F028E92a4BDC365e000D971E7E4673F0FA708' : '0x4439120dC2B88C02FF49771f1FFE8885BD4A094e';
const EVENT_NAME = 'PermissionlessGachaponCreated';

export default class StandardGachaponDeployer implements GachaponDeployer, TransactionEvents {
  lastTransactionHash?: string;
  state: 'waiting-transaction' | 'transaction-accepted';

  constructor() {
    this.state = 'waiting-transaction';
  }

  async deploy(signer: Signer, userAddress: string, name: string, currencyAddress: string): Promise<string> {
    this.state = 'waiting-transaction';
    this.lastTransactionHash = undefined;

    const contract = GachaponDeployer__factory.connect(DEPLOYER_ADDRESS, signer);
    const transaction = await contract.deploy(name, currencyAddress, {
      from: userAddress,
      value: await contract.price(),
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: null
    });
    this.state = 'transaction-accepted';
    this.lastTransactionHash = transaction.hash;

    const receipt = await transaction.wait();

    const eventFragment = contract.interface.getEvent(EVENT_NAME);
    const log = receipt?.logs.find(log => log.topics.indexOf(eventFragment.topicHash) >= 0);
    if (!log) {
      return '';
    }

    const logDescription = contract.interface.parseLog({ data: log.data, topics: [...log.topics] });

    return logDescription?.args[1] ?? '0';
  }
}
