import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "nft-visual"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = ["controls", "src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.isImage ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    class: "media image",
    src: _ctx.source,
    alt: "",
    loading: "lazy"
  }, null, 8, _hoisted_2)) : _ctx.isVideo ? (_openBlock(), _createElementBlock("video", {
    key: 1,
    class: "media video",
    loop: "",
    controls: _ctx.controls,
    autoplay: "",
    src: _ctx.source
  }, null, 8, _hoisted_3)) : _createCommentVNode("", true)]);
}