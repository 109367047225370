import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "demo-prize"
};
const _hoisted_2 = {
  class: "media-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_image = _resolveComponent("el-image");
  const _component_NFTVisual = _resolveComponent("NFTVisual");
  return _ctx.visualUrl ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createElementVNode("div", null, [_createVNode(_component_el_image, {
    class: "initial-state",
    src: _ctx.initialStateImage,
    onClick: _cache[0] || (_cache[0] = $event => _ctx.openPrize())
  }, null, 8, ["src"])], 512), [[_vShow, _ctx.currentState === 'initial']]), _withDirectives(_createElementVNode("div", null, [_withDirectives(_createVNode(_component_el_image, {
    class: "animated-state",
    src: _ctx.animatedStateImage
  }, null, 8, ["src"]), [[_vShow, _ctx.index >= 0]]), _withDirectives(_createVNode(_component_el_image, {
    class: "animated-state",
    src: _ctx.sharedAnimatedStateImage
  }, null, 8, ["src"]), [[_vShow, _ctx.index === -1]])], 512), [[_vShow, _ctx.currentState === 'animated']]), _withDirectives(_createElementVNode("div", {
    class: "final-state",
    onClick: _cache[1] || (_cache[1] =
    //@ts-ignore
    (...args) => _ctx.openViewer && _ctx.openViewer(...args))
  }, [_createVNode(_component_el_image, {
    src: _ctx.visualUrl,
    alt: "prize",
    fit: "contain"
  }, null, 8, ["src"])], 512), [[_vShow, _ctx.currentState === 'final']]), _ctx.isViewerOpened ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "viewer",
    onClick: _cache[2] || (_cache[2] =
    //@ts-ignore
    (...args) => _ctx.closeViewer && _ctx.closeViewer(...args))
  }, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_NFTVisual, {
    source: _ctx.visualUrl,
    isImage: true,
    isVideo: false
  }, null, 8, ["source"])])])) : _createCommentVNode("", true)])) : _createCommentVNode("", true);
}