import { defineComponent } from 'vue';
import WebsiteIcon from '@/components/Icons/Website.vue';
import TwitterIcon from '@/components/Icons/Twitter.vue';
import InstagramIcon from '@/components/Icons/Instagram.vue';
import InnerPanel from './InnerPanel.vue';
import OuterPanel from './OuterPanel.vue';
import { getUserProfileByAddress } from '@/common/api/users';
import defaultAvatar from '@/app/assets/factory/default-avatar.png';
import { ElMessage } from 'element-plus';
import { truncateMiddle } from '@/common/utils/truncate';
import useClipboard from "vue-clipboard3";
export default defineComponent({
  name: 'OwnerPanel',
  components: {
    OuterPanel,
    InnerPanel,
    WebsiteIcon,
    TwitterIcon,
    InstagramIcon
  },
  props: {
    gachapon: {
      type: Object
    },
    ownerAddress: String
  },
  data() {
    return {
      owner: null
    };
  },
  computed: {
    defaultAvatar() {
      return defaultAvatar;
    }
  },
  methods: {
    truncateMiddle,
    async copyUrlToClipboard() {
      if (!this.ownerAddress) {
        return;
      }
      await this.copyToClipboard(this.ownerAddress);
    }
  },
  async created() {
    if (!this.ownerAddress) {
      return;
    }
    this.owner = await getUserProfileByAddress(this.ownerAddress);
  },
  setup() {
    const {
      toClipboard
    } = useClipboard();
    const copyToClipboard = async text => {
      try {
        await toClipboard(text);
        ElMessage({
          message: 'Copied to clipboard!',
          grouping: true,
          type: 'success'
        });
      } catch (e) {
        ElMessage({
          message: 'Failed to copy.',
          grouping: true,
          type: 'error'
        });
      }
    };
    return {
      copyToClipboard
    };
  }
});