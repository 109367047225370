import { defineComponent } from 'vue';
import SwitchNetworkToEthereumDialog from '@/components/Dialogs/SwitchNetworkToEthereumDialog.vue';
import StakingPool from './StakingPool.vue';
import { getL1BlockchainIdHex } from '@/common/utils/blockchains';
import { connectedChain } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'EthereumTab',
  components: {
    StakingPool,
    SwitchNetworkToEthereumDialog
  },
  data() {
    return {
      connectedChain,
      isSwitchNetworkDialogVisible: false
    };
  },
  props: {
    stakingPools: {
      type: Array,
      default: () => []
    },
    walletBalances: {
      type: Array,
      default: () => []
    },
    walletApprovedAmounts: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isWalletConnectedToEthereum() {
      if (!this.connectedChain) {
        return false;
      }
      return this.connectedChain.id === getL1BlockchainIdHex();
    }
  },
  methods: {
    closeSwitchNetworkDialog() {
      this.isSwitchNetworkDialogVisible = false;
    },
    openSwitchNetworkDialog() {
      this.isSwitchNetworkDialogVisible = true;
    }
  }
});