import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "prizes-list"
};
const _hoisted_2 = {
  class: "footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PrizeBall = _resolveComponent("PrizeBall");
  const _component_ShadowedButton = _resolveComponent("ShadowedButton");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _ctx.nfts ? (_openBlock(), _createBlock(_component_el_dialog, {
    key: 0,
    class: "demo-prizes-dialog",
    "model-value": _ctx.isVisible,
    center: true,
    "append-to-body": true,
    "destroy-on-close": true,
    fullscreen: true
  }, {
    footer: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_ctx.canOpenAllPrizes ? (_openBlock(), _createBlock(_component_ShadowedButton, {
      key: 0,
      shadowSize: "s",
      theme: "pink-white",
      class: "button",
      onClick: _ctx.openAllPrizes
    }, {
      default: _withCtx(() => [_createTextVNode("OPEN ALL")]),
      _: 1
    }, 8, ["onClick"])) : (_openBlock(), _createBlock(_component_ShadowedButton, {
      key: 1,
      shadowSize: "s",
      theme: "pink-white",
      class: "button",
      onClick: _ctx.onClose
    }, {
      default: _withCtx(() => [_createTextVNode("CLOSE")]),
      _: 1
    }, 8, ["onClick"]))])]),
    default: _withCtx(() => [_createVNode(_Transition, {
      name: "fade"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nfts, (nft, index) => {
        return _openBlock(), _createBlock(_component_PrizeBall, {
          key: index,
          visualUrl: nft,
          index: !_ctx.areAllPrizesOpened ? index : _ctx.openedPrizesIndexesSet.has(index) ? index : -1,
          open: _ctx.areAllPrizesOpened,
          isMuted: _ctx.mutePrizeBalls,
          onOpened: $event => _ctx.handlePrizeOpen(index)
        }, null, 8, ["visualUrl", "index", "open", "isMuted", "onOpened"]);
      }), 128))])]),
      _: 1
    })]),
    _: 1
  }, 8, ["model-value"])) : _createCommentVNode("", true);
}