import { defineComponent } from 'vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import { getPlayedRounds } from '@/common/api/rounds';
import Round from './Round.vue';
import WalletSignIn from '@/components/Wallets/WalletSignIn.vue';
import { connectedWallet } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'ClaimView',
  components: {
    Round,
    ShadowedButton,
    WalletSignIn
  },
  data() {
    return {
      connectedWallet,
      currentPage: 0,
      pageSize: 20,
      rounds: [],
      isLoading: false,
      hasLoadedAll: false
    };
  },
  computed: {
    isWalletConnected() {
      return !!this.connectedWallet;
    },
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    },
    hasViewportReachedBottomOfPage() {
      return this.$store.state.viewport.hasReachedBottomOfPage;
    }
  },
  methods: {
    loadPlayedRounds() {
      if (!this.isWalletConnected || this.isLoading) {
        return;
      }
      this.isLoading = true;
      setTimeout(async () => {
        const rounds = await getPlayedRounds(this.walletAddress, this.currentPage * this.pageSize);
        if (!rounds || rounds.length < this.pageSize) {
          this.hasLoadedAll = true;
        } else {
          this.currentPage++;
        }
        this.rounds = [...this.rounds, ...rounds];
        this.isLoading = false;
      }, 100);
    }
  },
  async created() {
    await this.loadPlayedRounds();
  },
  watch: {
    connectedWallet: async function () {
      if (!this.isWalletConnected) {
        return;
      }
      this.rounds = [];
      this.hasLoadedAll = false;
      await this.loadPlayedRounds();
    },
    isWalletConnected: async function () {
      if (!this.isWalletConnected) {
        return;
      }
      await this.loadPlayedRounds();
    },
    hasViewportReachedBottomOfPage: async function () {
      if (this.hasLoadedAll) {
        return;
      }
      await this.loadPlayedRounds();
    }
  }
});