import { defineComponent } from 'vue';
import { getL2BlockchainIdHex } from '@/common/utils/blockchains';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import QuantityInput from '@/components/Inputs/QuantityInput.vue';
import BaseDialog from './BaseDialog.vue';
import { withdrawNft } from '@/common/contracts/nfts/bridge-nfts';
import { ElMessage } from 'element-plus';
import { connectedWallet, connectedChain } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'TransferNFTToEthereumDialog',
  components: {
    ShadowedButton,
    QuantityInput,
    BaseDialog
  },
  data() {
    return {
      connectedWallet,
      connectedChain,
      quantity: 0,
      loading: false
    };
  },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    closeDialog: {
      type: Function,
      default: () => ({})
    },
    selectedNft: {
      type: Object
    }
  },
  computed: {
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    }
  },
  methods: {
    async handleWithdraw() {
      if (this.connectedChain?.id !== getL2BlockchainIdHex() || !this.quantity || !this.selectedNft || !this.connectedWallet || this.loading) {
        return;
      }
      this.loading = true;
      try {
        //const walletSigner = await getWalletSigner(this.connectedWallet);
        await withdrawNft(this.connectedWallet, this.walletAddress, this.selectedNft, this.quantity);
        this.selectedNft.balance -= this.quantity;
        this.$emit('nft-transferred');
        ElMessage({
          message: 'Transfer inbound! It should appear in the "NFTs pending L2 -> L1" tab in a few seconds.',
          grouping: true,
          type: 'success'
        });
        this.closeDialog();
      } catch (e) {
        if (e instanceof Error) {
          ElMessage({
            message: `Couldn't start the transfer: ${e.message}`,
            grouping: true,
            type: 'error'
          });
        }
      }
      this.loading = false;
    }
  }
});