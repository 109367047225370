import { createApp } from 'vue';
import VueGtag from 'vue-gtag';
import VueSocialSharing from 'vue-social-sharing';
import App from './app/App.vue';
import router from './app/router';
import store from './app/store';
import '@mdi/font/css/materialdesignicons.css';

const app = createApp(App)
  .use(VueSocialSharing)
  .use(router)
  .use(store);

if (process.env.NODE_ENV === 'production') {
  app.use(VueGtag, {
    config: { id: 'G-R3ZK8XCY5H' },
  }, router);
}

app.mount('#app');
