const videoExtensions = new Set([
    'webm',
    'mpg',
    'mp2',
    'mpeg',
    'mpe',
    'mpv',
    'ogg',
    'mp4',
    'm4p',
    'm4v',
    'avi',
    'wmv',
    'mov',
    'qt',
    'flv',
    'swf',
    'avchd'
]);

export function isVideo(filename: string): boolean {
  if (!filename) {
    return false;
  }

  const fileExtension = filename.split('.').pop();
  if (!fileExtension) {
      return false;
  }

  return videoExtensions.has(fileExtension.toLocaleLowerCase());
}
