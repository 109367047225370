import { ERC20 } from './types';
import { Provider, Signer } from 'ethers';
import { Erc20__factory } from '@/contracts';

export default class ERC20Contract implements ERC20 {
  async balanceOf(signer: Signer|Provider, walletAddress: string, tokenAddress: string): Promise<bigint> {
    const contract = Erc20__factory.connect(tokenAddress, signer);

    return contract.balanceOf(walletAddress);
  }

  async allowance(signer: Signer|Provider, walletAddress: string, spenderAddress: string, tokenAddress: string): Promise<bigint> {
    const contract = Erc20__factory.connect(tokenAddress, signer);

    return contract.allowance(walletAddress, spenderAddress);
  }

  async approveAll(signer: Signer, walletAddress: string, spenderAddress: string, tokenAddress: string): Promise<void> {
    const contract = Erc20__factory.connect(tokenAddress, signer);
    const approveAmount = 115792089237316195423570985008687907853269984665640564039457584007913129639935n;

    const transaction = await contract.approve(spenderAddress, approveAmount, {
      from: walletAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: null
    });

    await transaction.wait();
  }
}
