import { defineComponent } from 'vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import SwitchNetworkToPolygonDialog from '@/components/Dialogs/SwitchNetworkToPolygonDialog.vue';
import StandardGachaponDeploymentDialog from './StandardGachaponDeploymentDialog.vue';
import { getCurrencies } from '@/common/api/currencies';
import { getL2BlockchainIdHex } from '@/common/utils/blockchains';
import { connectedChain } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'StandardGachaponDeploymentDrawer',
  components: {
    ShadowedButton,
    SwitchNetworkToPolygonDialog,
    StandardGachaponDeploymentDialog
  },
  data() {
    return {
      connectedChain,
      playcount: 10000,
      hasMissingField: false,
      isSwitchToPolygonDialogVisible: false,
      isGachaponDeploymentDialogVisible: false,
      blockchainInput: 137,
      nameInput: '',
      selectedCurrency: null,
      currencies: new Array()
    };
  },
  computed: {
    mustSwitchBlockchain() {
      return this.connectedChain?.id !== getL2BlockchainIdHex();
    }
  },
  methods: {
    async fetchCurrencies() {
      this.currencies = await getCurrencies();
    },
    async handleDeploy() {
      if (!this.nameInput || !this.selectedCurrency) {
        this.hasMissingField = true;
        return;
      }
      this.hasMissingField = false;
      if (this.mustSwitchBlockchain) {
        this.isSwitchToPolygonDialogVisible = true;
        return;
      }
      this.isGachaponDeploymentDialogVisible = true;
    }
  },
  async created() {
    await this.fetchCurrencies();
  }
});