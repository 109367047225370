import { defineComponent } from 'vue';
import DescriptionSection from './DescriptionSection.vue';
import FactorySection from './FactorySection.vue';
import FeaturesSection from './FeaturesSection.vue';
import FeesSection from './FeesSection.vue';
import GachaponSection from './GachaponSection.vue';
import MarketplaceSection from './MarketplaceSection.vue';
import StepsSection from './StepsSection.vue';
import TokenSection from './TokenSection.vue';
export default defineComponent({
  name: 'HomeView',
  components: {
    DescriptionSection,
    FactorySection,
    FeaturesSection,
    FeesSection,
    GachaponSection,
    MarketplaceSection,
    StepsSection,
    TokenSection
  }
});