import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ShadowedButton',
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    isPressed: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'custom'
    },
    shadowSize: {
      type: String,
      default: 'm'
    }
  }
});