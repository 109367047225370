import { getL2TokenSymbol } from '@/common/api/erc20-token-api';
import { StakingPoolMetadata } from '@/common/contracts/staking-pools/types';
import { getL2BlockchainId, L2_MAINNET_ID, L2_TESTNET_ID } from '@/common/utils/blockchains';

const testnetStakingPoolsMetadata: StakingPoolMetadata[] = [
  {
      address: '0x2147aAB654De80397BC3ABdc48a9EDC24a5ce7B9', // AZUKI
      stakingToken: {
        address: '0x98f50dfF2e808b0954A6f6562B952D105eF195f4',
        name: 'azuki',
        symbol: getL2TokenSymbol('0x98f50dfF2e808b0954A6f6562B952D105eF195f4'),
        decimals: 18,
        isWrappedNativeToken: false,
        isSupported: false
      },
      exchangeURL: 'https://charts.cometh.io/token/0x7cdc0421469398e0f3aa8890693d86c840ac8931',
      isClaimAndWithdraw: false,
      chainId: L2_TESTNET_ID,
      isDepreciated: true,
  },
  {
      address: '0xEe191bec55C74b14b69f5f230991704CCBcF8675', // ETH
      stakingToken: {
        address: '0xA6FA4fB5f76172d178d61B04b0ecd319C5d1C0aa',
        name: 'eth',
        symbol: getL2TokenSymbol('0xA6FA4fB5f76172d178d61B04b0ecd319C5d1C0aa'),
        decimals: 18,
        isWrappedNativeToken: false,
        isSupported: false
      },
      exchangeURL: 'https://charts.cometh.io/token/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
      isClaimAndWithdraw: false,
      chainId: L2_TESTNET_ID,
      isDepreciated: true,
  },
  {
      address: '0xd98B119966d55CcCaBdF483bF77C6d2625c14423', // AZUKI
      stakingToken: {
        address: '0x98f50dfF2e808b0954A6f6562B952D105eF195f4',
        name: 'azuki',
        symbol: getL2TokenSymbol('0x98f50dfF2e808b0954A6f6562B952D105eF195f4'),
        decimals: 18,
        isWrappedNativeToken: false,
        isSupported: false
      },
      exchangeURL: 'https://charts.cometh.io/token/0x7cdc0421469398e0f3aa8890693d86c840ac8931',
      isClaimAndWithdraw: false,
      chainId: L2_TESTNET_ID,
      isDepreciated: true,
  },
];

const mainnetStakingPoolsMetadata: StakingPoolMetadata[] = [
  {
      address: '0xE699FFCeD532BB43BD2A84C82c73C858758d12cC', // DOKI
      stakingToken: {
        address: '0x5C7F7Fe4766fE8f0fa9b41E2E4194d939488ff1C',
        name: 'doki',
        symbol: getL2TokenSymbol('0x5C7F7Fe4766fE8f0fa9b41E2E4194d939488ff1C'),
        decimals: 18,
        isWrappedNativeToken: false,
        isSupported: false
      },
      exchangeURL: 'https://charts.cometh.io/token/0x5c7f7fe4766fe8f0fa9b41e2e4194d939488ff1c',
      isClaimAndWithdraw: false,
      chainId: L2_MAINNET_ID,
      isDepreciated: true,
  },
  {
      address: '0xc0a1dFb85734E465C5dadc5683DE58358C906598', // AZUKI-MUST
      stakingToken: {
        address: '0xd0985A2E8410c03B3bB0D7997DA433428D58342f',
        name: 'azuki-must',
        symbol: getL2TokenSymbol('0xd0985A2E8410c03B3bB0D7997DA433428D58342f'),
        decimals: 18,
        isWrappedNativeToken: false,
        isSupported: false
      },
      exchangeURL: 'https://charts.cometh.io/pair/0xd0985a2e8410c03b3bb0d7997da433428d58342f',
      isClaimAndWithdraw: false,
      chainId: L2_MAINNET_ID,
      isDepreciated: true,
  },
  {
      address: '0x69Cb6f98E45c13A230d292bE0a6aF93a6521c39B', // AZUKI-ETH
      stakingToken: {
        address: '0x92Bb3233F59561FC1fEC53EfC3339E4Af8E917F4',
        name: 'azuki-eth',
        symbol: getL2TokenSymbol('0x92Bb3233F59561FC1fEC53EfC3339E4Af8E917F4'),
        decimals: 18,
        isWrappedNativeToken: false,
        isSupported: false
      },
      exchangeURL: 'https://charts.cometh.io/pair/0x92bb3233f59561fc1fec53efc3339e4af8e917f4',
      isClaimAndWithdraw: false,
      chainId: L2_MAINNET_ID,
      isDepreciated: true,
  },
  {
      address: '0x2146baC214D9BF2Da56c3d4A69b9149e457F9d8c', // DOKI-MUST
      stakingToken: {
        address: '0x9cb31B03089eca4C0f42554256d0217326D15AE7',
        name: 'doki-must',
        symbol: getL2TokenSymbol('0x9cb31B03089eca4C0f42554256d0217326D15AE7'),
        decimals: 18,
        isWrappedNativeToken: false,
        isSupported: false
      },
      exchangeURL: 'https://charts.cometh.io/pair/0x9cb31b03089eca4c0f42554256d0217326d15ae7',
      isClaimAndWithdraw: false,
      chainId: L2_MAINNET_ID,
      isDepreciated: true,
  },
  {
      address: '0xBbDC1681e43549d3871CF1953D1dD9afF320feF0', // DOKI-ETH
      stakingToken: {
        address: '0xcCeD5cB001D6081c4561bf7911F11Ccd9aAA1474',
        name: 'doki-eth',
        symbol: getL2TokenSymbol('0xcCeD5cB001D6081c4561bf7911F11Ccd9aAA1474'),
        decimals: 18,
        isWrappedNativeToken: false,
        isSupported: false
      },
      exchangeURL: 'https://charts.cometh.io/pair/0xcced5cb001d6081c4561bf7911f11ccd9aaa1474',
      isClaimAndWithdraw: false,
      chainId: L2_MAINNET_ID,
      isDepreciated: true,
  },
  {
      address: '0xD6644eA185Ed9CE3Df1D91B54471D27B36a65c30', // DOKI-MUST
      stakingToken: {
        address: '0x9cb31B03089eca4C0f42554256d0217326D15AE7',
        name: 'doki-eth',
        symbol: getL2TokenSymbol('0x9cb31B03089eca4C0f42554256d0217326D15AE7'),
        decimals: 18,
        isWrappedNativeToken: false,
        isSupported: false
      },
      exchangeURL: 'https://charts.cometh.io/pair/0x9cb31b03089eca4c0f42554256d0217326d15ae7',
      isClaimAndWithdraw: false,
      chainId: L2_MAINNET_ID,
      isDepreciated: true,
  },
  {
      address: '0x81AbDd138c50D577cda3116b763d2e3f82CE8eA2', // DOKI-ETH
      stakingToken: {
        address: '0xcCeD5cB001D6081c4561bf7911F11Ccd9aAA1474',
        name: 'doki-eth',
        symbol: getL2TokenSymbol('0xcCeD5cB001D6081c4561bf7911F11Ccd9aAA1474'),
        decimals: 18,
        isWrappedNativeToken: false,
        isSupported: false
      },
      exchangeURL: 'https://charts.cometh.io/pair/0xcced5cb001d6081c4561bf7911f11ccd9aaa1474',
      isClaimAndWithdraw: false,
      chainId: L2_MAINNET_ID,
      isDepreciated: true,
  }
];

export const stakingPoolsMetadata = getL2BlockchainId() === L2_MAINNET_ID ? mainnetStakingPoolsMetadata : testnetStakingPoolsMetadata;
