import { defineComponent } from 'vue';
import { L1_MAINNET_ID, L1_TESTNET_ID, L2_MAINNET_ID, L2_TESTNET_ID } from '@/common/utils/blockchains';
import { connectedWallet, connectedChain } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'Network',
  data() {
    return {
      connectedWallet,
      connectedChain,
      deployed: false,
      isGetMoreMaticDialogVisible: false
    };
  },
  computed: {
    blockchain() {
      if (!this.connectedChain) {
        return 'UNSUPPORTED NETWORK';
      }
      switch (+this.connectedChain.id) {
        case L1_MAINNET_ID:
          return 'ETHEREUM L1';
        case L1_TESTNET_ID:
          return 'ETH GOERLI - TEST';
        case L2_MAINNET_ID:
          return 'POLYGON (MATIC) L2';
        case L2_TESTNET_ID:
          return 'MATIC MUMBAI - TEST';
        default:
          return 'UNSUPPORTED NETWORK';
      }
    },
    isWalletConnected() {
      return !!this.connectedWallet;
    }
  }
});