import { defineComponent } from 'vue';
export default defineComponent({
  name: 'BuyIcon',
  props: {
    color: {
      type: String,
      default: 'inherit'
    },
    width: String,
    height: String,
    isDisabled: Boolean
  }
});