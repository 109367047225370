import { CoingeckoPrices, getTokensPrices } from '../../api/coingecko-api';
import { getTokenPairETHValue, ExchangeSubgraphEndpoint } from '../../api/uniswap-v2-subgraph-api';
import { getL1AddressOfToken, getL2TokenSymbol, getL2LPTokenAddressToNativeTokens, WETHL1TokenAddress } from '../../api/erc20-token-api';
import { StakingPool, StakingPoolMetadata, Reward } from './types';
import ERC20Token from '@/common/types/ERC20Token';
import { formatEther, parseUnits, Signer } from 'ethers';
import { ComethStaking__factory } from '@/contracts';
import { getL2Provider } from '@/common/utils/ethers';

const provider = getL2Provider();

export default class ComethABIStakingPool implements StakingPool {
  metadata: StakingPoolMetadata;
  totalStakedAmount = 0n;
  APR = 0;
  stakedAmount = 0n;
  rewardableTokens: ERC20Token[] = [];
  rewardableRates: bigint[] = [];
  rewards: Reward[] = [];

  constructor(metadata: StakingPoolMetadata) {
    this.metadata = metadata;
  }

  async claimAll(signer: Signer, userAddress: string): Promise<void> {
    const contract = ComethStaking__factory.connect(this.metadata.address, signer);

    const transaction = await contract.getReward({
      from: userAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: null
    });

    await transaction.wait();
  }

  async stake(signer: Signer, userAddress: string, amount: bigint): Promise<void> {
    const contract = ComethStaking__factory.connect(this.metadata.address, signer);

    const transaction = await contract.stake(amount, {
      from: userAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: null
    });

    await transaction.wait();
  }

  async unstake(signer: Signer, userAddress: string, amount: bigint): Promise<void> {
    const contract = ComethStaking__factory.connect(this.metadata.address, signer);

    const transaction = await contract.withdraw(amount, {
      from: userAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: null
    });

    await transaction.wait();
  }

  async fetchTotalStakedAmount(): Promise<bigint> {
    const contract = ComethStaking__factory.connect(this.metadata.address, provider);

    return contract.totalSupply();
  }

  async fetchRewardableTokens(): Promise<ERC20Token[]> {
    const contract = ComethStaking__factory.connect(this.metadata.address, provider);

    const tokenAddresses: string[] = await contract.getRewardsTokens();

    const rewardableTokens: ERC20Token[] = [];
    for (const tokenAddress of tokenAddresses) {
      rewardableTokens.push(
        {
          address: tokenAddress,
          symbol: getL2TokenSymbol(tokenAddress),
          name: '',
          decimals: 18,
          isSupported: false,
          isWrappedNativeToken: false
        }
      );
    }

    return rewardableTokens;
  }

  async fetchRewardableRates(): Promise<bigint[]> {
    const contract = ComethStaking__factory.connect(this.metadata.address, provider);

    return contract.getRewardRates();
  }

  protected getL1TokenAddress(tokenAddress: string): string {
    const L1TokenAddress = getL1AddressOfToken(tokenAddress);
    if (L1TokenAddress === '') {
      return WETHL1TokenAddress;
    } else if (!L1TokenAddress) {
      return '';
    }

    return L1TokenAddress;
  }

  protected async getPoolTokensPrices(): Promise<CoingeckoPrices> {
    const lpTokenNativeAssets = getL2LPTokenAddressToNativeTokens(this.metadata.stakingToken.address);
    const stakingTokensAddresses = (lpTokenNativeAssets.length !== 0 ? lpTokenNativeAssets : [this.metadata.stakingToken.address])
      .map((tokenAddress) => this.getL1TokenAddress(tokenAddress));
    const rewardableTokensAddresses = this.rewardableTokens.map((token) => this.getL1TokenAddress(token.address));

    return getTokensPrices([WETHL1TokenAddress, ...stakingTokensAddresses, ...rewardableTokensAddresses]);
  }

  protected async getStakedTokensUSDValue(tokenPrices: CoingeckoPrices): Promise<number> {
    const lpTokenNativeAssets = getL2LPTokenAddressToNativeTokens(this.metadata.stakingToken.address);
    const totalStakedAmountToEther = Number(parseUnits(this.totalStakedAmount.toString(), 'ether'));
    if (lpTokenNativeAssets.length === 0) {
      return (tokenPrices[this.getL1TokenAddress(this.metadata.stakingToken.address).toLowerCase()]?.usd ?? 0) * totalStakedAmountToEther;
    }

    const lpTokenETHValue = await getTokenPairETHValue(this.metadata.stakingToken.address, ExchangeSubgraphEndpoint.ComethSwap);

    return (tokenPrices[WETHL1TokenAddress.toLowerCase()]?.usd ?? 0) * lpTokenETHValue * totalStakedAmountToEther;
  }

  protected getRewardsUSDValue(tokenPrices: CoingeckoPrices): number {
    const sum =  this.rewardableTokens.reduce((sum, token, index) => {
      const rewardRate = this.rewardableRates[index];
      // Multiply by 10 000 because BN floor the provided number, i.e: new BN(0.21).toNumber() === new BN(0).toNumber()
      const tokenPrice = BigInt((tokenPrices[this.getL1TokenAddress(token.address).toLowerCase()]?.usd ?? 0) * 10000);
      return sum + (rewardRate * tokenPrice / 10000n);
    }, 0n);

    return +formatEther(sum);
  }

  async fetchAPR(): Promise<number> {
    if (this.metadata.isDepreciated) {
      return 0;
    }

    try {
      const tokensUSDValue = await this.getPoolTokensPrices();
      const stakedTokensUSDValue = await this.getStakedTokensUSDValue(tokensUSDValue);
      const rewardsUSDValue = this.getRewardsUSDValue(tokensUSDValue);

      return (rewardsUSDValue * 60 * 60 * 24 * 365.25) / stakedTokensUSDValue * 100;
    } catch(e) {
      console.error(e);
    }

    return 0;
  }

  async loadPoolData(): Promise<void> {
    const promises = [this.fetchTotalStakedAmount(), this.fetchRewardableTokens(), this.fetchRewardableRates()];

    const [totalStakedAmountResult, rewardableTokensResult, rewardableRatesResult] = await Promise.allSettled(promises);
    if (totalStakedAmountResult.status === 'fulfilled') {
      this.totalStakedAmount = totalStakedAmountResult.value as bigint;
    }
    if (rewardableTokensResult.status === 'fulfilled') {
      this.rewardableTokens = rewardableTokensResult.value as ERC20Token[];
    }
    if (rewardableRatesResult.status === 'fulfilled') {
      this.rewardableRates = rewardableRatesResult.value as bigint[];
    }

    this.APR = await this.fetchAPR();
  }

  async fetchStakedAmount(userAddress: string): Promise<bigint> {
    const contract = ComethStaking__factory.connect(this.metadata.address, provider);

    return contract.balanceOf(userAddress);
  }

  async fetchRewards(userAddress: string): Promise<Reward[]> {
    const rewards: Reward[] = [];
    if (this.totalStakedAmount === 0n || !this.rewardableTokens.length || !this.rewardableRates.length) {
      return rewards;
    }

    const contract = ComethStaking__factory.connect(this.metadata.address, provider);

    const claimable = await contract.earned(userAddress);

    //const claimable = await this.contract.methods.earned(userAddress).call();
    if (claimable.length > 0) {
      // Multiply by 1 000 000 to improve precision and avoid BN slicing decimals
      const oneMillion = 1000000n;
      const userRewardRatesCut = oneMillion * this.stakedAmount / this.totalStakedAmount;

      for (let i = 0; i < this.rewardableTokens.length; i++) {
        let userRewardRate = 0n;
        if (!this.metadata.isDepreciated) {
          userRewardRate = this.rewardableRates[i] * (userRewardRatesCut / oneMillion);
        }

        if (!claimable[i]) {
          continue;
        }

        rewards.push({ token: this.rewardableTokens[i], claimable: claimable[i], rate: userRewardRate });
      }
    }

    return rewards;
  }

  async loadUserData(userAddress: string): Promise<void> {
    this.stakedAmount = await this.fetchStakedAmount(userAddress);
    this.rewards = await this.fetchRewards(userAddress);
  }
}
