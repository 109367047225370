import Gachapon from '@/common/types/Gachapon';
import { GachaponManager } from './types';
import { syncMachine } from '@/common/api/gachapons/legacy-gachapons';
import OwnedNFT from '@/common/types/OwnedNFT';
import { parseUnits, Signer } from 'ethers';
import { LegacyGachapon__factory } from '@/contracts';

export default class LegacyGachaponManager implements GachaponManager {
  gachapon: Gachapon;

  constructor(gachapon: Gachapon) {
    this.gachapon = gachapon;
  }

  async unlock(signer: Signer, userAddress: string): Promise<void> {
    const contract = LegacyGachapon__factory.connect(this.gachapon.address, signer);
    const transaction = await contract.unlockMachine({
      from: userAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: null
    });

    await transaction.wait(8);

    await syncMachine(this.gachapon.address);
  }

  async lock(signer: Signer, userAddress: string): Promise<void> {
    const contract = LegacyGachapon__factory.connect(this.gachapon.address, signer);
    const transaction = await contract.lockMachine({
      from: userAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: null
    });

    await transaction.wait(8);

    await syncMachine(this.gachapon.address);
  }

  async setName(signer: Signer, userAddress: string, name: string): Promise<void> {
    const contract = LegacyGachapon__factory.connect(this.gachapon.address, signer);
    const transaction = await contract.changeMachineTitle(name, {
      from: userAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: null
    });

    await transaction.wait(8);

    await syncMachine(this.gachapon.address);
  }

  async setDescription(signer: Signer, userAddress: string, description: string): Promise<void> {
    const contract = LegacyGachapon__factory.connect(this.gachapon.address, signer);
    const transaction = await contract.changeMachineDescription(description, {
      from: userAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: null
    });

    await transaction.wait(8);

    await syncMachine(this.gachapon.address);
  }

  async setSpinPrice(signer: Signer, userAddress: string, price: number): Promise<void> {
    const contract = LegacyGachapon__factory.connect(this.gachapon.address, signer);
    const transaction = await contract.changePlayOncePrice(parseUnits(price.toString(), this.gachapon.currency.decimals), {
      from: userAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: null
    });

    await transaction.wait(8);

    await syncMachine(this.gachapon.address);
  }

  async loadNft(signer: Signer, userAddress: string, ownedNft: OwnedNFT, amount: number): Promise<void> {
    const contract = LegacyGachapon__factory.connect(this.gachapon.address, signer);
    const transaction = await contract.addCard(ownedNft.tokenId, amount, {
      from: userAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: null
    });

    await transaction.wait(8);

    await syncMachine(this.gachapon.address);
  }
}
