import OwnedNFT from '@/common/types/OwnedNFT';
import {TransferableNFT} from './types';
import {getGasPrice} from '@/common/api/gas-price';
import { Signer, parseUnits } from 'ethers';
import { Erc1155__factory } from '@/contracts';

export default class ERC1155Token implements TransferableNFT {
  nft: OwnedNFT;

  constructor(nft: OwnedNFT) {
    this.nft = nft;
  }

  async transfer(signer: Signer, fromAddress: string, toAddress: string, quantity: number): Promise<void> {
    const gasPrice = await getGasPrice();

    const contract = Erc1155__factory.connect(this.nft.collectionAddress, signer);
    const transaction = await contract.safeTransferFrom(fromAddress, toAddress, this.nft.tokenId, quantity, '0x', {
      from: fromAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: gasPrice ? parseUnits(gasPrice.toString(), 'gwei') : null
    });

    await transaction.wait();
  }
}
