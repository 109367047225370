import { defineComponent } from 'vue';
import BaseDialog from './BaseDialog.vue';
import AzukiSays from './AzukiSays.vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import { connectedWallet, connectedChain, setChain, L1_CHAIN } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'SwitchNetworkToEthereumDialog',
  components: {
    ShadowedButton,
    BaseDialog,
    AzukiSays
  },
  data() {
    return {
      connectedWallet,
      connectedChain,
      isLoading: false
    };
  },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    closeDialog: {
      type: Function,
      default: () => ({})
    }
  },
  methods: {
    async handleSwitch() {
      if (this.isLoading || !this.connectedWallet || this.connectedChain?.id === L1_CHAIN.id) {
        return;
      }
      this.isLoading = true;
      await setChain({
        chainId: L1_CHAIN.id,
        wallet: this.connectedWallet.label
      });
      this.isLoading = false;
      this.closeDialog();
    }
  }
});