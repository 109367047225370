import { defineComponent } from 'vue';
const crankMusic = require('@/app/assets/gachapon-machine/audio/crank.mp3');
export default defineComponent({
  name: 'Gachapon',
  data() {
    return {
      displayDefaultInitialState: false,
      displayDefaultPendingState: false,
      displayDefaultEndState: false,
      initialStateImageFolder: '/gachapons/machines/initial',
      pendingStateImageFolder: '/gachapons/machines/pending',
      endStateImageFolder: '/gachapons/machines/end'
    };
  },
  props: {
    gachaponAddress: String,
    isAnimated: {
      type: Boolean,
      default: false
    },
    playEndingAnimation: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    initialStateImage() {
      return `${this.initialStateImageFolder}/${this.gachaponAddress}.png`;
    },
    pendingStateImage() {
      return `${this.pendingStateImageFolder}/${this.gachaponAddress}.gif`;
    },
    endStateImage() {
      return `${this.endStateImageFolder}/${this.gachaponAddress}.gif`;
    },
    defaultInitialStateImage() {
      return `${this.initialStateImageFolder}/default.png`;
    },
    defaultPendingStateImage() {
      return `${this.pendingStateImageFolder}/default.gif`;
    },
    defaultEndStateImage() {
      return `${this.endStateImageFolder}/default.gif`;
    }
  },
  setup() {
    const music = new Audio(crankMusic);
    function playMusic() {
      music.currentTime = 0;
      music.play();
      music.volume = 0.2;
    }
    return {
      playMusic
    };
  },
  watch: {
    playEndingAnimation(newValue) {
      if (this.isAnimated && newValue) {
        this.playMusic();
      }
    }
  }
});