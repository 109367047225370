import { defineComponent } from 'vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import BaseDialog from './BaseDialog.vue';
import AzukiSeriouslySays from './AzukiSeriouslySays.vue';
export default defineComponent({
  name: 'BuyERC20TokenDialog',
  components: {
    ShadowedButton,
    BaseDialog,
    AzukiSeriouslySays
  },
  props: {
    tokenAddress: {
      type: String,
      default: ''
    },
    tokenSymbol: {
      type: String,
      default: ''
    },
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    closeDialog: {
      type: Function
    }
  },
  computed: {
    exchangeLink() {
      return `https://paraswap.io/#/${this.tokenAddress}?network=polygon`;
    }
  }
});