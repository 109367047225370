import { defineComponent } from 'vue';
import { getSubgraphBehindBlocks } from '@/common/api/subgraphes/polygon';
import { getL2BlockchainId, getL2BlockchainIdHex } from '@/common/utils/blockchains';
import TransferNFTToEthereumDialog from '@/components/Dialogs/TransferNFTToEthereumDialog.vue';
import SwitchNetworkToPolygonDialog from '@/components/Dialogs/SwitchNetworkToPolygonDialog.vue';
import TransferNFTDialog from '@/components/Dialogs/TransferNFTDialog.vue';
import TransferNFTsToEthereumDialog from '@/components/Dialogs/TransferNFTsToEthereumDialog.vue';
import OwnedNFTsList from './OwnedNFTsList.vue';
import { ElMessage } from 'element-plus';
import { connectedWallet, connectedChain } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'PolygonTab',
  components: {
    OwnedNFTsList,
    TransferNFTToEthereumDialog,
    TransferNFTsToEthereumDialog,
    TransferNFTDialog,
    SwitchNetworkToPolygonDialog
  },
  data() {
    return {
      connectedWallet,
      connectedChain,
      forceRefreshList: false,
      isSwitchNetworkDialogVisible: false,
      isNFTWithdrawDialogVisible: false,
      isNFTsWithdrawDialogVisible: false,
      isNFTTransferDialogVisible: false,
      selectedNft: null,
      selectedNfts: new Array(),
      isCollectionOutOfSync: false,
      blocksBehind: 0
    };
  },
  computed: {
    minutesBehind() {
      return Math.floor(this.blocksBehind / 100) * 5;
    },
    L2BlockchainId() {
      return getL2BlockchainId();
    },
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    }
  },
  methods: {
    async isTabOutOfSync() {
      this.blocksBehind = await getSubgraphBehindBlocks();
      this.isCollectionOutOfSync = this.blocksBehind > 100;
    },
    setSelectedNfts(selectedNfts) {
      this.selectedNfts = selectedNfts;
    },
    closeNFTWithdrawDialog() {
      this.isNFTWithdrawDialogVisible = false;
      this.selectedNft = null;
    },
    openNFTWithdrawDialog(card) {
      if (this.connectedChain?.id !== getL2BlockchainIdHex()) {
        this.openSwitchNetworkDialog();
        return;
      }
      this.selectedNft = card;
      this.isNFTWithdrawDialogVisible = true;
    },
    closeNFTsWithdrawDialog() {
      this.isNFTsWithdrawDialogVisible = false;
    },
    openNFTsWithdrawDialog() {
      if (this.connectedChain?.id !== getL2BlockchainIdHex()) {
        this.openSwitchNetworkDialog();
        return;
      }
      if (this.selectedNfts.length === 0) {
        ElMessage({
          message: 'You need to select one or multiple NFTs first.',
          grouping: true,
          type: 'error'
        });
        return;
      }
      this.isNFTsWithdrawDialogVisible = true;
    },
    closeNFTTransferDialog() {
      this.isNFTTransferDialogVisible = false;
      this.selectedNft = null;
    },
    openNFTTransferDialog(card) {
      if (this.connectedChain?.id !== getL2BlockchainIdHex()) {
        this.openSwitchNetworkDialog();
        return;
      }
      this.selectedNft = card;
      this.isNFTTransferDialogVisible = true;
    },
    closeSwitchNetworkDialog() {
      this.isSwitchNetworkDialogVisible = false;
    },
    openSwitchNetworkDialog() {
      this.isSwitchNetworkDialogVisible = true;
    }
  },
  watch: {
    walletAddress: async function () {
      if (this.connectedWallet) {
        await this.isTabOutOfSync();
      }
    }
  }
});