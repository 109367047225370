import { defineComponent } from 'vue';
import { getAllLegacyGachapons, getVRFGachapons } from '@/common/api/gachapons';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import HeaderCarousel from './HeaderCarousel.vue';
import CategoriesFilterDropdown from './CategoriesFilterDropdown.vue';
import GachaponTypeFilter from './GachaponTypeFilter.vue';
import FilterSortDropdown from './FilterSortDropdown.vue';
import StandardGachaponWarningDialog from './StandardGachaponWarningDialog.vue';
import GachaponCard from '@/components/Gachapon/GachaponCard.vue';
import { getSortedByStateGachapons, getGachaponState } from '@/common/utils/gachapons';
import { getUserProfilesIndexedByAddress } from '@/common/api/users';
import { ElMessage } from 'element-plus';
export default defineComponent({
  name: 'GachaView',
  components: {
    HeaderCarousel,
    CategoriesFilterDropdown,
    GachaponTypeFilter,
    FilterSortDropdown,
    GachaponCard,
    ShadowedButton,
    StandardGachaponWarningDialog
  },
  data() {
    return {
      selectedGachapon: null,
      currentPage: 0,
      pageSize: 50,
      isLoading: false,
      hasLoadedAll: false,
      legacyGachapons: [],
      sortedAndFilteredLegacyGachapons: [],
      gachapons: [],
      owners: {},
      sortOrder: 'desc',
      filters: {
        banned: false,
        categoryId: undefined,
        curated: undefined,
        empty: false,
        locked: undefined,
        lockedForever: false,
        soldOut: undefined
      }
    };
  },
  computed: {
    hideStandardGachaponWarning() {
      return localStorage.hideStandardGachaponWarning === 'true' || localStorage.hideStandardGachaponWarning === true;
    },
    hasViewportReachedBottomOfPage() {
      return this.$store.state.viewport.hasReachedBottomOfPage;
    },
    categoryIdFilter() {
      return this.filters.categoryId;
    },
    curatedGachaponsOnlyFilter() {
      return this.filters.curated;
    },
    lockedFilter() {
      return this.filters.locked;
    },
    soldOutFilter() {
      return this.filters.soldOut;
    }
  },
  methods: {
    mustDisplayStandardGachaponWarning() {
      return !localStorage.hideStandardGachaponWarning || localStorage.hideStandardGachaponWarning === 'false';
    },
    selectGachapon(gachapon) {
      if (!gachapon || gachapon.isCurated) {
        return;
      }
      this.selectedGachapon = gachapon;
    },
    getGachaponState(gachapon) {
      return getGachaponState(gachapon);
    },
    async fetchLegacyGachapons() {
      this.legacyGachapons = await getAllLegacyGachapons();
      this.sortAndFilterLegacyGachapons();
    },
    async fetchGachapons() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      setTimeout(async () => {
        try {
          const gachapons = await getVRFGachapons(this.currentPage * this.pageSize, this.pageSize, 'createdAt', this.sortOrder, this.filters);
          if (gachapons.length < this.pageSize) {
            this.hasLoadedAll = true;
          } else {
            this.currentPage++;
          }
          this.gachapons = [...this.gachapons, ...gachapons];
        } catch (e) {
          this.hasLoadedAll = true;
          ElMessage({
            message: 'Couldn\'t retrieve gachapons.',
            grouping: true,
            type: 'error'
          });
        }
        this.isLoading = false;
      }, 100);
    },
    async resetAndFetchGachapons() {
      this.hasLoadedAll = false;
      this.currentPage = 0;
      this.gachapons = [];
      await this.fetchGachapons();
      this.sortAndFilterLegacyGachapons();
    },
    sortAndFilterLegacyGachapons() {
      if (this.curatedGachaponsOnlyFilter === false) {
        this.sortedAndFilteredLegacyGachapons = [];
        return;
      }
      const sortedByStateLegacyGachapons = getSortedByStateGachapons(this.legacyGachapons.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime()));
      let legacyGachapons = [...sortedByStateLegacyGachapons['available'], ...(this.filters.soldOut === false ? [] : sortedByStateLegacyGachapons['sold-out']), ...(this.filters.locked === false ? [] : sortedByStateLegacyGachapons['locked'])];
      if (this.categoryIdFilter && this.categoryIdFilter > -1) {
        legacyGachapons = legacyGachapons.filter(gachapon => gachapon.category.id);
      }
      this.sortedAndFilteredLegacyGachapons = this.sortOrder === 'desc' ? legacyGachapons.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime()) : legacyGachapons.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
    },
    handleGachaponType(type) {
      if (type === 'all') {
        this.filters.curated = undefined;
        return;
      }
      if (type === 'curated') {
        this.filters.curated = true;
      } else if (type === 'standard') {
        this.filters.curated = false;
      }
    }
  },
  async created() {
    await this.fetchLegacyGachapons();
    await this.fetchGachapons();
  },
  watch: {
    hasViewportReachedBottomOfPage: async function () {
      if (this.hasLoadedAll) {
        return;
      }
      await this.fetchGachapons();
    },
    sortOrder: async function () {
      await this.resetAndFetchGachapons();
    },
    curatedGachaponsOnlyFilter: async function () {
      await this.resetAndFetchGachapons();
    },
    lockedFilter: async function () {
      await this.resetAndFetchGachapons();
    },
    soldOutFilter: async function () {
      await this.resetAndFetchGachapons();
    },
    categoryIdFilter: async function () {
      await this.resetAndFetchGachapons();
    },
    legacyGachapons: async function () {
      const owners = await getUserProfilesIndexedByAddress(this.legacyGachapons.map(gachapon => gachapon.ownerAddress));
      this.owners = {
        ...this.owners,
        ...owners
      };
    },
    gachapons: async function () {
      const owners = await getUserProfilesIndexedByAddress(this.gachapons.map(gachapon => gachapon.ownerAddress));
      this.owners = {
        ...this.owners,
        ...owners
      };
    }
  }
});