import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "quantity-input-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input_number = _resolveComponent("el-input-number");
  const _component_ShadowedButton = _resolveComponent("ShadowedButton");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_input_number, {
    class: "quantity-input",
    "model-value": _ctx.quantity === 0 ? undefined : _ctx.quantity,
    "controls-position": "right",
    min: _ctx.minQuantity,
    max: _ctx.maxQuantity,
    placeholder: _ctx.placeholder,
    disabled: _ctx.disabled,
    onChange: _ctx.handleChange
  }, null, 8, ["model-value", "min", "max", "placeholder", "disabled", "onChange"]), _createElementVNode("div", null, [_createVNode(_component_ShadowedButton, {
    shadowSize: "xs",
    theme: "violet-white",
    isDisabled: _ctx.disabled,
    onClick: _ctx.setMaxQuantity
  }, {
    default: _withCtx(() => [_createTextVNode(" MAX ")]),
    _: 1
  }, 8, ["isDisabled", "onClick"])])]);
}