import httpClient from '@/common/utils/http-client';
import { getAllNftsByTokenIdsAndCollectionAddresses } from '@/common/api/nfts/nfts';
import { OwnedNFTsData } from './types';
import OwnedNFT from '@/common/types/OwnedNFT';
import NFT from '@/common/types/NFT';
import { getL1BlockchainId, getL2BlockchainId } from '@/common/utils/blockchains';

const API_URL_L1 = process.env.NODE_ENV === 'production' ? 'https://api.thegraph.com/subgraphs/name/dokidokifinance/momiji' : 'https://api.thegraph.com/subgraphs/name/dokidokifinance/momiji-goerli';
const API_URL_L2 = process.env.NODE_ENV === 'production' ? 'https://api.thegraph.com/subgraphs/name/dokidokifinance/momiji-matic' : 'https://api.thegraph.com/subgraphs/name/dokidokifinance/momiji-mumbai';

async function getOwnedNftsByOwnerAddress(apiUrl: string, ownerAddress: string, orderBy: 'lastReceived' | 'balance' = 'lastReceived', orderDirection: 'asc' | 'desc' = 'asc', from = 0, limit = 100, includeNullQuantity = false): Promise<OwnedNFTsData|null> {
  const response = await httpClient.post(apiUrl, {
    query: `{
      ownerships(first: ${limit}, skip: ${from}, where: {owner: "${ownerAddress.toLowerCase()}"${includeNullQuantity ? '' : ', balance_gt: 0'}}, orderBy: ${orderBy}, orderDirection: ${orderDirection}) {
        id
        balance
        lastReceived
        nft {
          tokenId
          contractAddress
        }
      }
    }`,
  });

  if (response.data?.ownerships) {
    return response.data;
  }

  return null;
}

export async function getL1OwnedNftsByOwnerAddress(ownerAddress: string, orderBy: 'lastReceived' | 'balance' = 'lastReceived', orderDirection: 'asc' | 'desc' = 'asc', from = 0, limit = 100, includeNullQuantity = false): Promise<OwnedNFT[]> {
  const ownedNfts: OwnedNFT[] = [];
  const ownedNftsData = await getOwnedNftsByOwnerAddress(API_URL_L1, ownerAddress, orderBy, orderDirection, from, limit, includeNullQuantity);
  if (!ownedNftsData) {
    return ownedNfts;
  }

  const tokenIds = [];
  const collectionAddresses = [];
  const balancesIndexedByTokenIds: Record<string, number> = {};
  for (const ownedNftData of ownedNftsData.ownerships) {
    tokenIds.push(ownedNftData.nft.tokenId);
    collectionAddresses.push(ownedNftData.nft.contractAddress);
    balancesIndexedByTokenIds[ownedNftData.nft.tokenId] = +ownedNftData.balance;
  }

  const nfts: NFT[] = await getAllNftsByTokenIdsAndCollectionAddresses(tokenIds, collectionAddresses, getL1BlockchainId());
  for (const nft of nfts) {
    ownedNfts.push({...nft, balance: balancesIndexedByTokenIds[nft.tokenId]});
  }

  return ownedNfts;
}

export async function getAllL1OwnedNftsByOwnerAddress(ownerAddress: string, orderBy: 'lastReceived' | 'balance' = 'lastReceived', orderDirection: 'asc' | 'desc' = 'asc', includeNullQuantity = false): Promise<OwnedNFT[]> {
  const limit = 100;
  let from = 0;
  let ownedNfts: OwnedNFT[] = [];

  let ownedNftsBatch = [];
  do {
    ownedNftsBatch = await getL1OwnedNftsByOwnerAddress(ownerAddress, orderBy, orderDirection, from, limit, includeNullQuantity);
    ownedNfts = [...ownedNfts, ...ownedNftsBatch];

    from += limit;
  } while (ownedNftsBatch.length >= limit);

  return ownedNfts;
}

export async function getL2OwnedNftsByOwnerAddress(ownerAddress: string, orderBy: 'lastReceived' | 'balance' = 'lastReceived', orderDirection: 'asc' | 'desc' = 'asc', from = 0, limit = 100, includeNullQuantity = false): Promise<OwnedNFT[]> {
  const ownedNfts: OwnedNFT[] = [];
  const ownedNftsData = await getOwnedNftsByOwnerAddress(API_URL_L2, ownerAddress, orderBy, orderDirection, from, limit, includeNullQuantity);
  if (!ownedNftsData) {
    return ownedNfts;
  }

  const tokenIds = [];
  const collectionAddresses = [];
  const balancesIndexedByTokenIds: Record<string, number> = {};
  for (const ownedNftData of ownedNftsData.ownerships) {
    tokenIds.push(ownedNftData.nft.tokenId);
    collectionAddresses.push(ownedNftData.nft.contractAddress);
    balancesIndexedByTokenIds[ownedNftData.nft.tokenId] = +ownedNftData.balance;
  }

  const nfts: NFT[] = await getAllNftsByTokenIdsAndCollectionAddresses(tokenIds, collectionAddresses, getL2BlockchainId());
  for (const nft of nfts) {
    ownedNfts.push({...nft, balance: balancesIndexedByTokenIds[nft.tokenId]});
  }

  return ownedNfts;
}

export async function getAllL2OwnedNftsByOwnerAddress(ownerAddress: string, orderBy: 'lastReceived' | 'balance' = 'lastReceived', orderDirection: 'asc' | 'desc' = 'asc', includeNullQuantity = false): Promise<OwnedNFT[]> {
  const limit = 100;
  let from = 0;
  let ownedNfts: OwnedNFT[] = [];

  let ownedNftsBatch = [];
  do {
    ownedNftsBatch = await getL2OwnedNftsByOwnerAddress(ownerAddress, orderBy, orderDirection, from, limit, includeNullQuantity);
    ownedNfts = [...ownedNfts, ...ownedNftsBatch];

    from += limit;
  } while (ownedNftsBatch.length >= limit);

  return ownedNfts;
}
