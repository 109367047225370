import PendingNFT from '@/common/types/PendingNFT';
import OwnedNFT from '@/common/types/OwnedNFT';
import { getDokidokiL1CollectionAddress, getDokidokiL2CollectionAddress } from '@/common/utils/nfts';
import posClient from '@/common/utils/matic-pos-client';
import { WalletState } from '@web3-onboard/core/dist/types';
import Web3 from 'web3';
import { L1_HTTPS_RPC, L2_HTTPS_RPC } from '@/common/utils/blockchains/rpcs';

const getWeb3L1Provider = (): Web3 => {
  return new Web3(L1_HTTPS_RPC);
};

const getWeb3L2Provider = (): Web3 => {
  return new Web3(L2_HTTPS_RPC);
};

const getWeb3Provider = (connectedWallet: WalletState): Web3 => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return new Web3(connectedWallet.provider);
};

export async function isL1ContractApproved(connectedWallet: WalletState, userAddress: string): Promise<boolean> {
  const provider = getWeb3Provider(connectedWallet);
  await posClient.init({
    network: process.env.NODE_ENV == 'production' ? 'mainnet' : 'testnet',
    version: process.env.NODE_ENV == 'production' ? 'v1' : 'mumbai',
    parent: {
      provider: provider,
      defaultConfig: {
        from: ''
      }
    },
    child: {
      provider: getWeb3L2Provider(),
      defaultConfig: {
        from: ''
      }
    },
  });

  return posClient.erc1155(getDokidokiL1CollectionAddress(), true).isApprovedAll(userAddress);
}

export async function approveL1Contract(connectedWallet: WalletState, userAddress: string): Promise<void> {
  const provider = getWeb3Provider(connectedWallet);
  await posClient.init({
    network: process.env.NODE_ENV == 'production' ? 'mainnet' : 'testnet',
    version: process.env.NODE_ENV == 'production' ? 'v1' : 'mumbai',
    parent: {
      provider: provider,
      defaultConfig: {
        from: userAddress
      }
    },
    child: {
      provider: getWeb3L2Provider(),
      defaultConfig: {
        from: userAddress
      }
    },
  });

  const result = await posClient.erc1155(getDokidokiL1CollectionAddress(), true).approveAll();
  await result.getTransactionHash();
  await result.getReceipt();
}

export async function retrievePendingNft(connectedWallet: WalletState, userAddress: string, pendingNft: PendingNFT): Promise<void> {
  const provider = getWeb3Provider(connectedWallet);
  await posClient.init({
    network: process.env.NODE_ENV == 'production' ? 'mainnet' : 'testnet',
    version: process.env.NODE_ENV == 'production' ? 'v1' : 'mumbai',
    parent: {
      provider: provider,
      defaultConfig: {
        from: userAddress
      }
    },
    child: {
      provider: getWeb3L2Provider(),
      defaultConfig: {
        from: userAddress
      }
    },
  });

  const result = pendingNft.isBatch
    ? await posClient.erc1155(getDokidokiL1CollectionAddress(), true).withdrawExitMany(pendingNft.transactionHash)
    : await posClient.erc1155(getDokidokiL1CollectionAddress(), true).withdrawExit(pendingNft.transactionHash);
  await result.getTransactionHash();
  await result.getReceipt();
}

export async function depositNft(connectedWallet: WalletState, userAddress: string, ownedNft: OwnedNFT, amount: number): Promise<void> {
  const provider = getWeb3Provider(connectedWallet);
  await posClient.init({
    network: process.env.NODE_ENV == 'production' ? 'mainnet' : 'testnet',
    version: process.env.NODE_ENV == 'production' ? 'v1' : 'mumbai',
    parent: {
      provider: provider,
      defaultConfig: {
        from: userAddress
      }
    },
    child: {
      provider: getWeb3L2Provider(),
      defaultConfig: {
        from: userAddress
      }
    },
  });

  const result = await posClient.erc1155(getDokidokiL1CollectionAddress(), true).deposit({
    amount: amount,
    tokenId: ownedNft.tokenId,
    userAddress: userAddress
  });
  await result.getTransactionHash();
  await result.getReceipt();
}

export async function depositNfts(connectedWallet: WalletState, userAddress: string, ownedNfts: OwnedNFT[], amounts: number[]): Promise<void> {
  const provider = getWeb3Provider(connectedWallet);
  await posClient.init({
    network: process.env.NODE_ENV == 'production' ? 'mainnet' : 'testnet',
    version: process.env.NODE_ENV == 'production' ? 'v1' : 'mumbai',
    parent: {
      provider: provider,
      defaultConfig: {
        from: userAddress
      }
    },
    child: {
      provider: getWeb3L2Provider(),
      defaultConfig: {
        from: userAddress
      }
    },
  });

  const result = await posClient.erc1155(getDokidokiL1CollectionAddress(), true).depositMany({
    amounts: amounts,
    tokenIds: ownedNfts.map((ownedNft) => ownedNft.tokenId),
    userAddress: userAddress
  });
  await result.getTransactionHash();
  await result.getReceipt();
}

export async function withdrawNft(connectedWallet: WalletState, userAddress: string, ownedNft: OwnedNFT, amount: number): Promise<void> {
  const provider = getWeb3Provider(connectedWallet);
  await posClient.init({
    network: process.env.NODE_ENV == 'production' ? 'mainnet' : 'testnet',
    version: process.env.NODE_ENV == 'production' ? 'v1' : 'mumbai',
    parent: {
      provider: getWeb3L1Provider(),
      defaultConfig: {
        from: userAddress
      }
    },
    child: {
      provider: provider,
      defaultConfig: {
        from: userAddress
      }
    },
  });

  const result = await posClient.erc1155(getDokidokiL2CollectionAddress()).withdrawStart(
    ownedNft.tokenId,
    amount
  );
  await result.getTransactionHash();
  await result.getReceipt();
}

export async function withdrawNfts(connectedWallet: WalletState, userAddress: string, ownedNfts: OwnedNFT[], amounts: number[]): Promise<void> {
  const provider = getWeb3Provider(connectedWallet);
  await posClient.init({
    network: process.env.NODE_ENV == 'production' ? 'mainnet' : 'testnet',
    version: process.env.NODE_ENV == 'production' ? 'v1' : 'mumbai',
    parent: {
      provider: getWeb3L1Provider(),
      defaultConfig: {
        from: userAddress
      }
    },
    child: {
      provider: provider,
      defaultConfig: {
        from: userAddress
      }
    },
  });

  const result = await posClient.erc1155(getDokidokiL2CollectionAddress()).withdrawStartMany(
    ownedNfts.map((ownedNft) => ownedNft.tokenId),
    amounts,
  );
  await result.getTransactionHash();
  await result.getReceipt();
}

