import { defineComponent } from 'vue';
import GachaponCard from '@/components/Gachapon/GachaponCard.vue';
import GachaponConfigurationDrawer from './GachaponConfigurationDrawer/index.vue';
import StandardGachaponDeploymentDrawer from './StandardGachaponDeploymentDrawer.vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
export default defineComponent({
  name: 'GachaFactoryTab',
  components: {
    GachaponCard,
    GachaponConfigurationDrawer,
    StandardGachaponDeploymentDrawer,
    ShadowedButton
  },
  data() {
    return {
      isStandardDeploymentDrawerOpen: false,
      isGachaponConfigurationDrawerOpen: false,
      selectedGachaponAddress: ''
    };
  },
  props: {
    gachapons: {
      type: Array,
      default: () => []
    },
    gachaponManagers: {
      type: Object,
      default: null
    },
    gachaponCategories: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    gachaponPageUrl() {
      if (!this.selectedGachapon) {
        return '';
      }
      const routeUrl = this.$router.resolve({
        path: `/gachapon/${this.selectedGachapon.address}`
      });
      return routeUrl.href;
    },
    selectedGachapon() {
      if (!this.selectedGachaponAddress || !this.gachaponManagers[this.selectedGachaponAddress]) {
        return null;
      }
      return this.gachaponManagers[this.selectedGachaponAddress].gachapon;
    }
  },
  methods: {
    handleConfigure(gachaponAddress) {
      if (!gachaponAddress) {
        return;
      }
      this.isStandardDeploymentDrawerOpen = false;
      this.selectedGachaponAddress = gachaponAddress;
    },
    handleDeployDrawerClose(done) {
      this.isStandardDeploymentDrawerOpen = false;
      done();
    },
    handleConfigurationDrawerClose(done) {
      this.selectedGachaponAddress = '';
      done();
    }
  }
});