import { defineComponent } from 'vue';
import { getL1BlockchainId, getL1BlockchainIdHex } from '@/common/utils/blockchains';
import TransferNFTToPolygonDialog from '@/components/Dialogs/TransferNFTToPolygonDialog.vue';
import TransferNFTsToPolygonDialog from '@/components/Dialogs/TransferNFTsToPolygonDialog.vue';
import SwitchNetworkToEthereumDialog from '@/components/Dialogs/SwitchNetworkToEthereumDialog.vue';
import TransferNFTDialog from '@/components/Dialogs/TransferNFTDialog.vue';
import OwnedNFTsList from './OwnedNFTsList.vue';
import { ElMessage } from 'element-plus';
import { connectedWallet, connectedChain } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'EthereumTab',
  components: {
    OwnedNFTsList,
    TransferNFTToPolygonDialog,
    TransferNFTDialog,
    SwitchNetworkToEthereumDialog,
    TransferNFTsToPolygonDialog
  },
  data() {
    return {
      connectedWallet,
      connectedChain,
      forceRefreshList: false,
      isSwitchNetworkDialogVisible: false,
      isNFTDepositDialogVisible: false,
      isNFTsDepositDialogVisible: false,
      isNFTTransferDialogVisible: false,
      selectedNft: null,
      selectedNfts: new Array()
    };
  },
  computed: {
    L1BlockchainId() {
      return getL1BlockchainId();
    }
  },
  methods: {
    setSelectedNfts(selectedNfts) {
      this.selectedNfts = selectedNfts;
    },
    closeNFTsDepositDialog() {
      this.isNFTsDepositDialogVisible = false;
    },
    openNFTsDepositDialog() {
      if (this.connectedChain?.id !== getL1BlockchainIdHex()) {
        this.openSwitchNetworkDialog();
        return;
      }
      if (this.selectedNfts.length === 0) {
        ElMessage({
          message: 'You need to select one or multiple NFTs first.',
          grouping: true,
          type: 'error'
        });
        return;
      }
      this.isNFTsDepositDialogVisible = true;
    },
    closeNFTDepositDialog() {
      this.selectedNft = null;
      this.isNFTDepositDialogVisible = false;
    },
    openNFTDepositDialog(selectedNft) {
      if (this.connectedChain?.id !== getL1BlockchainIdHex()) {
        this.openSwitchNetworkDialog();
        return;
      }
      this.selectedNft = selectedNft;
      this.isNFTDepositDialogVisible = true;
    },
    closeNFTTransferDialog() {
      this.selectedNft = null;
      this.isNFTTransferDialogVisible = false;
    },
    openNFTTransferDialog(selectedNft) {
      if (this.connectedChain?.id !== getL1BlockchainIdHex()) {
        this.openSwitchNetworkDialog();
        return;
      }
      this.selectedNft = selectedNft;
      this.isNFTTransferDialogVisible = true;
    },
    closeSwitchNetworkDialog() {
      this.isSwitchNetworkDialogVisible = false;
    },
    openSwitchNetworkDialog() {
      this.isSwitchNetworkDialogVisible = true;
    }
  }
});