import { defineComponent } from 'vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import BaseDialog from '@/components/Dialogs/BaseDialog.vue';
import AzukiSeriouslySays from '@/components/Dialogs/AzukiSeriouslySays.vue';
export default defineComponent({
  name: 'StandardGachaponWarningDialog',
  components: {
    ShadowedButton,
    BaseDialog,
    AzukiSeriouslySays
  },
  data() {
    return {
      isChecked: false
    };
  },
  props: {
    gachapon: {
      type: Object
    },
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    closeDialog: {
      type: Function
    }
  },
  mounted() {
    if (localStorage.hideStandardGachaponWarning) {
      this.isChecked = localStorage.hideStandardGachaponWarning === 'true' || localStorage.hideStandardGachaponWarning === true;
    }
  },
  watch: {
    isChecked: function () {
      localStorage.hideStandardGachaponWarning = this.isChecked;
    }
  }
});