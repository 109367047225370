import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3bd34b77"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "gachapon-container"
};
const _hoisted_2 = {
  ref: "play",
  src: "@/app/assets/gachapon-machine/audio/crank.mp3"
};
const _hoisted_3 = {
  class: "gachapon"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "gachapon"
};
const _hoisted_7 = ["src"];
const _hoisted_8 = ["src"];
const _hoisted_9 = {
  class: "gachapon"
};
const _hoisted_10 = ["src"];
const _hoisted_11 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("audio", _hoisted_2, null, 512), _withDirectives(_createElementVNode("div", _hoisted_3, [!_ctx.displayDefaultInitialState ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: _ctx.initialStateImage,
    onError: _cache[0] || (_cache[0] = $event => _ctx.displayDefaultInitialState = true)
  }, null, 40, _hoisted_4)) : (_openBlock(), _createElementBlock("img", {
    key: 1,
    src: _ctx.defaultInitialStateImage
  }, null, 8, _hoisted_5))], 512), [[_vShow, !_ctx.isAnimated]]), _withDirectives(_createElementVNode("div", _hoisted_6, [!_ctx.displayDefaultPendingState ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: _ctx.pendingStateImage,
    onError: _cache[1] || (_cache[1] = $event => _ctx.displayDefaultPendingState = true)
  }, null, 40, _hoisted_7)) : (_openBlock(), _createElementBlock("img", {
    key: 1,
    src: _ctx.defaultPendingStateImage
  }, null, 8, _hoisted_8))], 512), [[_vShow, _ctx.isAnimated && !_ctx.playEndingAnimation]]), _withDirectives(_createElementVNode("div", _hoisted_9, [!_ctx.displayDefaultEndState ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: _ctx.endStateImage,
    onError: _cache[2] || (_cache[2] = $event => _ctx.displayDefaultEndState = true)
  }, null, 40, _hoisted_10)) : (_openBlock(), _createElementBlock("img", {
    key: 1,
    src: _ctx.defaultEndStateImage
  }, null, 8, _hoisted_11))], 512), [[_vShow, _ctx.isAnimated && _ctx.playEndingAnimation]])]);
}