import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a0439dd8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "refresh"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElIconRefresh = _resolveComponent("ElIconRefresh");
  const _component_el_icon = _resolveComponent("el-icon");
  return _openBlock(), _createElementBlock("span", _hoisted_1, [_createVNode(_component_el_icon, {
    class: "icon"
  }, {
    default: _withCtx(() => [_createVNode(_component_ElIconRefresh)]),
    _: 1
  })]);
}