import { defineComponent } from 'vue';
import SwitchNetworkToPolygonDialog from '@/components/Dialogs/SwitchNetworkToPolygonDialog.vue';
import StakingPool from './StakingPool.vue';
import { getL2BlockchainIdHex } from '@/common/utils/blockchains';
import { connectedChain } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'PolygonTab',
  components: {
    StakingPool,
    SwitchNetworkToPolygonDialog
  },
  data() {
    return {
      connectedChain,
      isSwitchNetworkDialogVisible: false
    };
  },
  props: {
    stakingPools: {
      type: Array,
      default: () => []
    },
    walletBalances: {
      type: Array,
      default: () => []
    },
    walletApprovedAmounts: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isWalletConnectedToPolygon() {
      return this.connectedChain?.id === getL2BlockchainIdHex();
    }
  },
  methods: {
    closeSwitchNetworkDialog() {
      this.isSwitchNetworkDialogVisible = false;
    },
    openSwitchNetworkDialog() {
      this.isSwitchNetworkDialogVisible = true;
    }
  }
});