import GachaponsGlobalPlayStats from '@/common/types/GachaponsGlobalPlayStats';
import {getAllLegacyGachapons} from '@/common/api/gachapons/legacy-gachapons';

export async function getLegacyGachaponsGlobalPlayStats(): Promise<GachaponsGlobalPlayStats> {
  const globalPlayStats: GachaponsGlobalPlayStats = {
    createdGachaponsAmount: 0,
    distributedNftsAmount: 0,
    uniquePlayersAmount: 3300
  };

  const gachapons = await getAllLegacyGachapons();
  if (gachapons.length === 0) {
    return globalPlayStats;
  }

  globalPlayStats.createdGachaponsAmount = gachapons.length;
  for (const gachapon of gachapons) {
    globalPlayStats.distributedNftsAmount += gachapon.timesPlayed;
  }

  return globalPlayStats;
}
