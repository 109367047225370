import httpClient from '@/common/utils/http-client';
import Gachapon from '@/common/types/Gachapon';
import AllowlistSpot from '@/common/types/AllowlistSpot';
import WalletAllowlistSpot from '@/common/types/WalletAllowlistSpot';
import { WalletAllowlistSpotsData } from '@/common/api/gachapons/types';

const API_URL = process.env.NODE_ENV === 'production' ? 'https://nft-api.dokidoki.com' : 'https://nft-api-dev.dokidoki.com';

function buildAllowlistSpotsParameters(allowlistSpots: AllowlistSpot[]): string {
  let queryParameters = '';
  for (let i = 0; allowlistSpots[i]; i++) {
    queryParameters += '{';
    queryParameters += `walletAddress: "${allowlistSpots[i]['walletAddress']}",`;
    queryParameters += `playAmountAllowed: "${allowlistSpots[i]['playAmountAllowed'].toString()}",`;
    queryParameters += `price: "${allowlistSpots[i]['price'].toString()}"`;
    queryParameters += '},';
  }

  return queryParameters;
}

export async function updateGachaponAllowlist(walletSignature: string, gachapon: Gachapon, allowlistSpots: AllowlistSpot[]): Promise<string> {
  const response = await httpClient.post(`${API_URL}`, {
    query: `
      mutation {
        updateGachaponAllowlist(
          signature: "${walletSignature}"
          gachaponAddress: "${gachapon.address}"
          chainId: ${gachapon.blockchainId}
          allowlistSpots: [${buildAllowlistSpotsParameters(allowlistSpots)}]
        )
      }`
    }
  );

  return response.data.updateGachaponAllowlist;
}

export async function getWalletAllowlistSpots(gachapon: Gachapon, walletAddress: string): Promise<WalletAllowlistSpot[]> {
  const response = await httpClient.post(`${API_URL}`, {
    query: `{
      walletGachaponAllowlistSpots(gachaponAddress: "${gachapon.address}", walletAddress: "${walletAddress}", chainId: ${gachapon.blockchainId}) {
        gachaponAddress,
        chainId,
        merkleProof,
        walletAddress,
        playAmountAllowed,
        playedAmount,
        price
      }
    }`
  });

  const walletAllowlistSpots: WalletAllowlistSpot[] = [];
  if (response.data) {
    const walletAllowlistSpotsData = response.data as WalletAllowlistSpotsData;
    for (const walletAllowlistSpotData of walletAllowlistSpotsData.walletGachaponAllowlistSpots) {
      const walletAllowlistSpot: WalletAllowlistSpot = {
        gachaponAddress: walletAllowlistSpotData.gachaponAddress,
        walletAddress: walletAllowlistSpotData.walletAddress,
        merkleProof: walletAllowlistSpotData.merkleProof,
        playAmountAllowed: BigInt(walletAllowlistSpotData.playAmountAllowed),
        playedAmount: BigInt(walletAllowlistSpotData.playedAmount),
        price: BigInt(walletAllowlistSpotData.price)
      };

      walletAllowlistSpots.push(walletAllowlistSpot);
    }
  }

  return walletAllowlistSpots;
}
