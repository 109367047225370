import { defineComponent } from 'vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
export default defineComponent({
  name: 'FeaturesSection',
  components: {
    ShadowedButton
  },
  data() {
    return {
      clickedStats: [false, false, false, false, false, false, false, false]
    };
  },
  methods: {
    handleClick(index) {
      if (this.clickedStats[index] === undefined) {
        return;
      }
      const clickedStats = [...this.clickedStats];
      clickedStats[index] = !clickedStats[index];
      this.clickedStats = [...clickedStats];
    }
  }
});