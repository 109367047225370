import "core-js/modules/es.array.push.js";
import { Loading as ElIconLoading } from '@element-plus/icons-vue';
import { defineComponent } from 'vue';
import SwitchNetworkToPolygonDialog from '@/components/Dialogs/SwitchNetworkToPolygonDialog.vue';
import { RoundStatus } from '@/common/api/rounds/types';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import { getVRFPlayableGachaponByAddress } from '@/common/contracts/gachapons';
import { getL2BlockchainId, getL2BlockchainIdHex, getL2BlockchainExplorerTxLink } from '@/common/utils/blockchains';
import { getNftsByTokenIdsAndCollectionAddresses } from '@/common/api/nfts';
import { connectedWallet, connectedChain, getWalletSigner } from '@/common/utils/web3-onboard';
import { VrfGachapon__factory } from '@/contracts';
import { ElMessage } from 'element-plus';
export default defineComponent({
  name: 'Round',
  components: {
    SwitchNetworkToPolygonDialog,
    ShadowedButton,
    ElIconLoading
  },
  data() {
    return {
      connectedWallet,
      connectedChain,
      nfts: [],
      rounds: [],
      isLoading: false,
      isSwitchNetworkDialogVisible: false,
      isClaiming: false,
      isDeployed: false
    };
  },
  props: {
    round: {
      type: Object,
      default: null
    }
  },
  computed: {
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    },
    rollTransactionLink() {
      return `${getL2BlockchainExplorerTxLink()}/${this.round?.rollTransactionHash}`;
    },
    claimTransactionLink() {
      return `${getL2BlockchainExplorerTxLink()}/${this.round?.claimTransactionHash}`;
    },
    gachaponLink() {
      const link = this.$router.resolve({
        name: 'gachapon',
        params: {
          address: this.round.gachaponAddress
        }
      });
      return link.href;
    },
    isClaimable() {
      return this.round.status === RoundStatus.completed;
    },
    isClaimed() {
      return this.round.status === RoundStatus.claimed;
    },
    datePlayed() {
      return new Intl.DateTimeFormat(undefined, {
        dateStyle: 'short',
        timeStyle: 'short'
      }).format(this.round.createdAt);
    }
  },
  methods: {
    async claimPrizes() {
      if (this.connectedChain?.id !== getL2BlockchainIdHex()) {
        this.openSwitchNetworkDialog();
        return;
      }
      const playableGachapon = await getVRFPlayableGachaponByAddress(this.round.gachaponAddress);
      if (!playableGachapon || this.round.status !== RoundStatus.completed || !this.connectedWallet) {
        return;
      }
      this.isClaiming = true;
      try {
        const walletSigner = await getWalletSigner(this.connectedWallet);
        const vrfGachaponContract = VrfGachapon__factory.connect(playableGachapon.gachapon.address, walletSigner);
        const transaction = await vrfGachaponContract.claimPrizes(this.round.id, {
          from: this.walletAddress,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
          gasPrice: null
        });
        await transaction.wait();
        this.round.status = RoundStatus.claimed;
        this.round.claimTransactionHash = transaction.hash || '';
      } catch (e) {
        if (e instanceof Error) {
          console.error(e.message);
          ElMessage({
            message: e.message,
            grouping: true,
            type: 'error'
          });
        }
      }
      this.isClaiming = false;
    },
    closeSwitchNetworkDialog() {
      this.isSwitchNetworkDialogVisible = false;
    },
    openSwitchNetworkDialog() {
      this.isSwitchNetworkDialogVisible = true;
    },
    async fetchNfts() {
      if (!this.round.prizes) {
        return;
      }
      const tokenIds = [];
      const collectionIds = [];
      this.round.prizes.map(prize => {
        const [collection, tokenId] = prize.split('_');
        collectionIds.push(collection);
        tokenIds.push(tokenId);
        return {
          collection,
          tokenId
        };
      });
      this.nfts = await getNftsByTokenIdsAndCollectionAddresses(tokenIds, collectionIds, getL2BlockchainId());
    }
  },
  async created() {
    await this.fetchNfts();
  }
});