import { defineComponent } from 'vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import BaseDialog from './BaseDialog.vue';
import { retrievePendingNft } from '@/common/contracts/nfts/bridge-nfts';
import { ElMessage } from 'element-plus';
import { connectedWallet } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'RetrieveNFTDialog',
  components: {
    ShadowedButton,
    BaseDialog
  },
  data() {
    return {
      connectedWallet,
      loading: false
    };
  },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    closeDialog: {
      type: Function,
      default: () => ({})
    },
    selectNft: {
      type: Object
    }
  },
  computed: {
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    }
  },
  methods: {
    async handleRetrieve() {
      if (!this.connectedWallet || this.loading || !this.selectNft) {
        return;
      }
      this.loading = true;
      try {
        await retrievePendingNft(this.connectedWallet, this.walletAddress, this.selectNft);
        this.$emit('nft-retrieved');
        ElMessage({
          message: 'NFT retrieved!',
          grouping: true,
          type: 'success'
        });
        this.closeDialog();
      } catch (e) {
        ElMessage({
          message: `Looks like there was an error retrieving your NFT: ${e.message}`,
          grouping: true,
          type: 'error'
        });
      }
      this.loading = false;
    }
  }
});