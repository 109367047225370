import "core-js/modules/es.array.push.js";
import { defineComponent } from 'vue';
import PolygonTab from './PolygonTab.vue';
import EthereumTab from './EthereumTab.vue';
import { getL1Pools, getL2Pools } from '@/common/contracts/staking-pools';
import { getL1BlockchainIdHex, getL2BlockchainIdHex } from '@/common/utils/blockchains';
import { connectedWallet, connectedChain, getWalletSigner } from '@/common/utils/web3-onboard';
import erc20Contract from '@/common/contracts/erc20';
export default defineComponent({
  name: 'Staking',
  components: {
    EthereumTab,
    PolygonTab
  },
  data() {
    return {
      connectedWallet,
      connectedChain,
      timer: 0,
      L1Pools: getL1Pools(),
      L2Pools: getL2Pools(),
      L1WalletBalances: [],
      L1WalletApprovedAmounts: [],
      L2WalletBalances: [],
      L2WalletApprovedAmounts: []
    };
  },
  computed: {
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    }
  },
  methods: {
    async refreshAllPools() {
      await this.refreshAllL1();
      await this.refreshAllL2();
    },
    async refreshStakingPoolsData(stakingPools) {
      const promises = [];
      for (const stakingPool of stakingPools) {
        promises.push(stakingPool.loadPoolData());
      }
      await Promise.allSettled(promises);
    },
    async refreshStakingPoolsUserData(stakingPools) {
      if (!this.connectedWallet) {
        return;
      }
      const promises = [];
      for (const stakingPool of stakingPools) {
        promises.push(stakingPool.loadUserData(this.walletAddress));
      }
      await Promise.allSettled(promises);
    },
    async refreshWalletL1Data() {
      if (!this.connectedWallet) {
        return;
      }
      if (this.connectedChain?.id === getL1BlockchainIdHex()) {
        this.L1WalletBalances = await this.getWalletBalances(this.L1Pools);
        this.L1WalletApprovedAmounts = await this.getWalletApprovedAmounts(this.L1Pools);
      }
    },
    async refreshWalletL2Data() {
      if (!this.connectedWallet) {
        return;
      }
      if (this.connectedChain?.id === getL2BlockchainIdHex()) {
        this.L2WalletBalances = await this.getWalletBalances(this.L2Pools);
        this.L2WalletApprovedAmounts = await this.getWalletApprovedAmounts(this.L2Pools);
      }
    },
    async refreshAllL1() {
      await this.refreshStakingPoolsData(this.L1Pools);
      await this.refreshStakingPoolsUserData(this.L1Pools);
      await this.refreshWalletL1Data();
    },
    async refreshAllL2() {
      await this.refreshStakingPoolsData(this.L2Pools);
      await this.refreshStakingPoolsUserData(this.L2Pools);
      await this.refreshWalletL2Data();
    },
    async getWalletBalances(stakingPools) {
      const walletBalances = [];
      if (!this.connectedWallet) {
        return walletBalances;
      }
      const walletSigner = await getWalletSigner(this.connectedWallet);
      const balancesPromises = [];
      for (const stakingPool of stakingPools) {
        balancesPromises.push(erc20Contract.balanceOf(walletSigner, this.walletAddress, stakingPool.metadata.stakingToken.address));
      }
      const balancesPromisesResults = await Promise.allSettled(balancesPromises);
      for (const balancesPromisesResult of balancesPromisesResults) {
        const value = balancesPromisesResult.status === 'fulfilled' ? balancesPromisesResult.value : 0n;
        walletBalances.push(value);
      }
      return walletBalances;
    },
    async getWalletApprovedAmounts(stakingPools) {
      const walletApprovedAmounts = [];
      if (!this.connectedWallet) {
        return walletApprovedAmounts;
      }
      const walletSigner = await getWalletSigner(this.connectedWallet);
      const approvedAmountPromises = [];
      for (const stakingPool of stakingPools) {
        approvedAmountPromises.push(erc20Contract.allowance(walletSigner, this.walletAddress, stakingPool.metadata.address, stakingPool.metadata.stakingToken.address));
      }
      const approvedAmountPromisesResults = await Promise.allSettled(approvedAmountPromises);
      for (const approvedAmountPromisesResult of approvedAmountPromisesResults) {
        const value = approvedAmountPromisesResult.status === 'fulfilled' ? approvedAmountPromisesResult.value : 0n;
        walletApprovedAmounts.push(value);
      }
      return walletApprovedAmounts;
    }
  },
  async mounted() {
    await this.refreshAllPools();
    this.timer = window.setInterval(async () => {
      await this.refreshAllPools();
    }, 30000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  watch: {
    connectedWallet: async function () {
      await this.refreshAllPools();
    },
    connectedChain: async function () {
      await this.refreshAllPools();
    }
  }
});