import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "paste-input-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_ShadowedButton = _resolveComponent("ShadowedButton");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_input, {
    class: "input",
    type: "text",
    modelValue: _ctx.localValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.localValue = $event),
    placeholder: _ctx.placeholder,
    onChange: _ctx.handleChange
  }, null, 8, ["modelValue", "placeholder", "onChange"]), _createVNode(_component_ShadowedButton, {
    shadowSize: "xs",
    theme: "violet-white",
    onClick: _ctx.handlePaste
  }, {
    default: _withCtx(() => [_createTextVNode(" PASTE ")]),
    _: 1
  }, 8, ["onClick"])]);
}