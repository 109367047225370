import { defineComponent } from 'vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import DemoGachapon from './DemoGachapon/index.vue';
import { getLegacyGachaponsGlobalPlayStats, getVRFGachaponsGlobalPlayStats } from '@/common/api/gachapons';
export default defineComponent({
  name: 'GachaponSection',
  components: {
    ShadowedButton,
    DemoGachapon
  },
  data() {
    return {
      gachaponsGlobalPlayStats: null
    };
  },
  computed: {},
  methods: {
    async fetchGachaponsGlobalPlayStats() {
      const legacyGachaponsGlobalPlayStats = await getLegacyGachaponsGlobalPlayStats();
      const VRFGachaponsGlobalPlayStats = await getVRFGachaponsGlobalPlayStats();
      this.gachaponsGlobalPlayStats = {
        createdGachaponsAmount: legacyGachaponsGlobalPlayStats.createdGachaponsAmount + VRFGachaponsGlobalPlayStats.createdGachaponsAmount,
        distributedNftsAmount: legacyGachaponsGlobalPlayStats.distributedNftsAmount + VRFGachaponsGlobalPlayStats.distributedNftsAmount,
        uniquePlayersAmount: legacyGachaponsGlobalPlayStats.uniquePlayersAmount + VRFGachaponsGlobalPlayStats.uniquePlayersAmount
      };
    }
  },
  async created() {
    await this.fetchGachaponsGlobalPlayStats();
  }
});