import "core-js/modules/es.array.push.js";
import { defineComponent } from 'vue';
import RefreshButton from '@/components/GachaButton/RefreshButton.vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import OwnedNFTCard from '@/components/NFT/OwnedNFTCard.vue';
import { getL2OwnedNftsByOwnerAddress, getL1OwnedNftsByOwnerAddress } from '@/common/api/nfts';
import { getUserProfilesIndexedByAddress } from '@/common/api/users';
import { getL1BlockchainId, getL2BlockchainId } from '@/common/utils/blockchains';
import { ElMessage } from 'element-plus';
import { connectedWallet } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'OwnedNFTsList',
  components: {
    OwnedNFTCard,
    RefreshButton,
    ShadowedButton
  },
  data() {
    return {
      connectedWallet,
      currentPage: 0,
      ownedNfts: new Array(),
      artists: {},
      isLoading: false,
      hasLoadedAll: false
    };
  },
  props: {
    forceRefreshList: {
      type: Boolean,
      default: false
    },
    selectedNfts: {
      type: Array,
      default: () => []
    },
    pageSize: {
      type: Number,
      default: 50
    },
    blockchainId: {
      type: Number
    }
  },
  computed: {
    selectAllText() {
      return this.selectedNfts.length > 0 ? 'UNSELECT ALL' : 'SELECT ALL';
    },
    L1BlockchainId() {
      return getL1BlockchainId();
    },
    L2BlockchainId() {
      return getL2BlockchainId();
    },
    batchOperationName() {
      if (this.blockchainId === getL2BlockchainId()) {
        return 'WITHDRAW';
      }
      return 'DEPOSIT';
    },
    hasViewportReachedBottomOfPage() {
      return this.$store.state.viewport.hasReachedBottomOfPage;
    },
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    }
  },
  methods: {
    async refreshList() {
      this.ownedNfts = [];
      this.currentPage = 0;
      this.isLoading = false;
      this.hasLoadedAll = false;
      await this.fetchNfts();
    },
    async fetchNfts() {
      if (!this.connectedWallet || this.isLoading || this.hasLoadedAll) {
        return;
      }
      this.isLoading = true;
      setTimeout(async () => {
        try {
          const ownedNfts = this.blockchainId === getL2BlockchainId() ? await getL2OwnedNftsByOwnerAddress(this.walletAddress, 'lastReceived', 'desc', this.currentPage * this.pageSize, this.pageSize) : await getL1OwnedNftsByOwnerAddress(this.walletAddress, 'lastReceived', 'desc', this.currentPage * this.pageSize, this.pageSize);
          if (ownedNfts.length < this.pageSize) {
            this.hasLoadedAll = true;
          } else {
            this.currentPage++;
          }
          this.ownedNfts = [...this.ownedNfts, ...ownedNfts];
        } catch (e) {
          this.hasLoadedAll = true;
          ElMessage({
            message: 'We couldn\'t retrieve your NFTs.',
            grouping: true,
            type: 'error'
          });
        }
        this.isLoading = false;
      }, 100);
    },
    async fetchArtists() {
      if (!this.ownedNfts.length) {
        return;
      }
      const artists = await getUserProfilesIndexedByAddress(this.ownedNfts.map(ownedNft => ownedNft.artistAddress));
      this.artists = {
        ...this.artists,
        ...artists
      };
    },
    /**
     * nft item check change
     */
    toggleNftsSelection(selectedNft) {
      const selectedNftIndex = this.findSelectedNftIndex(selectedNft.tokenId);
      if (selectedNftIndex !== -1) {
        this.selectedNfts.splice(selectedNftIndex, 1);
        return;
      }
      this.selectedNfts.push(selectedNft);
    },
    isNftSelected(nft) {
      return this.findSelectedNftIndex(nft.tokenId) !== -1;
    },
    /**
     * select all nfts
     */
    toggleAll() {
      if (this.selectedNfts.length > 0) {
        this.$emit('nfts-selected', []);
        return;
      }
      this.$emit('nfts-selected', this.ownedNfts);
    },
    /**
     * Returns the index of the nft ID in the selectedNfts array, or -1
     */
    findSelectedNftIndex(nftIdToCheck) {
      return this.selectedNfts.findIndex(nft => nft.tokenId === nftIdToCheck);
    },
    getOpenseaCollectionAddress(nft) {
      if (nft.blockchainId === this.L2BlockchainId) {
        return `matic/${nft.collectionAddress}`;
      }
      return nft.collectionAddress;
    }
  },
  async created() {
    await this.fetchNfts();
  },
  watch: {
    walletAddress: async function () {
      if (!this.walletAddress) {
        return;
      }
      await this.refreshList();
    },
    forceRefreshList: async function () {
      if (!this.forceRefreshList) {
        return;
      }
      await this.refreshList();
      this.$emit('update:forceRefreshList', false);
    },
    ownedNfts: async function () {
      await this.fetchArtists();
    },
    hasViewportReachedBottomOfPage: async function () {
      if (this.hasLoadedAll) {
        return;
      }
      await this.fetchNfts();
    }
  }
});