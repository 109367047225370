import ERC20Token from '@/common/types/ERC20Token';

export enum RoundStatus {
  waiting = 'WaitingForOracleResponse',
  completed = 'Completed',
  claimed = 'Claimed',
  cancelled = 'Cancelled',
}

export interface RoundData {
  /** ID of the request from the VRF oracle */
  id: string;
  claimHash: string;
  paymentHash: string;
  /** Status of the round */
  status: RoundStatus
  /** Times played */
  timesPlayed: number;
  /** Prizes for this round in the format 'collectionAddress_tokenId' */
  prizes: string[];
  /** Timestamp as for when the round was played */
  createdAt: number;
  /** Amount paid for the round */
  paid: string;
  gachapon: {
    /** Address of the gachapon played */
    id: string;
    /** Title of the gachapon played */
    title: string;
    /** Currency used in the round */
    currency: {
      id: string;
      symbol: string;
      decimals: number;
      name: string;
      isSupported: boolean;
      isWrappedNativeToken: boolean;
    };
  };
}

export interface RoundsData {
  rounds: RoundData[];
}

export interface PlayedRound {
  id: string;
  claimTransactionHash: string;
  rollTransactionHash: string;
  status: RoundStatus;
  timesPlayed: number;
  prizes: string[];
  createdAt: Date;
  paid: bigint;
  currency: ERC20Token;
  gachaponAddress: string;
  gachaponTitle: string;
}
