import { defineComponent } from 'vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import { connectWallet } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'WalletSignIn',
  components: {
    ShadowedButton
  },
  methods: {
    connectWallet
  }
});