import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4e41b44b"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "logo"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Currency = _resolveComponent("Currency");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tokens, (token, index) => {
    return _openBlock(), _createBlock(_component_Currency, {
      key: index,
      name: token
    }, null, 8, ["name"]);
  }), 128))]);
}