import "core-js/modules/es.array.push.js";
import { defineComponent } from 'vue';
import csvParser from 'papaparse';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import CSVGeneralGuideDialog from './CSVGeneralGuideDialog.vue';
import ErrorsDetailDialog from './ErrorsDetailDialog.vue';
import WarningsDetailDialog from './WarningsDetailDialog.vue';
import ApplyCsvDialog from './ApplyCsvDialog.vue';
import SwitchNetworkToPolygonDialog from '@/components/Dialogs/SwitchNetworkToPolygonDialog.vue';
import AllowlistRestrictionDialog from './AllowlistRestrictionDialog.vue';
import { remapCsvAllowlistSpotToAllowlistSpot } from '@/common/utils/csv/allowlist';
import { stringToWei } from '@/common/utils/format-numbers';
import { getL2BlockchainIdHex } from '@/common/utils/blockchains';
import { ElMessage } from 'element-plus';
import { connectedChain } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'AllowlistTab',
  components: {
    ShadowedButton,
    CSVGeneralGuideDialog,
    ErrorsDetailDialog,
    WarningsDetailDialog,
    AllowlistRestrictionDialog,
    SwitchNetworkToPolygonDialog,
    ApplyCsvDialog
  },
  data() {
    return {
      connectedChain,
      isCsvGeneralGuideDialogVisible: false,
      isErrorsDetailDialogVisible: false,
      isWarningsDetailDialogVisible: false,
      isAllowlistRestrictionDialogVisible: false,
      isApplyCsvDialogVisible: false,
      isSwitchToPolygonDialogVisible: false,
      file: null,
      fileData: [],
      allowlistSpots: [],
      addressesRecord: {},
      errors: {},
      warnings: [],
      isAllowlistOnly: this.gachaponManager?.gachapon.isAllowlistOnly || false
    };
  },
  props: {
    gachaponManager: {
      type: Object,
      default: null
    }
  },
  computed: {
    hasWarnings() {
      return this.warnings.length > 0;
    },
    hasErrors() {
      return Object.keys(this.errors)?.length > 0;
    },
    mustSwitchBlockchain() {
      return getL2BlockchainIdHex() !== this.connectedChain?.id;
    }
  },
  methods: {
    onExceed(files) {
      if (!files[0]) {
        return;
      }
      const sizeLimit = files[0].size / 1024 / 1024 <= 20;
      if (!sizeLimit) {
        ElMessage({
          message: 'File should be less than 20MB!',
          grouping: true,
          type: 'error'
        });
        return;
      }
      this.file = files[0];
    },
    onFileUploadChange(file) {
      if (!file.raw) {
        return;
      }
      const sizeLimit = file.size / 1024 / 1024 < 20;
      if (!sizeLimit) {
        ElMessage({
          message: 'File should be less than 50MB!',
          grouping: true,
          type: 'error'
        });
        return;
      }
      this.file = file.raw;
    },
    async onApplyCsv() {
      if (!this.allowlistSpots.length) {
        return;
      }
      if (this.mustSwitchBlockchain) {
        this.isSwitchToPolygonDialogVisible = true;
        return;
      }
      this.isApplyCsvDialogVisible = true;
    },
    async onAllowlistOnlyChange(value) {
      if (this.mustSwitchBlockchain) {
        this.isSwitchToPolygonDialogVisible = true;
        return;
      }
      this.isAllowlistOnly = value;
      this.isAllowlistRestrictionDialogVisible = true;
    },
    hasCsvFileGlobalErrors(fileData) {
      if (!fileData.length) {
        this.errors[0] = 'The CSV file does not seem to contain any data.';
        return true;
      }
      if (fileData.length > 100000) {
        this.errors[0] = 'The CSV file must contain less than 100 000 entries.';
        return true;
      }
      if (!Object.keys(fileData[0]).some(key => key === 'address') || !Object.keys(fileData[0]).some(key => key === 'playcount') || !Object.keys(fileData[0]).some(key => key === 'price')) {
        this.errors[1] = 'The CSV file is missing column headers.';
        return true;
      }
      return false;
    }
  },
  watch: {
    file: function () {
      if (!this.file) {
        return;
      }
      csvParser.parse(this.file, {
        header: true,
        skipEmptyLines: true,
        complete: results => {
          if (results.errors.length) {
            ElMessage({
              message: 'Something went wrong with this file!',
              grouping: true,
              type: 'error'
            });
            for (let i = 0; results.errors[i]; i++) {
              this.errors[i] = results.errors[i].message;
            }
            return;
          }
          this.fileData = results.data;
        }
      });
    },
    fileData: function (fileData) {
      this.allowlistSpots = [];
      this.errors = {};
      this.warnings = [];
      this.addressesRecord = {};
      if (this.hasCsvFileGlobalErrors(fileData)) {
        return;
      }
      if (this.gachaponManager.gachapon.price.toString() === '0') {
        this.warnings.push('GACHAPON PRICE IS CURRENTLY SET TO 0. If applied now, allowlisted users without a specified price column will be able to play this GACHAPON for free even if you change the price later. If this is not your intention, please set the desired GACHAPON price first in general settings.');
      }
      for (let i = 0; fileData[i]; i++) {
        if (this.addressesRecord[fileData[i].address]) {
          this.warnings.push(`Duplicate wallet address line ${i + 1}`);
          this.addressesRecord[fileData[i].address]++;
        } else {
          this.addressesRecord[fileData[i].address] = 1;
        }
        if (this.addressesRecord[fileData[i].address] > 100) {
          this.errors[i + 2] = 'A given wallet address can\'t have more than 100 entries.';
        }
        if (fileData[i].playcount === '0') {
          this.errors[i + 2] = 'Allowlist playcount must be greater than 0.';
        }
        try {
          if (fileData[i].price === '') {
            fileData[i].price = this.gachaponManager.gachapon.price.toString();
          } else {
            fileData[i].price = stringToWei(fileData[i].price, this.gachaponManager.gachapon.currency.decimals);
          }
          this.allowlistSpots.push(remapCsvAllowlistSpotToAllowlistSpot(fileData[i]));
        } catch (e) {
          if (e instanceof Error) {
            this.errors[i + 2] = e.message;
          }
        }
      }
    }
  }
});