import { defineComponent } from 'vue';
export default defineComponent({
  name: 'BaseDialog',
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    width: {
      type: String
    },
    title: {
      type: String
    },
    onClose: {
      type: Function
    }
  }
});