export function truncate(text: string, length: number, clamp = '...'): string {
  if (!text) {
    return '';
  }

  return text.slice(0, length) + (length < text.length ? clamp || '...' : '');
}

export function truncateMiddle(text: string, length: number, clamp = '...'): string {
  if (!text || text.length / 2 < length) {
    return text;
  }

  const start = text.substring(0, length);
  const end = text.substring(text.length - length, text.length);
  return start + clamp + end;
}
