import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_DemoNFTItem = _resolveComponent("DemoNFTItem");
  const _component_InnerPanel = _resolveComponent("InnerPanel");
  const _component_OuterPanel = _resolveComponent("OuterPanel");
  return _openBlock(), _createBlock(_component_OuterPanel, {
    class: "demo-nfts-panel"
  }, {
    default: _withCtx(() => [_createVNode(_component_InnerPanel, {
      class: "nfts-list"
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nfts, (nft, index) => {
        return _openBlock(), _createBlock(_component_DemoNFTItem, {
          key: index,
          visualUrl: nft
        }, null, 8, ["visualUrl"]);
      }), 128))]),
      _: 1
    })]),
    _: 1
  });
}