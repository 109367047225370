import { defineComponent } from 'vue';
import SwitchNetworkToPolygonDialog from '@/components/Dialogs/SwitchNetworkToPolygonDialog.vue';
import BuyERC20TokenDialog from '@/components/Dialogs/BuyERC20TokenDialog.vue';
import ApproveGachaponDialog from '@/components/Dialogs/ApproveGachaponDialog.vue';
import LockedMachineDialog from '@/components/Dialogs/LockedGachaponDialog.vue';
import { connectedWallet, connectedChain } from '@/common/utils/web3-onboard';
export default defineComponent({
  components: {
    SwitchNetworkToPolygonDialog,
    BuyERC20TokenDialog,
    ApproveGachaponDialog,
    LockedMachineDialog
  },
  name: 'PlayState',
  data() {
    return {
      connectedWallet,
      connectedChain
    };
  },
  props: {
    isGachaponReady: {
      type: Boolean,
      default: false
    },
    showDialogIfNeeded: {
      type: Boolean,
      default: false
    },
    mustUseAllowlistSpot: {
      type: Boolean,
      default: false
    },
    playPrice: {
      type: BigInt,
      default: 0n
    },
    allowlistPlayPrice: {
      type: BigInt,
      default: 0n
    },
    spinsAmount: {
      type: Number,
      default: 0
    },
    approvedAmount: {
      type: BigInt,
      default: 0n
    },
    userBalance: {
      type: BigInt,
      default: 0n
    },
    gachapon: {
      type: Object
    },
    owner: {
      type: Object
    },
    walletAllowlistSpot: {
      type: Object,
      default: null
    }
  },
  computed: {
    isGachaponApproved() {
      return this.approvedAmount > this.playPrice;
    },
    state() {
      if (!this.connectedWallet) {
        return 'CONNECT WALLET';
      }
      if (this.gachapon?.isLocked) {
        return 'GACHAPON IS LOCKED';
      }
      if (this.connectedChain && parseInt(this.connectedChain.id, 16) != this.gachapon?.blockchainId) {
        return 'SWITCH NETWORKS';
      }
      if (!this.gachapon?.prizesAmountLeft) {
        return 'GACHAPON IS EMPTY';
      }
      if (this.playPrice > this.userBalance) {
        return 'INSUFFICIENT FUNDS';
      }
      if (this.gachapon?.isAllowlistOnly && !this.walletAllowlistSpot) {
        return 'NOT ENOUGH ALLOWLIST';
      }
      if (this.mustUseAllowlistSpot) {
        if (this.allowlistPlayPrice > 0 && !this.isGachaponApproved) {
          return 'APPROVE TO PLAY';
        }
        if (this.spinsAmount > this.walletAllowlistSpot?.playAmountAllowed - this.walletAllowlistSpot?.playedAmount) {
          return 'ROLLS EXCEEDS ALLOWLIST';
        }
      } else {
        if (this.playPrice > 0 && !this.isGachaponApproved) {
          return 'APPROVE TO PLAY';
        }
        if (this.gachapon.spinsAmountLeft < this.spinsAmount) {
          return 'INSUFFICIENT PLAY COUNT';
        }
      }
      return 'ROLL';
    }
  },
  mounted() {
    if (this.state !== 'ROLL') {
      this.$emit('update:isGachaponReady', false);
      return;
    }
    this.$emit('update:isGachaponReady', true);
  },
  watch: {
    state: function () {
      if (this.state !== 'ROLL') {
        this.$emit('update:isGachaponReady', false);
        return;
      }
      this.$emit('update:isGachaponReady', true);
    }
  }
});