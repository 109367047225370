import { Check as ElIconCheck } from '@element-plus/icons-vue';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'FilterSortDropdown',
  components: {
    ElIconCheck
  },
  props: {
    sortOrder: {
      type: String
    },
    filters: {
      type: Object
    }
  },
  methods: {
    handleSortOrder(sortOrder) {
      this.$emit('update:sortOrder', sortOrder);
    },
    handleSoldOut(newValue) {
      const soldOut = newValue ? undefined : false;
      this.$emit('update:filters', {
        ...this.filters,
        soldOut
      });
    },
    handleLocked(newValue) {
      const locked = newValue ? undefined : false;
      this.$emit('update:filters', {
        ...this.filters,
        locked
      });
    }
  }
});