import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BuyERC20TokenDialog = _resolveComponent("BuyERC20TokenDialog");
  const _component_SwitchNetworkToPolygonDialog = _resolveComponent("SwitchNetworkToPolygonDialog");
  const _component_ApproveGachaponDialog = _resolveComponent("ApproveGachaponDialog");
  const _component_LockedMachineDialog = _resolveComponent("LockedMachineDialog");
  return _openBlock(), _createElementBlock("div", null, [_createTextVNode(_toDisplayString(_ctx.state) + " ", 1), _createVNode(_component_BuyERC20TokenDialog, {
    isDialogVisible: _ctx.showDialogIfNeeded && _ctx.state === 'INSUFFICIENT FUNDS',
    closeDialog: () => _ctx.$emit('update:showDialogIfNeeded', false),
    tokenAddress: this.gachapon.currency.address,
    tokenSymbol: _ctx.gachapon.currency.symbol
  }, null, 8, ["isDialogVisible", "closeDialog", "tokenAddress", "tokenSymbol"]), _createVNode(_component_SwitchNetworkToPolygonDialog, {
    isDialogVisible: _ctx.showDialogIfNeeded && _ctx.state === 'SWITCH NETWORKS',
    closeDialog: () => _ctx.$emit('update:showDialogIfNeeded', false)
  }, null, 8, ["isDialogVisible", "closeDialog"]), _createVNode(_component_ApproveGachaponDialog, _mergeProps({
    machineAddress: _ctx.gachapon.address,
    isDialogVisible: _ctx.showDialogIfNeeded && _ctx.state === 'APPROVE TO PLAY',
    closeDialog: () => _ctx.$emit('update:showDialogIfNeeded', false),
    tokenAddress: this.gachapon.currency.address,
    playPrice: _ctx.playPrice
  }, _ctx.$attrs), null, 16, ["machineAddress", "isDialogVisible", "closeDialog", "tokenAddress", "playPrice"]), _createVNode(_component_LockedMachineDialog, {
    isDialogVisible: _ctx.showDialogIfNeeded && _ctx.state === 'GACHAPON IS LOCKED',
    closeDialog: () => _ctx.$emit('update:showDialogIfNeeded', false),
    owner: _ctx.owner
  }, null, 8, ["isDialogVisible", "closeDialog", "owner"])]);
}