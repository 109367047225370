import { defineComponent } from 'vue';
import { getGachaponColors } from '@/common/api/gachapons/customization';
import Carousel from '@/components/Carousel/index.vue';
import FeaturedNFTsPanel from './FeaturedNFTsPanel.vue';
import OwnerPanel from './OwnerPanel.vue';
import GachaponPanel from './GachaponPanel.vue';
import PrizesDialog from './PrizesDialog.vue';
import { getPlayableGachaponByAddress } from '@/common/contracts/gachapons';
import { ElMessage } from 'element-plus';
export default defineComponent({
  name: 'GachaponView',
  components: {
    Carousel,
    OwnerPanel,
    FeaturedNFTsPanel,
    GachaponPanel,
    PrizesDialog
  },
  data() {
    return {
      displayDefaultBackgroundImage: false,
      backgroundImageFolder: '/gachapons/machines/background',
      nfts: [],
      prizes: [],
      gachaponAddress: this.$route.params.address,
      playableGachapon: null,
      machineBackgroundStyle: {},
      colors: {
        buttonTextColor: '#9e93e2',
        buttonBackgroundColor: '#FFFFFF',
        mainColor: '#968ce1',
        buttonShadowColor: '#8555c2',
        buttonPressedBackgroundColor: '#9e93e2',
        buttonPressedTextColor: '#FFFFFF',
        textBoxColor: '#837bdc',
        textColor: '#ffffff'
      },
      windowWidth: window?.innerWidth > 0 ? window.innerWidth : 0
    };
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    async fetchPlayableGachapon(gachaponAddress) {
      this.playableGachapon = await getPlayableGachaponByAddress(gachaponAddress);
      if (!this.playableGachapon) {
        ElMessage({
          message: 'Gachapon not found.',
          grouping: true,
          type: 'error'
        });
      }
    },
    closePrizesDialog() {
      this.prizes = [];
    }
  },
  computed: {
    carouselType() {
      return this.windowWidth >= 850 && this.windowWidth < 1400 ? 'card' : '';
    },
    customStyle() {
      return {
        '--text-color': this.colors.textColor,
        '--panel-background-color': this.colors.mainColor,
        '--panel-transparent-background-color': `${this.colors.mainColor}E9`,
        '--box-background-color': this.colors.textBoxColor,
        '--shadow-color': this.colors.buttonShadowColor,
        '--button-text-color': this.colors.buttonTextColor,
        '--button-background-color': this.colors.buttonBackgroundColor,
        '--button-shadow-color': this.colors.buttonShadowColor,
        '--button-pressed-background-color': this.colors.buttonPressedBackgroundColor,
        '--button-pressed-text-color': this.colors.buttonPressedTextColor
      };
    },
    backgroundImage() {
      return `${this.backgroundImageFolder}/${this.gachaponAddress}.jpg`;
    },
    defaultBackgroundImage() {
      return `${this.backgroundImageFolder}/default.jpg`;
    }
  },
  async created() {
    await this.fetchPlayableGachapon(this.$route.params.address.toString());
    if (!this.playableGachapon) {
      return;
    }
    const newColors = getGachaponColors(this.playableGachapon.gachapon.address);
    this.colors = {
      buttonTextColor: newColors.buttonTextColor ? newColors.buttonTextColor : this.colors.buttonTextColor,
      buttonBackgroundColor: newColors.buttonBackgroundColor ? newColors.buttonBackgroundColor : this.colors.buttonBackgroundColor,
      mainColor: newColors.mainColor ? newColors.mainColor : this.colors.mainColor,
      buttonShadowColor: newColors.buttonShadowColor ? newColors.buttonShadowColor : this.colors.buttonShadowColor,
      buttonPressedBackgroundColor: newColors.buttonPressedBackgroundColor ? newColors.buttonPressedBackgroundColor : this.colors.buttonPressedBackgroundColor,
      buttonPressedTextColor: newColors.buttonPressedTextColor ? newColors.buttonPressedTextColor : this.colors.buttonPressedTextColor,
      textBoxColor: newColors.textBoxColor ? newColors.textBoxColor : this.colors.textBoxColor,
      textColor: newColors.textColor ? newColors.textColor : this.colors.textColor
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  watch: {
    prizes: async function () {
      if (!this.playableGachapon?.gachapon || this.prizes.length > 0) {
        return;
      }
      await this.fetchPlayableGachapon(this.$route.params.address.toString());
    }
  }
});