import { defineComponent } from 'vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import wait from '@/common/utils/wait';
import VRFPlayableGachapon from '@/common/contracts/gachapons/VRFPlayableGachapon';
import PrizeBall from './PrizeBall.vue';
import { getUserProfileByAddress } from '@/common/api/users';
import { connectedWallet, getWalletSigner } from '@/common/utils/web3-onboard';
import { VrfGachapon__factory } from '@/contracts';
const prizeOpenMusic = require('@/app/assets/gachapon-machine/audio/prize_open.mp3');
export default defineComponent({
  components: {
    ShadowedButton,
    PrizeBall
  },
  name: 'PrizesDialog',
  data() {
    return {
      connectedWallet,
      ownerName: '',
      openedPrizes: 0,
      openPrizes: false,
      mutePrizeBalls: false,
      isClaimed: false,
      isClaiming: false
    };
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function
    },
    nfts: {
      type: Array,
      default: () => []
    },
    playableGachapon: {
      type: Object
    }
  },
  computed: {
    nftNamesList() {
      if (!this.nfts) {
        return '';
      }
      const nftNamesSet = new Set();
      for (const nft of this.nfts) {
        if (nftNamesSet.has(nft.name)) {
          continue;
        }
        nftNamesSet.add(nft.name);
      }
      const nftNames = [...nftNamesSet];
      return nftNames.join(', ');
    },
    currentUrl() {
      return window.location?.origin ? window.location.origin + this.$route.fullPath : this.$route.fullPath;
    },
    claimButtonText() {
      return this.isClaimed ? 'CLAIMED!' : 'CLAIM PRIZES';
    },
    canOpenAllPrizes() {
      if (this.nfts.length - this.openedPrizes >= 1) {
        return true;
      }
      return false;
    },
    mustClaimPrizes() {
      return this.playableGachapon instanceof VRFPlayableGachapon;
    },
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    }
  },
  methods: {
    async fetchOwner(ownerAddress) {
      if (!ownerAddress) {
        return;
      }
      const user = await getUserProfileByAddress(ownerAddress);
      if (user?.username) {
        this.ownerName = user.username;
      }
    },
    async claimPrizes() {
      if (!(this.playableGachapon instanceof VRFPlayableGachapon) || this.isClaimed || this.isClaiming || !this.connectedWallet || !this.playableGachapon.requestId) {
        return;
      }
      this.isClaiming = true;
      try {
        const walletSigner = await getWalletSigner(this.connectedWallet);
        const vrfGachaponContract = VrfGachapon__factory.connect(this.playableGachapon.gachapon.address, walletSigner);
        const transaction = await vrfGachaponContract.claimPrizes(this.playableGachapon.requestId, {
          from: this.walletAddress,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
          gasPrice: null
        });
        await transaction.wait();
        this.isClaimed = true;
      } catch (e) {
        if (e instanceof Error) {
          console.error(e.message);
        }
      }
      this.isClaiming = false;
    },
    async openAllPrizes() {
      this.mutePrizeBalls = true;
      this.openPrizes = true;
      this.playMusic();
      await wait(1);
      this.mutePrizeBalls = false;
    }
  },
  async created() {
    if (this.playableGachapon?.gachapon.ownerAddress) {
      await this.fetchOwner(this.playableGachapon?.gachapon.ownerAddress);
    }
  },
  setup() {
    const music = new Audio(prizeOpenMusic);
    function playMusic() {
      music.currentTime = 0;
      music.play();
      music.volume = 0.2;
    }
    return {
      playMusic
    };
  }
});