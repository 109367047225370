import User from '@/common/types/User';
import httpClient from '@/common/utils/http-client';
import {UpdateUserData, UserNonceData, UsersData} from '@/common/api/users/types';
import { getAddress } from 'ethers';

const API_URL = process.env.NODE_ENV === 'production' ? 'https://nft-api.dokidoki.com' : 'https://nft-api-dev.dokidoki.com';

export async function getUserProfileByAddress(userAddress: string): Promise<User|null> {
  const userProfiles = await getUserProfilesIndexedByAddress([userAddress]);

  return userProfiles[getAddress(userAddress)] || null;
}

export async function getUserProfilesIndexedByAddress(userAddresses: string[]): Promise<Record<string, User>> {
  const response = await httpClient.post(`${API_URL}`, {
    query: `{
      users(addresses: ["${userAddresses.map(userAddress => getAddress(userAddress)).join('", "')}"]) {
        address
        username
        description
        profilePicture
        websiteLink
        twitterLink
        instagramLink
      }
    }`,
  });

  const usersData: UsersData = response.data;
  const userProfiles: Record<string, User> = {};
  for (const userData of usersData.users) {
    userProfiles[userData.address] = userData;
  }

  return userProfiles;
}

export async function getUserNonceByAddress(userAddress: string): Promise<string> {
  const response = await httpClient.post(`${API_URL}`, {
    query: `{
      userNonce(address: "${getAddress(userAddress)}") {
        nonce
      }
    }`,
  });

  const userNonceData: UserNonceData = response.data;

  return userNonceData.userNonce.nonce;
}

function surroundValueWithDoubleQuotesOrNull(value: string | null): string | null {
  return value ? `"${value}"` : null;
}

function buildUpdateQueryParameters(user: User): string {
  let queryParameters = '';

  for (const key in user) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const value = key === 'address' ? getAddress(user[key]) : user[key];
    queryParameters += `,${key}: ${surroundValueWithDoubleQuotesOrNull(value)}`;
  }

  return queryParameters;
}

export async function updateUserProfile(walletSignature: string, user: User): Promise<User> {
  const response = await httpClient.post(`${API_URL}`, {
    query: `
      mutation {
        updateUser(
          signature: "${walletSignature}"`
          + buildUpdateQueryParameters(user) +
        `) {
          username
          description
          profilePicture
          websiteLink
          twitterLink
          instagramLink
        }
      }`,
    }
  );

  const userData: UpdateUserData = response.data;

  return userData.updateUser;
}
