import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "claim-view-container"
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = {
  class: "rounds-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_WalletSignIn = _resolveComponent("WalletSignIn");
  const _component_Round = _resolveComponent("Round");
  const _component_ShadowedButton = _resolveComponent("ShadowedButton");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!_ctx.isWalletConnected ? (_openBlock(), _createBlock(_component_WalletSignIn, {
    key: 0
  })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rounds, round => {
    return _openBlock(), _createBlock(_component_Round, {
      key: round.id,
      round: round
    }, null, 8, ["round"]);
  }), 128))]), !_ctx.hasLoadedAll && _ctx.rounds.length > 0 ? (_openBlock(), _createBlock(_component_ShadowedButton, {
    key: 0,
    onClick: _ctx.loadPlayedRounds,
    shadowSize: "s",
    theme: "violet-white",
    isLoading: _ctx.isLoading,
    class: "load-more-button"
  }, {
    default: _withCtx(() => [_createTextVNode("LOAD MORE")]),
    _: 1
  }, 8, ["onClick", "isLoading"])) : _createCommentVNode("", true)]))]);
}