import { defineComponent } from 'vue';
import { translateOwnedNFTtoTransferableNFT } from '@/common/contracts/nfts';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import QuantityInput from '@/components/Inputs/QuantityInput.vue';
import BaseDialog from './BaseDialog.vue';
import { ElMessage } from 'element-plus';
import { connectedWallet, getWalletSigner } from '@/common/utils/web3-onboard';
import { isAddress } from 'ethers';
import { resolveENStoETHAddress } from '@/common/utils/ethers/ens';
export default defineComponent({
  name: 'TransferNFTDialog',
  components: {
    ShadowedButton,
    QuantityInput,
    BaseDialog
  },
  data() {
    return {
      connectedWallet,
      quantity: 0,
      toWalletAddress: '',
      loading: false,
      transferableNft: null
    };
  },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    closeDialog: {
      type: Function,
      default: () => ({})
    },
    selectedNft: {
      type: Object
    }
  },
  computed: {
    isERC1155() {
      return this.selectedNft?.standard === 'ERC1155';
    },
    maxQuantity() {
      if (this.selectedNft && this.isERC1155) {
        return this.selectedNft.balance < this.selectedNft.maxSupply ? this.selectedNft.balance : this.selectedNft.maxSupply;
      }
      return 1;
    },
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    }
  },
  methods: {
    async getWalletAddressFromENS(toWalletAddress) {
      let walletAddress = toWalletAddress;
      try {
        if (!isAddress(toWalletAddress)) {
          walletAddress = await resolveENStoETHAddress(walletAddress);
        }
      } catch (e) {
        throw Error('Couldn\'t resolve ENS!');
      }
      return walletAddress;
    },
    async handleTransfer() {
      if (!this.toWalletAddress || !this.transferableNft || !this.selectedNft || this.loading || this.selectedNft.standard === 'ERC1155' && !this.quantity || !this.connectedWallet) {
        return;
      }
      this.loading = true;
      try {
        const toWalletAddress = await this.getWalletAddressFromENS(this.toWalletAddress);
        if (!isAddress(toWalletAddress)) {
          throw 'Incorrect wallet address!';
        }
        const walletSigner = await getWalletSigner(this.connectedWallet);
        await this.transferableNft.transfer(walletSigner, this.walletAddress, toWalletAddress, this.quantity);
        this.selectedNft.balance -= this.quantity;
        this.$emit('nft-transferred');
        ElMessage({
          message: 'Transfer outbound!',
          grouping: true,
          type: 'success'
        });
        this.closeDialog();
      } catch (e) {
        if (e instanceof Error) {
          ElMessage({
            message: `Couldn't start the transfer: ${e.message}`,
            grouping: true,
            type: 'error'
          });
        }
      }
      this.loading = false;
    }
  },
  async created() {
    if (!this.selectedNft) {
      return;
    }
    this.transferableNft = translateOwnedNFTtoTransferableNFT(this.selectedNft);
    this.quantity = !this.isERC1155 ? 1 : 0;
  }
});