import { defineComponent } from 'vue';
import { bigIntToString } from '@/common/utils/format-numbers';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import { parseUnits } from 'ethers';
export default defineComponent({
  name: 'BigIntQuantityInput',
  components: {
    ShadowedButton
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    quantity: {
      type: BigInt,
      default: 0n
    },
    maxQuantity: {
      type: BigInt,
      default: 0n
    },
    decimals: {
      type: Number
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    bigIntToString,
    handleChange(event) {
      const element = event.currentTarget;
      if (!element.value) {
        this.$emit('update:quantity', 0n);
        return;
      }
      const quantity = parseUnits(element.value.toString(), this.decimals);
      this.$emit('update:quantity', quantity);
    },
    setMaxQuantity() {
      if (this.disabled) {
        return;
      }
      this.$emit('update:quantity', this.maxQuantity);
    }
  }
});