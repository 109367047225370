export interface State {
  viewport: {
    hasReachedBottomOfPage: boolean;
  }
}

const state: State = {
  viewport: {
    hasReachedBottomOfPage: false
  }
};

export default state;
