import { defineComponent } from 'vue';
export default defineComponent({
  name: 'Carousel',
  props: {
    onCarouselChange: {
      type: Function,
      default: () => ({})
    },
    trigger: {
      type: String,
      default: 'click'
    },
    height: {
      type: String
    },
    interval: {
      type: Number,
      default: 5000
    },
    indicators: {
      type: String
    },
    initialIndex: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: ''
    }
  }
});