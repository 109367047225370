import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "ethereum-tab"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_OwnedNFTsList = _resolveComponent("OwnedNFTsList");
  const _component_TransferNFTDialog = _resolveComponent("TransferNFTDialog");
  const _component_TransferNFTToPolygonDialog = _resolveComponent("TransferNFTToPolygonDialog");
  const _component_TransferNFTsToPolygonDialog = _resolveComponent("TransferNFTsToPolygonDialog");
  const _component_SwitchNetworkToEthereumDialog = _resolveComponent("SwitchNetworkToEthereumDialog");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_OwnedNFTsList, {
    forceRefreshList: _ctx.forceRefreshList,
    "onUpdate:forceRefreshList": _cache[0] || (_cache[0] = $event => _ctx.forceRefreshList = $event),
    selectedNfts: _ctx.selectedNfts,
    blockchainId: _ctx.L1BlockchainId,
    onNftDeposit: _ctx.openNFTDepositDialog,
    onNftTransfer: _ctx.openNFTTransferDialog,
    onNftsSelected: _ctx.setSelectedNfts,
    onOpenBatchDialog: _ctx.openNFTsDepositDialog
  }, null, 8, ["forceRefreshList", "selectedNfts", "blockchainId", "onNftDeposit", "onNftTransfer", "onNftsSelected", "onOpenBatchDialog"]), _ctx.selectedNft ? (_openBlock(), _createBlock(_component_TransferNFTDialog, {
    key: 0,
    isDialogVisible: _ctx.isNFTTransferDialogVisible,
    closeDialog: _ctx.closeNFTTransferDialog,
    selectedNft: _ctx.selectedNft,
    onNftTransferred: _cache[1] || (_cache[1] = $event => _ctx.forceRefreshList = true)
  }, null, 8, ["isDialogVisible", "closeDialog", "selectedNft"])) : _createCommentVNode("", true), _ctx.selectedNft ? (_openBlock(), _createBlock(_component_TransferNFTToPolygonDialog, {
    key: 1,
    isDialogVisible: _ctx.isNFTDepositDialogVisible,
    closeDialog: _ctx.closeNFTDepositDialog,
    selectedNft: _ctx.selectedNft,
    onNftTransferred: _cache[2] || (_cache[2] = $event => _ctx.forceRefreshList = true)
  }, null, 8, ["isDialogVisible", "closeDialog", "selectedNft"])) : _createCommentVNode("", true), _ctx.selectedNfts ? (_openBlock(), _createBlock(_component_TransferNFTsToPolygonDialog, {
    key: 2,
    isDialogVisible: _ctx.isNFTsDepositDialogVisible,
    closeDialog: _ctx.closeNFTsDepositDialog,
    selectedNfts: _ctx.selectedNfts,
    onNftsTransferred: _cache[3] || (_cache[3] = $event => {
      _ctx.forceRefreshList = true;
      _ctx.selectedNfts = [];
    })
  }, null, 8, ["isDialogVisible", "closeDialog", "selectedNfts"])) : _createCommentVNode("", true), _createVNode(_component_SwitchNetworkToEthereumDialog, {
    isDialogVisible: _ctx.isSwitchNetworkDialogVisible,
    closeDialog: _ctx.closeSwitchNetworkDialog
  }, null, 8, ["isDialogVisible", "closeDialog"])]);
}