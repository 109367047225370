import { defineComponent } from 'vue';
import { bigIntToString } from '@/common/utils/format-numbers';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import BigIntQuantityInput from '@/components/Inputs/BigIntQuantityInput.vue';
import BaseDialog from '@/components/Dialogs/BaseDialog.vue';
import { ElMessage } from 'element-plus';
import { connectedWallet, getWalletSigner } from '@/common/utils/web3-onboard';
import erc20Contract from '@/common/contracts/erc20';
export default defineComponent({
  name: 'StakeTokenDialog',
  components: {
    ShadowedButton,
    BigIntQuantityInput,
    BaseDialog
  },
  data() {
    return {
      connectedWallet,
      quantity: 0n,
      loading: false
    };
  },
  props: {
    walletBalance: {
      type: BigInt,
      default: 0n
    },
    walletApprovedAmount: {
      type: BigInt,
      default: 0n
    },
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    closeDialog: {
      type: Function,
      default: () => ({})
    },
    stakingPool: {
      type: Object
    }
  },
  computed: {
    mustApprove() {
      if (this.walletBalance < this.walletApprovedAmount) {
        return false;
      }
      return true;
    },
    canStake() {
      if (!this.loading && !this.mustApprove && this.connectedWallet && this.quantity > 0n && this.walletBalance >= this.quantity) {
        return true;
      }
      return false;
    },
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    }
  },
  methods: {
    bigIntToString,
    async handleApprove() {
      if (!this.stakingPool || !this.connectedWallet) {
        return;
      }
      this.loading = true;
      try {
        const walletSigner = await getWalletSigner(this.connectedWallet);
        await erc20Contract.approveAll(walletSigner, this.walletAddress, this.stakingPool.metadata.address, this.stakingPool.metadata.stakingToken.address);
        this.$emit('token-approved');
        ElMessage({
          message: 'Approved!',
          grouping: true,
          type: 'success'
        });
      } catch (e) {
        if (e instanceof Error) {
          ElMessage({
            message: `Couldn't approve: ${e.message}`,
            grouping: true,
            type: 'error'
          });
        }
      }
      this.loading = false;
    },
    async handleStake() {
      if (!this.stakingPool || !this.connectedWallet) {
        return;
      }
      if (!this.canStake) {
        ElMessage({
          message: 'Not enough token to stake.',
          grouping: true,
          type: 'error'
        });
        return;
      }
      this.loading = true;
      try {
        const walletSigner = await getWalletSigner(this.connectedWallet);
        await this.stakingPool.stake(walletSigner, this.walletAddress, this.quantity);
        this.$emit('token-staked');
        ElMessage({
          message: 'Staked!',
          grouping: true,
          type: 'success'
        });
      } catch (e) {
        if (e instanceof Error) {
          ElMessage({
            message: `Couldn't stake: ${e.message}`,
            grouping: true,
            type: 'error'
          });
        }
        console.error(e);
      }
      this.loading = false;
      this.closeDialog();
    }
  }
});