import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "polygon-tab"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_alert = _resolveComponent("el-alert");
  const _component_OwnedNFTsList = _resolveComponent("OwnedNFTsList");
  const _component_TransferNFTDialog = _resolveComponent("TransferNFTDialog");
  const _component_TransferNFTToEthereumDialog = _resolveComponent("TransferNFTToEthereumDialog");
  const _component_TransferNFTsToEthereumDialog = _resolveComponent("TransferNFTsToEthereumDialog");
  const _component_SwitchNetworkToPolygonDialog = _resolveComponent("SwitchNetworkToPolygonDialog");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.isCollectionOutOfSync ? (_openBlock(), _createBlock(_component_el_alert, {
    key: 0,
    title: `Our indexing service seems to have a hard time catching up to all those plays (~${_ctx.minutesBehind} minutes late)! Your NFTs are safe in your wallet, but we seem unable to display your collection in real time right now. We apologize for the inconvenience. Please check again later!`,
    type: "warning",
    closable: false,
    "show-icon": ""
  }, null, 8, ["title"])) : _createCommentVNode("", true), _createVNode(_component_OwnedNFTsList, {
    forceRefreshList: _ctx.forceRefreshList,
    "onUpdate:forceRefreshList": _cache[0] || (_cache[0] = $event => _ctx.forceRefreshList = $event),
    selectedNfts: _ctx.selectedNfts,
    blockchainId: _ctx.L2BlockchainId,
    onNftWithdraw: _ctx.openNFTWithdrawDialog,
    onNftTransfer: _ctx.openNFTTransferDialog,
    onNftsSelected: _ctx.setSelectedNfts,
    onOpenBatchDialog: _ctx.openNFTsWithdrawDialog
  }, null, 8, ["forceRefreshList", "selectedNfts", "blockchainId", "onNftWithdraw", "onNftTransfer", "onNftsSelected", "onOpenBatchDialog"]), _ctx.selectedNft ? (_openBlock(), _createBlock(_component_TransferNFTDialog, {
    key: 1,
    isDialogVisible: _ctx.isNFTTransferDialogVisible,
    closeDialog: _ctx.closeNFTTransferDialog,
    selectedNft: _ctx.selectedNft,
    onNftTransferred: _cache[1] || (_cache[1] = $event => _ctx.forceRefreshList = true)
  }, null, 8, ["isDialogVisible", "closeDialog", "selectedNft"])) : _createCommentVNode("", true), _ctx.selectedNft ? (_openBlock(), _createBlock(_component_TransferNFTToEthereumDialog, {
    key: 2,
    isDialogVisible: _ctx.isNFTWithdrawDialogVisible,
    closeDialog: _ctx.closeNFTWithdrawDialog,
    selectedNft: _ctx.selectedNft,
    onNftTransferred: _cache[2] || (_cache[2] = $event => _ctx.forceRefreshList = true)
  }, null, 8, ["isDialogVisible", "closeDialog", "selectedNft"])) : _createCommentVNode("", true), _ctx.selectedNfts ? (_openBlock(), _createBlock(_component_TransferNFTsToEthereumDialog, {
    key: 3,
    isDialogVisible: _ctx.isNFTsWithdrawDialogVisible,
    closeDialog: _ctx.closeNFTsWithdrawDialog,
    selectedNfts: _ctx.selectedNfts,
    onNftsTransferred: _cache[3] || (_cache[3] = $event => {
      _ctx.forceRefreshList = true;
      _ctx.selectedNfts = [];
    })
  }, null, 8, ["isDialogVisible", "closeDialog", "selectedNfts"])) : _createCommentVNode("", true), _createVNode(_component_SwitchNetworkToPolygonDialog, {
    isDialogVisible: _ctx.isSwitchNetworkDialogVisible,
    closeDialog: _ctx.closeSwitchNetworkDialog
  }, null, 8, ["isDialogVisible", "closeDialog"])]);
}