import httpClient from '@/common/utils/http-client';
import Gachapon from '@/common/types/Gachapon';
import { VRFGachaponData, VRFGachaponFilters, VRFGachaponsData, VRFIndexesData } from './types';
import { getL2BlockchainId } from '@/common/utils/blockchains';
import { getAddress } from 'ethers';

const API_URL = process.env.NODE_ENV === 'development'
    ? 'https://api.thegraph.com/subgraphs/name/kirienzoeth/dokidoki-gachapons-mumbai'
    : process.env.VUE_APP_USE_BACKUP_SUBGRAPH === '1'
        ? 'https://graph.dokidoki.com/subgraphs/name/DokiDokiFinance/gachapon-subgraph'
        : 'https://api.thegraph.com/subgraphs/name/kirienzoeth/dokidoki-gachapons';

export function translateVRFGachaponDataToGachapon(vrfGachaponData: VRFGachaponData): Gachapon {
  const gachapon: Gachapon = {
    prizesAmountLeft: +vrfGachaponData.amountInPrizePool,
    isBanned: false,
    isLocked: vrfGachaponData.isLocked,
    isLockedForever: vrfGachaponData.isLockedForever,
    isCurated: vrfGachaponData.isCurated,
    address: getAddress(vrfGachaponData.id),
    title: vrfGachaponData.title,
    description: vrfGachaponData.description,
    price: BigInt(vrfGachaponData.playOncePrice),
    currency: {
      symbol: vrfGachaponData.currency.symbol,
      name: vrfGachaponData.currency.name,
      decimals: vrfGachaponData.currency.decimals,
      address: vrfGachaponData.currency.id,
      isWrappedNativeToken: vrfGachaponData.currency.isWrappedNativeToken,
      isSupported: vrfGachaponData.currency.isSupported
    },
    ownerAddress: getAddress(vrfGachaponData.owner),
    timesPlayed: +vrfGachaponData.timesPlayed,
    ownerProfitsRate: vrfGachaponData.artistRate / 10,
    ownerProfits: BigInt(vrfGachaponData.artistProfits),
    version: vrfGachaponData.factory.version,
    createdAt: new Date(vrfGachaponData.createdAt * 1000),
    roundsAmount: vrfGachaponData.roundsAmount,
    tokenomicsAmount: BigInt(vrfGachaponData.forTokenomics),
    blockchainId: getL2BlockchainId(),
    payoutAddresses: vrfGachaponData.profitsSharingAddresses.map(
      (payoutAddress) => ({
        address: getAddress(payoutAddress.walletAddress),
        rate: payoutAddress.rate / 10
      })
    ),
    category: {
      id: +vrfGachaponData.category.id,
      name: vrfGachaponData.category.name
    },
    uniqueNftsLoadedAmount: vrfGachaponData.nftsAmount,
    spinsAmountLeft: vrfGachaponData.roundsLeft,
    isAllowlistOnly: vrfGachaponData.isAllowlistOnly
  };

  return gachapon;
}

export async function getVRFGachapon(gachaponAddress: string): Promise<Gachapon|null> {
  const response = await httpClient.post(API_URL, {
    query: `{
      gachapon(id: "${gachaponAddress.toLowerCase()}") {
          id
          owner
          title
          description
          playOncePrice
          isLocked
          isLockedForever
          isCurated
          amountInPrizePool
          timesPlayed
          artistRate
          artistProfits
          createdAt
          nftsAmount
          currency {
            id
            symbol
            name
            decimals
            isWrappedNativeToken
            isSupported
          }
          roundsAmount
          forTokenomics
          profitsSharingAddresses {
            walletAddress
            rate
          }
          category {
            id
            name
          }
          roundsLeft
          factory {
            id
            version
          }
          isAllowlistOnly
      }
  }`,
  });

  if (!response.data.gachapon) {
    return null;
  }

  const gachaponData: VRFGachaponData = response.data.gachapon;

  return translateVRFGachaponDataToGachapon(gachaponData);
}

function buildWhereConditions(filters?: VRFGachaponFilters): string[] {
  const whereConditions: string[] = [];

  if (filters?.banned !== undefined) {
    whereConditions.push(`isBanned: ${filters.banned}`);
  }

  if (filters?.categoryId !== undefined) {
    whereConditions.push(`category: "${filters.categoryId}"`);
  }

  if (filters?.ownerAddress) {
    whereConditions.push(`owner: "${filters.ownerAddress}"`);
  }

  if (filters?.curated !== undefined) {
    whereConditions.push(`isCurated: ${filters.curated}`);
  }

  if (filters?.locked !== undefined) {
    whereConditions.push(`isLocked: ${filters.locked}`);
  }

  if (filters?.lockedForever !== undefined) {
    whereConditions.push(`isLockedForever: ${filters.lockedForever}`);
  }

  if (filters?.soldOut !== undefined) {
    if (filters.soldOut) {
      whereConditions.push('timesPlayed_gt: 0');
      whereConditions.push('amountInPrizePool: 0');
    } else {
      whereConditions.push('amountInPrizePool_gt: 0');
    }
  }

  if (filters?.empty !== undefined) {
    filters.empty ? whereConditions.push('nftsAmount: 0') : whereConditions.push('nftsAmount_gt: 0');
  }

  return whereConditions;
}

export async function getVRFGachaponsIndexedByAddress(from = 0, limit = 50, orderBy: 'createdAt' = 'createdAt', orderDirection: 'asc' | 'desc' = 'desc', filters: VRFGachaponFilters|undefined = undefined): Promise<Record<string, Gachapon>> {
    const whereConditions = buildWhereConditions(filters);
    const response = await httpClient.post(API_URL, {
        query: `{
            gachapons(first: ${limit}, skip: ${from}, where: {${whereConditions.join(', ')}}, orderBy: ${orderBy}, orderDirection: ${orderDirection}) {
                id
                owner
                title
                description
                playOncePrice
                isLocked
                isLockedForever
                isCurated
                amountInPrizePool
                timesPlayed
                artistRate
                artistProfits
                createdAt
                nftsAmount
                currency {
                  id
                  symbol
                  name
                  decimals
                  isWrappedNativeToken
                  isSupported
                }
                roundsAmount
                forTokenomics
                profitsSharingAddresses {
                  walletAddress
                  rate
                }
                category {
                  id
                  name
                }
                roundsLeft
                factory {
                  id
                  version
                }
                isAllowlistOnly
            }
        }`,
    });

    const gachapons: Record<string, Gachapon> = {};
    if (response.data) {
      const gachaponsData = response.data as VRFGachaponsData;
        for (const gachaponData of gachaponsData.gachapons) {
            const gachapon = translateVRFGachaponDataToGachapon(gachaponData);
            gachapons[gachapon.address] = gachapon;
        }
    }

    return gachapons;
}

export async function getVRFGachapons(from = 0, limit = 50, orderBy: 'createdAt' = 'createdAt', orderDirection: 'asc' | 'desc' = 'desc', filters: VRFGachaponFilters|undefined = undefined): Promise<Gachapon[]> {
  const gachapons = await getVRFGachaponsIndexedByAddress(from, limit, orderBy, orderDirection, filters);

  return Object.values(gachapons);
}

export async function getAllVRFGachapons(orderBy: 'createdAt' = 'createdAt', orderDirection: 'asc' | 'desc' = 'desc', filters: VRFGachaponFilters|undefined = undefined): Promise<Gachapon[]> {
  const limit = 100;
  let from = 0;
  let gachapons: Gachapon[] = [];

  let gachaponsBatch = [];
  do {
    gachaponsBatch = await getVRFGachapons(from, limit, orderBy, orderDirection, filters);
    gachapons = [...gachapons, ...gachaponsBatch];

    from += limit;
  } while (gachaponsBatch.length >= limit);

  return gachapons;
}

export async function translateVRFNumbersToTokenIdsAndCollectionAddresses(gachaponAddress: string, VRFNumbers: number[]): Promise<{tokenIds: string[], collectionAddresses: string[]}> {
    const gachaponNftIds = VRFNumbers.map((index) => {
        return gachaponAddress.toLowerCase() + '_' + index;
    });

    const response = await httpClient.post(API_URL, {
        query: `{
            indexOfGachaponNfts(where: {id_in: ["${gachaponNftIds.join('","')}"]}) {
              id
              gachaponNft {
                nft {
                  collection
                  tokenId
                }
              }
            }
          }`,
    });

    const tokenIds: string[] = [];
    const collectionAddresses: string[] = [];
    if (response.data) {
        const indexesData: VRFIndexesData = response.data as VRFIndexesData;
        const tokenIdsIndexedByVRFNumber: Record<string, string> = {};
        const collectionAddressesIndexedByVRFNumber: Record<string, string> = {};
        for (const indexOfGachaponNft of indexesData.indexOfGachaponNfts) {
            const splitId: string[] = indexOfGachaponNft.id.split('_');
            const VRFNumber = splitId[1];
            if (!VRFNumber) {
                continue;
            }

            tokenIdsIndexedByVRFNumber[VRFNumber] = indexOfGachaponNft.gachaponNft.nft.tokenId;
            collectionAddressesIndexedByVRFNumber[VRFNumber] = indexOfGachaponNft.gachaponNft.nft.collection;
        }

        for (const VRFNumber of VRFNumbers) {
            if (!tokenIdsIndexedByVRFNumber[VRFNumber]) {
                continue;
            }

            tokenIds.push(tokenIdsIndexedByVRFNumber[VRFNumber]);
            collectionAddresses.push(collectionAddressesIndexedByVRFNumber[VRFNumber]);
        }
    }

    return {tokenIds, collectionAddresses};
}
