import httpClient from '@/common/utils/http-client';
import { getAllNftsByTokenIdsAndCollectionAddresses } from '@/common/api/nfts/nfts';
import { GachaponNFTsData } from './types';
import GachaponNFT from '@/common/types/GachaponNFT';
import Gachapon from '@/common/types/Gachapon';
import NFT from '@/common/types/NFT';
import {getL2BlockchainId} from '@/common/utils/blockchains';

const API_URL = process.env.NODE_ENV === 'development'
  ? 'https://api.thegraph.com/subgraphs/name/kirienzoeth/dokidoki-gachapons-mumbai'
  : process.env.VUE_APP_USE_BACKUP_SUBGRAPH === '1'
    ? 'https://graph.dokidoki.com/subgraphs/name/DokiDokiFinance/gachapon-subgraph'
    : 'https://api.thegraph.com/subgraphs/name/kirienzoeth/dokidoki-gachapons';

export async function getGachaponNftsByGachapon(gachapon: Gachapon, from = 0, limit = 100): Promise<GachaponNFT[]> {
  const response = await httpClient.post(`${API_URL}`, {
    query: `{
      gachaponNfts(first: ${limit}, skip: ${from}, where: {gachapon: "${gachapon.address.toLowerCase()}"}, orderBy: loadedAmount, orderDirection: asc) {
        id
        balance
        loadedAmount
        nft {
          collection
          tokenId
        }
      }
    }`,
  });

  const gachaponNFTs: GachaponNFT[] = [];
  const gachaponNftsData: GachaponNFTsData = response.data as GachaponNFTsData;
  if (gachaponNftsData.gachaponNfts) {
    const tokenIds = [];
    const collectionAddresses = [];
    const balances: Record<string, number> = {};
    const loadedAmounts: Record<string, number> = {};
    for (const gachaponNFTData of gachaponNftsData.gachaponNfts) {
      const index = `${gachaponNFTData.nft.collection.toLowerCase()}_${gachaponNFTData.nft.tokenId.toLowerCase()}`;
      balances[index] = +gachaponNFTData.balance;
      loadedAmounts[index] = +gachaponNFTData.loadedAmount;

      tokenIds.push(gachaponNFTData.nft.tokenId);
      collectionAddresses.push(gachaponNFTData.nft.collection);
    }

    const nfts: NFT[] = await getAllNftsByTokenIdsAndCollectionAddresses(tokenIds, collectionAddresses, getL2BlockchainId());
    for (const nft of nfts) {
      const index = `${nft.collectionAddress.toLowerCase()}_${nft.tokenId.toLowerCase()}`;
      gachaponNFTs.push(
        {...nft, balance: balances[index], loadedAmount: loadedAmounts[index]}
      );
    }
  }

  return gachaponNFTs;
}

export async function getAllGachaponNftsByGachapon(gachapon: Gachapon): Promise<GachaponNFT[]> {
  const limit = 100;
  let from = 0;
  let gachaponNFTs: GachaponNFT[] = [];

  let gachaponNFTsBatch = [];
  do {
    gachaponNFTsBatch = await getGachaponNftsByGachapon(gachapon, from, limit);
    gachaponNFTs = [...gachaponNFTs, ...gachaponNFTsBatch];

    from += limit;
  } while (gachaponNFTsBatch.length >= limit);

  return gachaponNFTs;
}
