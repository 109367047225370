import Gachapon from '@/common/types/Gachapon';
import {PlayableGachapon} from './types';
import LegacyPlayableGachapon from './LegacyPlayableGachapon';
import VRFPlayableGachapon from './VRFPlayableGachapon';
import { getAllLegacyGachaponsIndexedByAddress, getVRFGachapon } from '@/common/api/gachapons';
import { getAddress } from 'ethers';

export async function getVRFPlayableGachaponByAddress(gachaponAddress: string): Promise<VRFPlayableGachapon|null> {
  const gachapon = await getVRFGachapon(gachaponAddress);
  if (!gachapon) {
    return null;
  }

  return new VRFPlayableGachapon(gachapon);
}

async function getLegacyPlayableGachapons(): Promise<Record<string, LegacyPlayableGachapon>> {
    const gachapons: Record<string, Gachapon> = await getAllLegacyGachaponsIndexedByAddress();

    const legacyPlayableGachapons: Record<string, LegacyPlayableGachapon> = {};
    for (const gachaponAddress in gachapons) {
        legacyPlayableGachapons[gachaponAddress] = new LegacyPlayableGachapon(gachapons[gachaponAddress]);
    }

    return legacyPlayableGachapons;
}

async function getVRFPlayableGachapon(gachaponAddress: string): Promise<Record<string, VRFPlayableGachapon>|null> {
    const gachapon = await getVRFGachapon(gachaponAddress);
    if (!gachapon) {
      return null;
    }

    const vrfPlayableGachapon: Record<string, VRFPlayableGachapon> = {};
    vrfPlayableGachapon[gachapon.address] = new VRFPlayableGachapon(gachapon);

    return vrfPlayableGachapon;
}

export async function getPlayableGachaponByAddress(gachaponAddress: string): Promise<PlayableGachapon | null> {
  const results = await Promise.allSettled([getLegacyPlayableGachapons(), getVRFPlayableGachapon(gachaponAddress)]);

  let playableGachapons: Record<string, PlayableGachapon> = {};
  for (const result of results) {
    if (result.status === 'fulfilled') {
      playableGachapons = {...playableGachapons, ...result.value};
    }
  }

  return playableGachapons[getAddress(gachaponAddress)] ?? null;
}
