import { GachaponColors } from '@/common/types/GachaponColors';
import customizationJson from '@/resources/gachapons/custom-colors.json';
import { getAddress } from 'ethers';


export function getGachaponColors(address: string): GachaponColors {
  const checksumAddress = getAddress(address);
  const customizationData: Record<string, Record<string, string>> = customizationJson;
  const config = customizationData[checksumAddress];
  if (config === undefined) {
    return {} as GachaponColors;
  }

  return {
    buttonTextColor: config.button_text_color,
    buttonBackgroundColor: config.button_background_color,
    mainColor: config.main_color,
    buttonShadowColor: config.button_shadow_color,
    buttonPressedBackgroundColor: config.button_pressed_background_color,
    buttonPressedTextColor: config.button_pressed_text_color,
    textBoxColor: config.text_box_color,
    textColor: config.text_color,
  };
}
