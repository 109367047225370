import { defineComponent } from 'vue';
import Wallet from './Wallet.vue';
import Network from './Network.vue';
import NavMenu from './NavMenu.vue';
export default defineComponent({
  name: 'HeaderContent',
  components: {
    Wallet,
    Network,
    NavMenu
  },
  props: {
    isAsideCollapsed: {
      type: Boolean,
      default: true
    },
    toggleAside: {
      type: Function,
      default: () => undefined
    }
  }
});