import axios from 'axios';

class HttpClient {
  async get(url: string, config?: Record<string, unknown>): Promise<Record<string, any>> {
    const response = await axios.get(url, config);

    return response.data;
  }

  async post(url: string, data?: any, config?: Record<string, unknown>): Promise<Record<string, any>> {
    const response = await axios.post(url, data, config);

    return response.data;
  }

  async put(url: string, data?: any, config?: Record<string, unknown>): Promise<Record<string, any>> {
    const response = await axios.put(url, data, config);

    return response.data;
  }
}

export default HttpClient;
