import Gachapon from '@/common/types/Gachapon';
import { AllowlistManageInterface, GachaponManager, VRFManageInterface } from './types';
import GachaponPayoutAddress from '@/common/types/GachaponPayoutAddress';
import OwnedNFT from '@/common/types/OwnedNFT';
import GachaponNFT from '@/common/types/GachaponNFT';
import { translateOwnedNFTtoTransferableNFT } from '@/common/contracts/nfts';
import { TransactionEvents } from '@/common/contracts/events/types';
import { VrfGachapon__factory, GachaponCategorizer__factory } from '@/contracts';
import { parseUnits, Signer } from 'ethers';

const GACHAPON_CATEGORIZER_ADDRESS = process.env.NODE_ENV === 'production' ? '0xc7Ceb18Af9B52DcdcD0B341aC19D3ceD97Fc3Bf5' : '0x9D5Bb0244682d78864361926eECbF1A0e58eCEF3';

export default class VRFGachaponManager implements GachaponManager, VRFManageInterface, AllowlistManageInterface, TransactionEvents {
  gachapon: Gachapon;
  lastTransactionHash?: string;
  state: 'waiting-transaction' | 'transaction-accepted';

  constructor(gachapon: Gachapon) {
    this.gachapon = gachapon;
    this.state = 'waiting-transaction';
  }

  async unlock(signer: Signer, userAddress: string): Promise<void> {
    const contract = VrfGachapon__factory.connect(this.gachapon.address, signer);
    const transaction = await contract.unlock({
      from: userAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: null
    });

    await transaction.wait(8);
  }

  async lock(signer: Signer, userAddress: string): Promise<void> {
    const contract = VrfGachapon__factory.connect(this.gachapon.address, signer);
    const transaction = await contract.lock({
      from: userAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: null
    });

    await transaction.wait(8);
  }

  async setName(signer: Signer, userAddress: string, name: string): Promise<void> {
    const contract = VrfGachapon__factory.connect(this.gachapon.address, signer);
    const transaction = await contract.setTitle(name, {
      from: userAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: null
    });

    await transaction.wait(8);
  }

  async setDescription(signer: Signer, userAddress: string, description: string): Promise<void> {
    const contract = VrfGachapon__factory.connect(this.gachapon.address, signer);
    const transaction = await contract.setDescription(description, {
      from: userAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: null
    });

    await transaction.wait(8);
  }

  async setSpinPrice(signer: Signer, userAddress: string, price: number): Promise<void> {
    const contract = VrfGachapon__factory.connect(this.gachapon.address, signer);
    const transaction = await contract.setPlayOncePrice(parseUnits(price.toString(), this.gachapon.currency.decimals), {
      from: userAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: null
    });

    await transaction.wait(8);
  }

  async loadNft(signer: Signer, userAddress: string, ownedNft: OwnedNFT, amount: number): Promise<void> {
      const transferableNft = translateOwnedNFTtoTransferableNFT(ownedNft);
      if (!transferableNft) {
        throw 'Can not transfer this NFT';
      }

      await transferableNft.transfer(signer, userAddress, this.gachapon.address, amount);
  }

  async setPayoutAddresses(signer: Signer, userAddress: string, payoutAddresses: GachaponPayoutAddress[]): Promise<void> {
    const addresses: string[] = [];
    const rates: number[] = [];
    for (const payoutAddress of payoutAddresses) {
      addresses.push(payoutAddress.address);
      rates.push(payoutAddress.rate * 10);
    }

    const contract = VrfGachapon__factory.connect(this.gachapon.address, signer);
    const transaction = await contract.setOwnerProfitsSharing(addresses, rates, {
      from: userAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: null
    });

    await transaction.wait(8);
  }

  async setCategory(signer: Signer, userAddress: string, categoryId: number): Promise<void> {
    const contract = GachaponCategorizer__factory.connect(GACHAPON_CATEGORIZER_ADDRESS, signer);
    const transaction = await contract.setGachaponCategory(this.gachapon.address, categoryId, {
      from: userAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: null
    });

    await transaction.wait(8);
  }

  async removeNft(signer: Signer, userAddress: string, gachaponNFT: GachaponNFT): Promise<void> {
    const contract = VrfGachapon__factory.connect(this.gachapon.address, signer);
    const transaction = await contract.removeNft(gachaponNFT.collectionAddress, gachaponNFT.tokenId, gachaponNFT.balance, {
      from: userAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: null
    });

    await transaction.wait(8);
  }

  async setAllowListMerkleRoot(signer: Signer, userAddress: string, merkelRoot: string): Promise<void> {
    this.state = 'waiting-transaction';
    this.lastTransactionHash = undefined;

    const contract = VrfGachapon__factory.connect(this.gachapon.address, signer);
    const transaction = await contract.setAllowListMerkleRoot(merkelRoot, {
      from: userAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: null
    });

    this.state = 'transaction-accepted';
    this.lastTransactionHash = transaction.hash;

    await transaction.wait(8);

    this.lastTransactionHash = undefined;
  }

  async setAllowlistOnlyStatus(signer: Signer, userAddress: string, isAllowlistOnly: boolean): Promise<void> {
    this.state = 'waiting-transaction';
    this.lastTransactionHash = undefined;

    const contract = VrfGachapon__factory.connect(this.gachapon.address, signer);
    const transaction = await contract.setAllowlistOnlyStatus(isAllowlistOnly, {
      from: userAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: null
    });
    this.state = 'transaction-accepted';
    this.lastTransactionHash = transaction.hash;

    await transaction.wait(8);

    this.lastTransactionHash = undefined;
  }
}
