import { getDefaultProvider, Provider } from 'ethers';
import { L1_HTTPS_RPC, L1_WSS_RPC, L2_HTTPS_RPC, L2_WSS_RPC } from '@/common/utils/blockchains/rpcs';

export function getL1Provider(webSocket = false): Provider {
  if (webSocket) {
    return getDefaultProvider(L1_WSS_RPC);
  }

  return getDefaultProvider(L1_HTTPS_RPC);
}

export function getL2Provider(webSocket = false): Provider {
  if (webSocket) {
    return getDefaultProvider(L2_WSS_RPC);
  }

  return getDefaultProvider(L2_HTTPS_RPC);
}
