import { defineComponent } from 'vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
export default defineComponent({
  name: 'QuantityInput',
  components: {
    ShadowedButton
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    quantity: {
      type: Number
    },
    minQuantity: {
      type: Number,
      default: 0
    },
    maxQuantity: {
      type: Number
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleChange(newValue) {
      this.$emit('update:quantity', newValue);
    },
    setMaxQuantity() {
      if (this.disabled) {
        return;
      }
      this.$emit('update:quantity', this.maxQuantity);
    }
  }
});