import { formatUnits, parseUnits } from 'ethers';

//Display purposes only, do not use for computations
export function roundNumberToLocalString(number: number): string {
  if (!number) {
      return '0';
  }

  let maximumFractionDigits = 2;
  if (number < 1) {
      maximumFractionDigits = 10;
  }

  return number.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits });
}

export function stringToWei(number: string, decimals = 18): string {
  return parseUnits(number, decimals).toString();
}

export function bigIntToString(bigint: bigint|undefined, decimals = 18): string {
  if (!bigint) {
    return '0';
  }

  return formatUnits(bigint, decimals);
}

export function bigIntToDisplayableString(bigint: bigint|undefined, decimals = 18): string {
  if (!bigint) {
    return '0';
  }

  const string = bigIntToString(bigint, decimals);
  if (!string) {
    return '0';
  }

  if (string < '1') {
    return string.slice(0, 8);
  }

  const displayableString = string.indexOf('.') === -1 ? string : string.slice(0, string.indexOf('.') + 3);
  const splitDisplayableString = displayableString.split('.');

  return splitDisplayableString[1] === '0' ? splitDisplayableString[0] : displayableString;
}
