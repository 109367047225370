// L1 Ethereum Blockchain IDs
export const L1_MAINNET_ID = 1;
export const L1_TESTNET_ID = 5;
// L2 Polygon Blockchain IDs
export const L2_MAINNET_ID = 137;
export const L2_TESTNET_ID = 80001;

export function getL1BlockchainId(): number {
  if (process.env.NODE_ENV === 'production') {
    return L1_MAINNET_ID;
  }

  return L1_TESTNET_ID;
}

export function getL2BlockchainId(): number {
  if (process.env.NODE_ENV === 'production') {
    return L2_MAINNET_ID;
  }

  return L2_TESTNET_ID;
}

export function getL1BlockchainIdHex(): string {
  const blockchainId = getL1BlockchainId();
  if (!blockchainId) {
    return '';
  }

  return `0x${blockchainId.toString(16)}`;
}

export function getL2BlockchainIdHex(): string {
  const blockchainId = getL2BlockchainId();
  if (!blockchainId) {
    return '';
  }

  return `0x${blockchainId.toString(16)}`;
}

export function getL1BlockchainExplorerLink(): string {
  if (getL1BlockchainId() === L1_TESTNET_ID) {
    return 'https://goerli.etherscan.io/';
  }

  return 'https://etherscan.io';
}

export function getL1BlockExplorerTxLink(): string {
  return `${getL1BlockchainExplorerLink}/tx`;
}

export function getL2BlockchainExplorerLink(): string {
  if (getL2BlockchainId() === L2_TESTNET_ID) {
    return 'https://mumbai.polygonscan.com';
  }

  return 'https://polygonscan.com';
}

export function getL2BlockchainExplorerTxLink(): string {
  return `${getL2BlockchainExplorerLink()}/tx`;
}
