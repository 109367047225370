import { translateVRFNumbersToTokenIdsAndCollectionAddresses } from '@/common/api/gachapons';
import { getAllNftsByTokenIdsAndCollectionAddresses } from '@/common/api/nfts';
import Gachapon from '@/common/types/Gachapon';
import NFT from '@/common/types/NFT';
import { PlayableGachapon } from './types';
import { getL2BlockchainId } from '@/common/utils/blockchains';
import { getGasPrice } from '@/common/api/gas-price';
import WalletAllowlistSpot from '@/common/types/WalletAllowlistSpot';
import {formatEther, formatUnits, parseUnits, Signer} from 'ethers';
import { VrfGachapon__factory } from '@/contracts';
import { getL2Provider } from '@/common/utils/ethers';

const provider = getL2Provider(true);

const EVENT_NAME_STARTED = 'RoundStarted';
const EVENT_NAME_COMPLETED = 'RoundCompleted';

async function getTokenIdsAndCollectionAddresses(gachaponAddress: string, requestId: string): Promise<{tokenIds: string[], collectionAddresses: string[]}> {
  const contract = VrfGachapon__factory.connect(gachaponAddress, provider);
  const filter = contract.filters[EVENT_NAME_COMPLETED];

  return new Promise((resolve, reject) => {
    contract.on(filter, (_requestId, _player, _times, _prizes) => {
      try {
        if (_requestId !== requestId) {
          return;
        }

        const nftIndexes = _prizes.map((_prize) => Number(_prize));
        const timesPlayed = Number(_times);

        resolve(translateVRFNumbersToTokenIdsAndCollectionAddresses(gachaponAddress, nftIndexes.splice(0, timesPlayed)));
        contract.removeAllListeners(filter);
      } catch (e) {
        reject();
      }
    });
  });
}

export default class VRFPlayableGachapon implements PlayableGachapon {
  gachapon: Gachapon;
  state: 'ready' | 'waiting';
  requestId?: string;

  constructor(gachapon: Gachapon) {
    this.gachapon = gachapon;
    this.state = 'ready';
  }

  async play(signer: Signer, userAddress: string, times: number, nativeTokenAmount = 0n, walletAllowlistSpot?: WalletAllowlistSpot): Promise<NFT[]> {
    const contract = VrfGachapon__factory.connect(this.gachapon.address, signer);
    const gasPrice = await getGasPrice();
    try {
      const transaction = !walletAllowlistSpot
        ? await contract['play(uint8)'](times, {
          value: nativeTokenAmount,
          from: userAddress,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
          gasPrice: gasPrice ? parseUnits(gasPrice.toString(), 'gwei') : null,
        })
        : await contract['play(uint8,uint256,uint256,bytes32[])'](times, walletAllowlistSpot.playAmountAllowed.toString(), walletAllowlistSpot.price.toString(), walletAllowlistSpot.merkleProof, {
          value: nativeTokenAmount,
          from: userAddress,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
          gasPrice: gasPrice ? parseUnits(gasPrice.toString(), 'gwei') : null
        });

      const receipt = await transaction.wait();
      this.state = 'waiting';

      const eventFragment = contract.interface.getEvent(EVENT_NAME_STARTED);
      const log = receipt?.logs.find(log => log.topics.indexOf(eventFragment.topicHash) >= 0);
      if (!log) {
        this.requestId = undefined;
        return [];
      }

      const logDescription = contract.interface.parseLog({ data: log.data, topics: [...log.topics] });
      const lastRequestId = logDescription?.args[0];
      this.requestId = lastRequestId;

      const { tokenIds, collectionAddresses } = await getTokenIdsAndCollectionAddresses(this.gachapon.address, lastRequestId);
      if (!tokenIds) {
        return [];
      }

      this.state = 'ready';

      return getAllNftsByTokenIdsAndCollectionAddresses(tokenIds, collectionAddresses, getL2BlockchainId());
    } catch (e) {
      this.state = 'ready';
      throw e;
    }
  }
}
