import { Loading as ElIconLoading } from '@element-plus/icons-vue';
import { defineComponent } from 'vue';
import RetrieveNFTDialog from '@/components/Dialogs/RetrieveNFTDialog.vue';
import SwitchNetworkToEthereumDialog from '@/components/Dialogs/SwitchNetworkToEthereumDialog.vue';
import RefreshButton from '@/components/GachaButton/RefreshButton.vue';
import PendingNFTCard from './PendingNFTCard.vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import { getPendingNftsIndexedByTransactionHashByOwnerAddress } from '@/common/api/nfts/pending-nfts';
import { getL1BlockchainIdHex } from '@/common/utils/blockchains';
import { connectedWallet, connectedChain } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'PendingTab',
  components: {
    RetrieveNFTDialog,
    SwitchNetworkToEthereumDialog,
    ShadowedButton,
    PendingNFTCard,
    RefreshButton,
    ElIconLoading
  },
  data() {
    return {
      connectedWallet,
      connectedChain,
      isLoading: false,
      isSwitchNetworkDialogVisible: false,
      selectedNft: null,
      pendingNftsIndexedByTransactionHash: {}
    };
  },
  computed: {
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    }
  },
  methods: {
    closeRetrieveNFTDialog() {
      this.selectedNft = null;
    },
    openRetrieveNFTDialog(selectedNft) {
      if (!selectedNft) {
        return;
      }
      if (this.connectedChain?.id !== getL1BlockchainIdHex()) {
        this.openSwitchNetworkDialog();
        return;
      }
      this.selectedNft = selectedNft;
    },
    closeSwitchNetworkDialog() {
      this.isSwitchNetworkDialogVisible = false;
    },
    openSwitchNetworkDialog() {
      this.isSwitchNetworkDialogVisible = true;
    },
    async fetchNfts() {
      if (!this.connectedWallet || this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.pendingNftsIndexedByTransactionHash = await getPendingNftsIndexedByTransactionHashByOwnerAddress(this.walletAddress);
      this.isLoading = false;
    }
  },
  async created() {
    await this.fetchNfts();
  },
  watch: {
    walletAddress: async function () {
      await this.fetchNfts();
    }
  }
});