import {VRFGachaponFilters} from '@/common/api/gachapons/types';

export * from './vrf-gachapons';
export * from './vrf-global-stats';
export * from './legacy-gachapons';
export * from './legacy-global-stats';
export * from './categories';

import { getAllLegacyGachaponsByOwnerAddress } from './legacy-gachapons';
import {getAllVRFGachapons} from './vrf-gachapons';
import Gachapon from '@/common/types/Gachapon';

export async function getAllGachaponsByOwnerAddress(ownerAddress: string, filters: VRFGachaponFilters|undefined = undefined): Promise<Gachapon[]> {
  const results = await Promise.allSettled([
    getAllVRFGachapons('createdAt', 'desc', {...filters, ownerAddress: ownerAddress}),
    getAllLegacyGachaponsByOwnerAddress(ownerAddress)
  ]);

  let gachapons: Gachapon[] = [];
  for (const result of results) {
    if (result.status === 'fulfilled') {
      gachapons = [...gachapons, ...result.value];
    }
  }

  return gachapons;
}
