import GachaponNFT from '@/common/types/GachaponNFT';
import Gachapon from '@/common/types/Gachapon';
import { getAllL2OwnedNftsByOwnerAddress } from './';

export async function getAllLegacyGachaponNftsByGachapon(gachapon: Gachapon): Promise<GachaponNFT[]> {
  const ownedNfts = await getAllL2OwnedNftsByOwnerAddress(gachapon.address, 'balance', 'asc', true);
  const gachaponNfts = ownedNfts.map(ownedNft => ({...ownedNft, loadedAmount: ownedNft.maxSupply}));

  return gachaponNfts;
}
