import { Share as ElIconShare } from '@element-plus/icons-vue';
import { defineComponent } from 'vue';
import { getNftByTokenIdAndCollectionAddress } from '@/common/api/nfts';
import NFTVisual from '@/components/NFT/NFTVisual.vue';
import { getUserProfileByAddress } from '@/common/api/users';
import { ElMessage } from 'element-plus';
import useClipboard from 'vue-clipboard3';
export default defineComponent({
  components: {
    NFTVisual,
    ElIconShare
  },
  data() {
    return {
      nft: null,
      artist: {},
      showPreview: false
    };
  },
  computed: {
    currentUrl() {
      return window.location?.origin ? window.location.origin + this.$route.fullPath : this.$route.fullPath;
    },
    openseaL2CollectionUrl() {
      if (!this.nft) {
        return '';
      }
      return `https://opensea.io/assets/matic/${this.nft.collectionAddress}`;
    }
  },
  methods: {
    async copyUrlToClipboard() {
      await this.copyToClipboard(this.currentUrl);
    },
    async fetchNft(tokenId, collectionAddress, blockchainId) {
      const nft = await getNftByTokenIdAndCollectionAddress(tokenId, collectionAddress, blockchainId);
      if (!nft) {
        ElMessage({
          message: 'NFT not found.',
          grouping: true,
          type: 'error'
        });
        return;
      }
      this.nft = nft;
    },
    async fetchArtist(artistAddress) {
      if (!this.nft?.artistAddress) {
        return;
      }
      const artist = await getUserProfileByAddress(artistAddress);
      if (!artist) {
        return;
      }
      this.artist = artist;
    }
  },
  async created() {
    await this.fetchNft(this.$route.params.tokenId.toString(), this.$route.params.collectionAddress.toString(), +this.$route.params.blockchainId);
    if (this.nft?.artistAddress) {
      await this.fetchArtist(this.nft.artistAddress);
    }
  },
  setup() {
    const {
      toClipboard
    } = useClipboard();
    const copyToClipboard = async text => {
      try {
        await toClipboard(text);
        ElMessage({
          message: 'Copied to clipboard!',
          grouping: true,
          type: 'success'
        });
      } catch (e) {
        ElMessage({
          message: 'Failed to copy.',
          grouping: true,
          type: 'error'
        });
      }
    };
    return {
      copyToClipboard
    };
  }
});