import { defineComponent } from 'vue';
import { truncate } from '@/common/utils/truncate';
export default defineComponent({
  name: 'OwnedNFTCard',
  components: {},
  props: {
    ownedNft: {
      type: Object
    },
    artist: {
      type: Object
    }
  },
  methods: {
    truncate
  }
});