import { Loading as ElIconLoading } from '@element-plus/icons-vue';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'DemoNFTItem',
  components: {
    ElIconLoading
  },
  data() {
    return {
      showTooltip: false
    };
  },
  props: {
    visualUrl: {
      type: String
    }
  }
});