import { createStore } from 'vuex';
import state from '@/app/store/state';
import mutations from '@/app/store/mutations';

export default createStore({
  state,
  mutations,
  actions: {},
  modules: {},
});
