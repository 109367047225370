import { defineComponent } from 'vue';
export default defineComponent({
  name: 'AsideContent',
  props: {
    isAsideCollapsed: {
      type: Boolean,
      default: true
    },
    closeAside: {
      type: Function,
      default: () => ({})
    }
  },
  computed: {
    routePath() {
      return this.$route.path;
    }
  }
});