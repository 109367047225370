import { getAddress } from 'ethers';

export const WETHL1TokenAddress = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2';

const L2toL1TokenMap = new Map<string, string>();
// TESTNET
L2toL1TokenMap.set('0x419Df01D6B601C4fEC7882f65512FE2403DC381e', '0xfb64c01c89d7e17bfc2f52fe33264c30fb69471a'); // BND
L2toL1TokenMap.set('0x98f50dfF2e808b0954A6f6562B952D105eF195f4', '0xd55Fc9a8d2c4CB4f13793B18ff34529228741779'); // AZUKI
L2toL1TokenMap.set('0xA6FA4fB5f76172d178d61B04b0ecd319C5d1C0aa', ''); // ETH
L2toL1TokenMap.set('', '0x499d11E0b6eAC7c0593d8Fb292DCBbF815Fb29Ae'); // MATIC
L2toL1TokenMap.set('0x326C977E6efc84E512bB9C30f76E30c160eD06FB ', '0x326C977E6efc84E512bB9C30f76E30c160eD06FB'); // LINK
// MAINNET
L2toL1TokenMap.set('0x032b3217241fe53fFaDB334a9d06aACd2A9153D9', '0x84CD68c3e470eCEE4b8b6212eFcB8C6BcB38DA1D'); // BND
L2toL1TokenMap.set('0x7CdC0421469398e0F3aA8890693d86c840Ac8931', '0x910524678C0B1B23FFB9285a81f99C29C11CBaEd'); // AZUKI
L2toL1TokenMap.set('0x5C7F7Fe4766fE8f0fa9b41E2E4194d939488ff1C', '0x9ceb84f92a0561fa3cc4132ab9c0b76a59787544'); // DOKI
L2toL1TokenMap.set('0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f', '0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f'); // MUST
L2toL1TokenMap.set('0xFfB6efF3e73DB35b3efCF2bCD160fc517C5Df9Ec', '0x7e6c38d007740931e4b419bf15a68c79a0fb0c66'); // uDOKI
L2toL1TokenMap.set('0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619', ''); // ETH
L2toL1TokenMap.set('', '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0'); // MATIC

const L2TokenAddressToSymbol = new Map<string, string>();
// TESTNET
L2TokenAddressToSymbol.set('0x419Df01D6B601C4fEC7882f65512FE2403DC381e', 'BND');
L2TokenAddressToSymbol.set('0x98f50dfF2e808b0954A6f6562B952D105eF195f4', 'AZUKI');
L2TokenAddressToSymbol.set('0xA6FA4fB5f76172d178d61B04b0ecd319C5d1C0aa', 'ETH');
L2TokenAddressToSymbol.set('0x326C977E6efc84E512bB9C30f76E30c160eD06FB', 'LINK');
L2TokenAddressToSymbol.set('0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889', 'WMATIC');
// MAINNET
L2TokenAddressToSymbol.set('0x032b3217241fe53fFaDB334a9d06aACd2A9153D9', 'BND');
L2TokenAddressToSymbol.set('0x5C7F7Fe4766fE8f0fa9b41E2E4194d939488ff1C', 'DOKI');
L2TokenAddressToSymbol.set('0x7CdC0421469398e0F3aA8890693d86c840Ac8931', 'AZUKI');
L2TokenAddressToSymbol.set('0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619', 'ETH');
L2TokenAddressToSymbol.set('0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A', 'TITAN');
L2TokenAddressToSymbol.set('0x385Eeac5cB85A38A9a07A70c73e0a3271CfB54A7', 'GHST');
L2TokenAddressToSymbol.set('0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270', 'WMATIC');
L2TokenAddressToSymbol.set('', 'MATIC');
L2TokenAddressToSymbol.set('0xFfB6efF3e73DB35b3efCF2bCD160fc517C5Df9Ec', 'uDOKI');
L2TokenAddressToSymbol.set('0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f', 'MUST');
L2TokenAddressToSymbol.set('0xd0985A2E8410c03B3bB0D7997DA433428D58342f', 'AZUKI/MUST CLP');
L2TokenAddressToSymbol.set('0x92Bb3233F59561FC1fEC53EfC3339E4Af8E917F4', 'AZUKI/ETH CLP');
L2TokenAddressToSymbol.set('0x9cb31B03089eca4C0f42554256d0217326D15AE7', 'DOKI/MUST CLP');
L2TokenAddressToSymbol.set('0xcCeD5cB001D6081c4561bf7911F11Ccd9aAA1474', 'DOKI/ETH CLP');

const L1TokenAddressToSymbol = new Map<string, string>();
// MAINNET
L1TokenAddressToSymbol.set('0x9cEB84f92A0561fa3Cc4132aB9c0b76A59787544', 'DOKI');
L1TokenAddressToSymbol.set('0x910524678C0B1B23FFB9285a81f99C29C11CBaEd', 'AZUKI');
L1TokenAddressToSymbol.set('0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2', 'WETH');
L1TokenAddressToSymbol.set('0x2260fac5e5542a773aa44fbcfedf7c193bc2c599', 'WBTC');
L1TokenAddressToSymbol.set('0x7e6c38d007740931e4b419bf15a68c79a0fb0c66', 'uDOKI');
L1TokenAddressToSymbol.set('0x1D4b2B2a2Ca8762410801b51f128B73743439E39', 'DOKI/ETH ULP');
L1TokenAddressToSymbol.set('0x654def3E97C3F4218C3f49ace81687483C361b2b', 'AZUKI/ETH ULP');

/** An empty string as an address means MATIC */
const L2LPTokenAddressToNativeTokens = new Map<string, string[]>();
L2LPTokenAddressToNativeTokens.set('0xcCeD5cB001D6081c4561bf7911F11Ccd9aAA1474', ['0x5C7F7Fe4766fE8f0fa9b41E2E4194d939488ff1C', '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619']); // DOKI/ETH CLP
L2LPTokenAddressToNativeTokens.set('0x9cb31B03089eca4C0f42554256d0217326D15AE7', ['0x5C7F7Fe4766fE8f0fa9b41E2E4194d939488ff1C', '0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f']); // DOKI/MUST CLP
L2LPTokenAddressToNativeTokens.set('0x92Bb3233F59561FC1fEC53EfC3339E4Af8E917F4', ['0x7CdC0421469398e0F3aA8890693d86c840Ac8931', '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619']); // AZUKI/ETH CLP
L2LPTokenAddressToNativeTokens.set('0xd0985A2E8410c03B3bB0D7997DA433428D58342f', ['0x7CdC0421469398e0F3aA8890693d86c840Ac8931', '0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f']); // AZUKI/MUST CLP

/** An empty string as an address means ETH */
const L1LPTokenAddressToNativeTokens = new Map<string, string[]>();
L1LPTokenAddressToNativeTokens.set('0x1D4b2B2a2Ca8762410801b51f128B73743439E39', ['0x9cEB84f92A0561fa3Cc4132aB9c0b76A59787544', '']); // DOKI/ETH ULP
L1LPTokenAddressToNativeTokens.set('0x654def3E97C3F4218C3f49ace81687483C361b2b', ['0x910524678C0B1B23FFB9285a81f99C29C11CBaEd', '']); // AZUKI/ETH ULP

export function getL1AddressOfToken(maticTokenAddress: string): string | undefined {
  let checksumAddress = maticTokenAddress;
  if (maticTokenAddress !== '') {
    checksumAddress = getAddress(maticTokenAddress);
  }

  return L2toL1TokenMap.get(checksumAddress);
}

export function getL2TokenSymbol(tokenAddress: string): string {
  let checksumAddress = tokenAddress;
  if (tokenAddress !== '') {
    checksumAddress = getAddress(tokenAddress);
  }

  return L2TokenAddressToSymbol.get(checksumAddress) ?? '';
}

export function getL1TokenSymbol(tokenAddress: string): string {
  let checksumAddress = tokenAddress;
  if (tokenAddress !== '') {
    checksumAddress = getAddress(tokenAddress);
  }

  return L1TokenAddressToSymbol.get(checksumAddress) ?? '';
}

export function getL2LPTokenAddressToNativeTokens(tokenAddress: string): string[] {
  let checksumAddress = tokenAddress;
  if (tokenAddress !== '') {
    checksumAddress = getAddress(tokenAddress);
  }

  return L2LPTokenAddressToNativeTokens.get(checksumAddress) ?? [];
}

export function getL1LPTokenAddressToNativeTokens(tokenAddress: string): string[] {
  let checksumAddress = tokenAddress;
  if (tokenAddress !== '') {
    checksumAddress = getAddress(tokenAddress);
  }

  return L1LPTokenAddressToNativeTokens.get(checksumAddress) ?? [];
}
