import { defineComponent } from 'vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import BaseDialog from './BaseDialog.vue';
import { withdrawNfts } from '@/common/contracts/nfts/bridge-nfts';
import { getL2BlockchainIdHex } from '@/common/utils/blockchains';
import { ElMessage } from 'element-plus';
import { connectedWallet, connectedChain } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'TransferNFTsToEthereumDialog',
  components: {
    ShadowedButton,
    BaseDialog
  },
  data() {
    return {
      connectedWallet,
      connectedChain,
      loading: false
    };
  },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    closeDialog: {
      type: Function,
      default: () => ({})
    },
    selectedNfts: {
      type: Array,
      default: []
    }
  },
  computed: {
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    }
  },
  methods: {
    async handleWithdraw() {
      if (this.connectedChain?.id !== getL2BlockchainIdHex() || this.selectedNfts.length <= 0 || !this.connectedWallet || this.loading) {
        return;
      }
      this.loading = true;
      try {
        const nftBalances = this.selectedNfts.map(selectedNft => selectedNft.balance);
        await withdrawNfts(this.connectedWallet, this.walletAddress, this.selectedNfts, nftBalances);
        ElMessage({
          message: 'Transfer inbound! It should appear in the "NFTs pending L2 -> L1" tab in a few seconds.',
          grouping: true,
          type: 'success'
        });
        this.$emit('nfts-transferred');
        this.closeDialog();
      } catch (e) {
        ElMessage({
          message: 'Couldn\'t start the transfer.',
          grouping: true,
          type: 'error'
        });
      }
      this.loading = false;
    }
  }
});