import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "claim-view-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_WalletSignIn = _resolveComponent("WalletSignIn");
  const _component_PolygonTab = _resolveComponent("PolygonTab");
  const _component_el_tab_pane = _resolveComponent("el-tab-pane");
  const _component_EthereumTab = _resolveComponent("EthereumTab");
  const _component_PendingTab = _resolveComponent("PendingTab");
  const _component_el_tabs = _resolveComponent("el-tabs");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_tabs, {
    "model-value": _ctx.activeTab,
    onTabChange: _ctx.handleTabChange,
    type: "card",
    class: "tabs"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
      label: _ctx.tabLabels['L2'],
      name: "L2",
      lazy: true
    }, {
      default: _withCtx(() => [!_ctx.connectedWallet ? (_openBlock(), _createBlock(_component_WalletSignIn, {
        key: 0
      })) : (_openBlock(), _createBlock(_component_PolygonTab, {
        key: 1
      }))]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_tab_pane, {
      label: _ctx.tabLabels['L1'],
      name: "L1",
      lazy: true
    }, {
      default: _withCtx(() => [!_ctx.connectedWallet ? (_openBlock(), _createBlock(_component_WalletSignIn, {
        key: 0
      })) : (_openBlock(), _createBlock(_component_EthereumTab, {
        key: 1
      }))]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_tab_pane, {
      label: _ctx.tabLabels['pending'],
      name: "pending",
      lazy: true
    }, {
      default: _withCtx(() => [!_ctx.connectedWallet ? (_openBlock(), _createBlock(_component_WalletSignIn, {
        key: 0
      })) : (_openBlock(), _createBlock(_component_PendingTab, {
        key: 1
      }))]),
      _: 1
    }, 8, ["label"])]),
    _: 1
  }, 8, ["model-value", "onTabChange"])]);
}