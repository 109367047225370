import { defineComponent } from 'vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import BaseDialog from './BaseDialog.vue';
import AzukiSeriouslySays from './AzukiSeriouslySays.vue';
export default defineComponent({
  name: 'LockedGachaponDialog',
  components: {
    ShadowedButton,
    BaseDialog,
    AzukiSeriouslySays
  },
  props: {
    owner: {
      type: Object
    },
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    closeDialog: {
      type: Function
    }
  },
  methods: {
    isTwitterLinkValid() {
      return !!this.owner?.twitterLink;
    },
    isWebsiteLinkValid() {
      return !!this.owner?.websiteLink;
    }
  }
});