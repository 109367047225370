import { defineComponent } from 'vue';
import { roundNumberToLocalString, bigIntToString } from '@/common/utils/format-numbers';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import UnstakeTokenDialog from './UnstakeTokenDialog.vue';
import StakeTokenDialog from './StakeTokenDialog.vue';
import StakingPoolLogo from './StakingPoolLogo.vue';
import { ElMessage } from 'element-plus';
import { connectedWallet, getWalletSigner } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'StakingPool',
  components: {
    ShadowedButton,
    StakeTokenDialog,
    UnstakeTokenDialog,
    StakingPoolLogo
  },
  data() {
    return {
      connectedWallet,
      isStakeTokenDialogVisible: false,
      isUnstakeTokenDialogVisible: false,
      isClaimLoading: false
    };
  },
  props: {
    mustSwitchNetwork: {
      type: Boolean,
      default: false
    },
    stakingPool: {
      type: Object
    },
    walletBalance: {
      type: BigInt,
      default: 0n
    },
    walletApprovedAmount: {
      type: BigInt,
      default: 0n
    }
  },
  computed: {
    canClaim() {
      if (!this.isClaimLoading && this.connectedWallet) {
        return true;
      }
      return false;
    },
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    }
  },
  methods: {
    roundNumberToLocalString,
    formatAndRoundBigInt(number) {
      return bigIntToString(number, this.stakingPool?.metadata.stakingToken.decimals).slice(0, 12);
    },
    toWeeklyRate(rewardRate) {
      const weeklyRewards = 60n * 60n * 24n * 7n * rewardRate;
      return bigIntToString(weeklyRewards, 10);
    },
    async handleClaimClick() {
      if (this.mustSwitchNetwork) {
        this.$emit('switch-network');
        return;
      }
      if (!this.canClaim || !this.connectedWallet) {
        return;
      }
      this.isClaimLoading = true;
      try {
        const walletSigner = await getWalletSigner(this.connectedWallet);
        await this.stakingPool?.claimAll(walletSigner, this.walletAddress);
        this.$emit('token-claimed');
        ElMessage({
          message: 'Claimed!',
          grouping: true,
          type: 'success'
        });
      } catch (e) {
        if (e instanceof Error) {
          ElMessage({
            message: `Couldn't claim: ${e.message}`,
            grouping: true,
            type: 'error'
          });
        }
        console.error(e);
      }
      this.isClaimLoading = false;
    },
    async handleStakeClick() {
      if (this.mustSwitchNetwork) {
        this.$emit('switch-network');
        return;
      }
      this.isStakeTokenDialogVisible = true;
    },
    async handleUnstakeClick() {
      if (this.mustSwitchNetwork) {
        this.$emit('switch-network');
        return;
      }
      this.isUnstakeTokenDialogVisible = true;
    },
    closeStakeTokenDialog() {
      this.isStakeTokenDialogVisible = false;
    },
    closeUnstakeTokenDialog() {
      this.isUnstakeTokenDialogVisible = false;
    }
  }
});