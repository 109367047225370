import { defineComponent } from 'vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import OuterPanel from './OuterPanel.vue';
import InnerPanel from './InnerPanel.vue';
export default defineComponent({
  name: 'GachaponPanel',
  components: {
    ShadowedButton,
    OuterPanel,
    InnerPanel
  },
  data() {
    return {
      isLoading: false,
      spinsAmount: 5
    };
  },
  methods: {
    handlePlay() {
      this.isLoading = true;
      setTimeout(() => {
        this.$emit('play', this.spinsAmount);
        this.isLoading = false;
      }, 500);
    }
  }
});