import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["shadowed-button-container", {
      [`shadow-size-${_ctx.shadowSize}`]: _ctx.shadowSize,
      disabled: _ctx.isDisabled
    }])
  }, [_createVNode(_component_el_button, {
    class: _normalizeClass(["shadowed-button button", {
      pressed: _ctx.isPressed,
      [`theme-${_ctx.theme}`]: _ctx.theme,
      [`shadow-size-${_ctx.shadowSize}`]: _ctx.shadowSize,
      loading: _ctx.isLoading
    }]),
    text: true,
    loading: _ctx.isLoading
  }, {
    default: _withCtx(() => [!_ctx.isLoading ? _renderSlot(_ctx.$slots, "default", {
      key: 0
    }) : _createCommentVNode("", true)]),
    _: 3
  }, 8, ["class", "loading"])], 2);
}