import { defineComponent } from 'vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import BaseDialog from '@/components/Dialogs/BaseDialog.vue';
import AzukiSeriouslySays from '@/components/Dialogs/AzukiSeriouslySays.vue';
export default defineComponent({
  name: 'RemoveGachaponNFTsDialog',
  components: {
    ShadowedButton,
    BaseDialog,
    AzukiSeriouslySays
  },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    closeDialog: {
      type: Function
    }
  }
});