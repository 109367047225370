import { defineComponent } from 'vue';
import BaseDialog from '@/components/Dialogs/BaseDialog.vue';
import ThreeQuarterRoundLoader from '@/components/Loaders/ThreeQuarterRoundLoader.vue';
import { connectedWallet, getWalletSigner } from '@/common/utils/web3-onboard';
import { getL2BlockchainExplorerTxLink } from '@/common/utils/blockchains';
export default defineComponent({
  name: 'AllowlistRestrictionDialog',
  components: {
    BaseDialog,
    ThreeQuarterRoundLoader
  },
  data() {
    return {
      connectedWallet,
      isProcessing: false,
      hasFailed: false,
      isSuccessful: false
    };
  },
  props: {
    gachaponManager: {
      type: Object,
      default: null
    },
    isAllowlistOnly: {
      type: Boolean
    },
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    closeDialog: {
      type: Function
    }
  },
  computed: {
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    },
    isWaitingTransaction() {
      return !this.isProcessing && !this.hasFailed;
    },
    gachaponManagerState() {
      return this.gachaponManager.state;
    },
    lastTransactionHash() {
      return this.gachaponManager.lastTransactionHash;
    },
    transactionLink() {
      return getL2BlockchainExplorerTxLink() + `/${this.gachaponManager.lastTransactionHash}`;
    }
  },
  async created() {
    if (!this.connectedWallet || !this.isDialogVisible || this.isProcessing) {
      return;
    }
    try {
      const walletSigner = await getWalletSigner(this.connectedWallet);
      await this.gachaponManager.setAllowlistOnlyStatus(walletSigner, this.walletAddress, this.isAllowlistOnly);
      this.isSuccessful = true;
      this.$emit('refresh-gachapons');
      this.$emit('update:isAllowlistOnly', this.isAllowlistOnly);
    } catch (e) {
      this.hasFailed = true;
      this.$emit('update:isAllowlistOnly', !this.isAllowlistOnly);
    }
    this.isProcessing = false;
  },
  watch: {
    gachaponManagerState: function (state) {
      if (state === 'transaction-accepted') {
        this.isProcessing = true;
      }
    }
  }
});