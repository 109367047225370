import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "staking-view-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PolygonTab = _resolveComponent("PolygonTab");
  const _component_el_tab_pane = _resolveComponent("el-tab-pane");
  const _component_EthereumTab = _resolveComponent("EthereumTab");
  const _component_el_tabs = _resolveComponent("el-tabs");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_tabs, {
    type: "card",
    class: "tabs"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
      label: "Polygon L2 Staking",
      class: "tab"
    }, {
      default: _withCtx(() => [_createVNode(_component_PolygonTab, {
        stakingPools: _ctx.L2Pools,
        walletBalances: _ctx.L2WalletBalances,
        walletApprovedAmounts: _ctx.L2WalletApprovedAmounts,
        onTokenApproved: _ctx.refreshWalletL2Data,
        onTokenStaked: _ctx.refreshAllL2,
        onTokenUnstaked: _ctx.refreshAllL2,
        onTokenClaimed: _ctx.refreshAllL2
      }, null, 8, ["stakingPools", "walletBalances", "walletApprovedAmounts", "onTokenApproved", "onTokenStaked", "onTokenUnstaked", "onTokenClaimed"])]),
      _: 1
    }), _createVNode(_component_el_tab_pane, {
      label: "ETH Mainnet Staking",
      class: "tab"
    }, {
      default: _withCtx(() => [_createVNode(_component_EthereumTab, {
        stakingPools: _ctx.L1Pools,
        walletBalances: _ctx.L1WalletBalances,
        walletApprovedAmounts: _ctx.L1WalletApprovedAmounts,
        onTokenApproved: _ctx.refreshWalletL1Data,
        onTokenStaked: _ctx.refreshAllL1,
        onTokenUnstaked: _ctx.refreshAllL1,
        onTokenClaimed: _ctx.refreshAllL1
      }, null, 8, ["stakingPools", "walletBalances", "walletApprovedAmounts", "onTokenApproved", "onTokenStaked", "onTokenUnstaked", "onTokenClaimed"])]),
      _: 1
    })]),
    _: 1
  })]);
}