import { cid, cidPath, url, urlOrPath } from 'is-ipfs';

export function extractCIDFromString(string: string): string {
  let startOfHash = -1;

  if (cid(string) || cidPath(string)) {
    return string;
  }

  if (startOfHash === -1 && url(string)) {
    const substring = 'https://ipfs.io/ipfs/';
    startOfHash = string.indexOf(substring);
    if (startOfHash === 0) {
      startOfHash = substring.length;
    }
  }

  if (startOfHash === -1) {
    const substring = '/ipfs/';
    startOfHash = string.indexOf(substring);
    if (startOfHash !== -1) {
      startOfHash = startOfHash + substring.length;
    }
  }

  if (startOfHash === -1) {
    const substring = 'ipfs://';
    startOfHash = string.indexOf(substring);
    if (startOfHash !== -1) {
      startOfHash = startOfHash + substring.length;
    }
  }

  const ipfsPath = string.slice(startOfHash);
  if (startOfHash === -1 && (!cid(ipfsPath) || !cidPath(ipfsPath))) {
    throw new Error(`Can't extract IPFS path from string: ${string}`);
  }

  return ipfsPath;
}

export function isIPFS(uri: string) {
  return cid(uri) || cidPath(uri) || urlOrPath(uri) || uri.indexOf('ipfs://') !== -1;
}
