import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["width", "height"];
const _hoisted_2 = ["fill"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    width: _ctx.width,
    height: _ctx.height,
    viewBox: "0 0 32 32",
    xmlns: "http://www.w3.org/2000/svg"
  }, [_createElementVNode("path", {
    fill: _ctx.color,
    d: "M16 0C7.16333 0 0 7.16333 0 16C0 24.8367 7.16333 32 16 32C24.8367 32 32 24.8367 32 16C32 7.16333 24.8367 0 16 0ZM22.5083 13.1067C22.515 13.2433 22.5167 13.38 22.5167 13.5133C22.5167 17.68 19.3483 22.4817 13.5517 22.4817C11.8388 22.4845 10.1616 21.9926 8.72167 21.065C8.96667 21.095 9.21833 21.1067 9.47333 21.1067C10.95 21.1067 12.3083 20.605 13.3867 19.7583C12.7295 19.7454 12.0928 19.5278 11.5652 19.1359C11.0376 18.7439 10.6454 18.1971 10.4433 17.5717C10.9153 17.6614 11.4014 17.6426 11.865 17.5167C11.1517 17.3724 10.5103 16.986 10.0495 16.4228C9.5887 15.8596 9.33684 15.1544 9.33667 14.4267V14.3883C9.76167 14.6233 10.2483 14.7667 10.765 14.7833C10.0963 14.3382 9.62298 13.6545 9.44174 12.8719C9.26051 12.0893 9.38508 11.2671 9.79 10.5733C10.5816 11.5467 11.5688 12.3429 12.6876 12.9105C13.8065 13.4781 15.032 13.8044 16.285 13.8683C16.1257 13.1922 16.1943 12.4823 16.48 11.8492C16.7658 11.216 17.2527 10.695 17.8651 10.3671C18.4775 10.0392 19.1811 9.92287 19.8665 10.0361C20.5519 10.1494 21.1806 10.4859 21.655 10.9933C22.3603 10.8538 23.0366 10.5951 23.655 10.2283C23.4199 10.9586 22.9278 11.5786 22.27 11.9733C22.8947 11.8981 23.5048 11.7301 24.08 11.475C23.6575 12.1082 23.1252 12.6607 22.5083 13.1067V13.1067Z"
  }, null, 8, _hoisted_2)], 8, _hoisted_1);
}