import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AzukiSeriouslySays = _resolveComponent("AzukiSeriouslySays");
  const _component_ShadowedButton = _resolveComponent("ShadowedButton");
  const _component_router_link = _resolveComponent("router-link");
  const _component_el_checkbox = _resolveComponent("el-checkbox");
  const _component_BaseDialog = _resolveComponent("BaseDialog");
  return _ctx.gachapon ? (_openBlock(), _createBlock(_component_BaseDialog, {
    key: 0,
    class: "standard-gachapon-warning-dialog",
    isDialogVisible: _ctx.isDialogVisible,
    title: "Warning & Disclaimer",
    width: "638px",
    onClose: _ctx.closeDialog
  }, {
    default: _withCtx(() => [_createVNode(_component_AzukiSeriouslySays, null, {
      default: _withCtx(() => [_createTextVNode(" As an open platform, anyone can create a Standard Gacha on Doki Doki and load it with any NFTs. This means that bad actors may load fake NFTs or stolen artwork into their Gacha. Doki Doki is unable to reimburse you for any bad purchases. ")]),
      _: 1
    }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_router_link, {
      class: "to-gachapon-buttons-container",
      to: {
        name: 'gachapon',
        params: {
          address: _ctx.gachapon.address
        }
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_ShadowedButton, {
        shadowSize: "s",
        theme: "violet-white"
      }, {
        default: _withCtx(() => [_createTextVNode("CONTINUE TO GACHAPON")]),
        _: 1
      })]),
      _: 1
    }, 8, ["to"]), _createVNode(_component_el_checkbox, {
      modelValue: _ctx.isChecked,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.isChecked = $event),
      class: "remember-decision-checkbox"
    }, {
      default: _withCtx(() => [_createTextVNode("Don’t show me again")]),
      _: 1
    }, 8, ["modelValue"])])]),
    _: 1
  }, 8, ["isDialogVisible", "onClose"])) : _createCommentVNode("", true);
}