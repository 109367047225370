import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AzukiSays = _resolveComponent("AzukiSays");
  const _component_ShadowedButton = _resolveComponent("ShadowedButton");
  const _component_BaseDialog = _resolveComponent("BaseDialog");
  return _openBlock(), _createBlock(_component_BaseDialog, {
    class: "switch-network-to-polygon-dialog",
    isDialogVisible: _ctx.isDialogVisible,
    title: "Please switch wallet network",
    width: "638px",
    onClose: _ctx.closeDialog
  }, {
    default: _withCtx(() => [_createVNode(_component_AzukiSays, null, {
      default: _withCtx(() => [_createTextVNode(" Please connect to Polygon (Matic) L2 Network to spin! If it’s your first time connecting, you’ll need to add Polygon (Matic) to Metamask first by approving the prompt. ")]),
      _: 1
    }), _createVNode(_component_ShadowedButton, {
      class: "switch-button-container",
      shadowSize: "s",
      theme: "violet-white",
      isLoading: _ctx.isLoading,
      onClick: _ctx.handleSwitch
    }, {
      default: _withCtx(() => [_createTextVNode(" SWITCH ")]),
      _: 1
    }, 8, ["isLoading", "onClick"])]),
    _: 1
  }, 8, ["isDialogVisible", "onClose"]);
}