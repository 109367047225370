import axios from 'axios';

export enum ExchangeSubgraphEndpoint {
  ComethSwap = 'https://api.thegraph.com/subgraphs/name/cometh-game/comethswap',
  UniswapV2 = 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2',
}

/**
 * Get the value in ETH of one LP token.
 * 
 * @param pairAddress 
 * @returns The value in ETH of 1 token
 */
export async function getTokenPairETHValue(pairAddress: string, exchangeEndpoint: ExchangeSubgraphEndpoint): Promise<number> {
  const request = await axios.post(
    exchangeEndpoint,
    {
      query: `
        query pair($address: String!) {
          pair(id: $address) {
            trackedReserveETH,
            totalSupply
          }
        }
      `,
      variables: {
        address: pairAddress.toLowerCase(),
      }
    }
  );

  const pairData = request.data.data.pair;
  if (pairData === null) {
    return 0;
  }

  return +pairData.trackedReserveETH / +pairData.totalSupply;
}