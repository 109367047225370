import {PlayedRound, RoundsData} from '@/common/api/rounds/types';
import httpClient from '@/common/utils/http-client';

const API_URL = process.env.NODE_ENV === 'development'
  ? 'https://api.thegraph.com/subgraphs/name/kirienzoeth/dokidoki-gachapons-mumbai'
  : process.env.VUE_APP_USE_BACKUP_SUBGRAPH === '1'
    ? 'https://graph.dokidoki.com/subgraphs/name/DokiDokiFinance/gachapon-subgraph'
    : 'https://api.thegraph.com/subgraphs/name/kirienzoeth/dokidoki-gachapons';

export async function getPlayedRounds(walletAddress: string, skip: number): Promise<PlayedRound[]> {
  const response = await httpClient.post(API_URL, {
    query: `{
      rounds(where: { player: "${walletAddress.toLowerCase()}" }, skip: ${skip}, first: 20, orderBy: createdAt, orderDirection: desc) {
        id
        timesPlayed
        claimHash
        paymentHash
        status
        paid
        createdAt
        gachapon {
          id
          title
          currency {
            id
            symbol
            name
            decimals
            isWrappedNativeToken
            isSupported
          }
        }
        prizes
      }
    }`
  });

  const playedRounds: PlayedRound[] = [];
  if (response.data) {
    const roundsData: RoundsData = response.data;
    for (const roundData of roundsData.rounds) {
      const playedRound = {
        id: roundData.id,
        timesPlayed: roundData.timesPlayed,
        status: roundData.status,
        claimTransactionHash: roundData.claimHash,
        rollTransactionHash: roundData.paymentHash,
        prizes: roundData.prizes,
        paid: BigInt(roundData.paid),
        gachaponTitle: roundData.gachapon.title,
        gachaponAddress: roundData.gachapon.id,
        createdAt: new Date(roundData.createdAt * 1000),
        currency: {
          address: roundData.gachapon.currency.id,
          symbol: roundData.gachapon.currency.symbol,
          name: roundData.gachapon.currency.name,
          decimals: roundData.gachapon.currency.decimals,
          isWrappedNativeToken: roundData.gachapon.currency.isWrappedNativeToken,
          isSupported: roundData.gachapon.currency.isSupported
        }
      };

      playedRounds.push(playedRound);
    }
  }

  return playedRounds;
}
