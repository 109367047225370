import { CoingeckoPrices, getTokensPrices } from '../../api/coingecko-api';
import { getL1LPTokenAddressToNativeTokens, WETHL1TokenAddress } from '../../api/erc20-token-api';
import { getTokenPairETHValue, ExchangeSubgraphEndpoint } from '../../api/uniswap-v2-subgraph-api';
import { StakingPool, StakingPoolMetadata, Reward } from './types';
import ERC20Token from '@/common/types/ERC20Token';
import {formatEther, parseUnits, Signer} from 'ethers';
import { DokidokiStaking__factory } from '@/contracts';
import { getL1Provider } from '@/common/utils/ethers';

const provider = getL1Provider();

export default class DokiDokiABIStakingPool implements StakingPool {
  metadata: StakingPoolMetadata;
  totalStakedAmount = 0n;
  APR = 0;
  stakedAmount = 0n;
  rewardableTokens: ERC20Token[] = [];
  rewardableRates: bigint[] = [];
  rewards: Reward[] = [];

  constructor(metadata: StakingPoolMetadata, rewardableTokens: ERC20Token[]) {
    this.metadata = metadata;
    this.rewardableTokens = rewardableTokens;
  }

  async claimAll(signer: Signer, userAddress: string): Promise<void> {
    const contract = DokidokiStaking__factory.connect(this.metadata.address, signer);
    const claimableAmount = await contract.getRewardsAmount(userAddress);

    const transaction = await contract.claim(claimableAmount, {
      from: userAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: null
    });

    await transaction.wait();
  }

  async stake(signer: Signer, userAddress: string, amount: bigint): Promise<void> {
    const contract = DokidokiStaking__factory.connect(this.metadata.address, signer);

    const transaction = await contract.stake(amount, {
      from: userAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: null
    });

    await transaction.wait();
  }

  async unstake(signer: Signer, userAddress: string, amount: bigint): Promise<void> {
    const contract = DokidokiStaking__factory.connect(this.metadata.address, signer);

    const transaction = await contract.withdraw(amount, {
      from: userAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      gasPrice: null
    });

    await transaction.wait();
  }

  async fetchTotalStakedAmount(): Promise<bigint> {
    const contract = DokidokiStaking__factory.connect(this.metadata.address, provider);

    return contract.totalSupply();
  }

  async fetchRewardableTokens(): Promise<ERC20Token[]> {
    return this.rewardableTokens;
  }

  async fetchRewardableRates(): Promise<bigint[]> {
    const contract = DokidokiStaking__factory.connect(this.metadata.address, provider);

    return [await contract.rewardRate()];
  }
  protected async getPoolTokensPrices(): Promise<CoingeckoPrices> {
    const lpTokenNativeAssets = getL1LPTokenAddressToNativeTokens(this.metadata.stakingToken.address);
    const stakingTokensAddresses = (lpTokenNativeAssets.length !== 0 ? lpTokenNativeAssets : [this.metadata.stakingToken.address]);
    const rewardableTokensAddresses = this.rewardableTokens.map((token) => token.address);

    return getTokensPrices([WETHL1TokenAddress, ...stakingTokensAddresses, ...rewardableTokensAddresses]);
  }

  protected async getStakedTokensUSDValue(tokenPrices: CoingeckoPrices): Promise<number> {
    const lpTokenNativeAssets = getL1LPTokenAddressToNativeTokens(this.metadata.stakingToken.address);
    const totalStakedAmountToEther = Number(parseUnits(this.totalStakedAmount.toString(), 'ether'));
    if (lpTokenNativeAssets.length === 0) {
      return (tokenPrices[this.metadata.stakingToken.address.toLowerCase()]?.usd ?? 0) * totalStakedAmountToEther;
    }

    const lpTokenETHValue= await getTokenPairETHValue(this.metadata.stakingToken.address, ExchangeSubgraphEndpoint.UniswapV2);

    return (tokenPrices[WETHL1TokenAddress.toLowerCase()]?.usd ?? 0) * lpTokenETHValue * totalStakedAmountToEther;
  }

  protected getRewardsUSDValue(tokenPrices: CoingeckoPrices): number {
    const sum =  this.rewardableTokens.reduce((sum, token, index) => {
      const rewardRate = this.rewardableRates[index] ?? 0n;
      const tokenPrice = BigInt(Math.round((tokenPrices[token.address.toLowerCase()]?.usd ?? 0) * 10000));
      return sum + (rewardRate * tokenPrice / 10000n);
    }, 0n);

    return +formatEther(sum);
  }

  async fetchAPR(): Promise<number> {
    if (this.metadata.isDepreciated) {
      return 0;
    }

    try {
      const tokensUSDValue = await this.getPoolTokensPrices();
      const stakedTokensUSDValue = await this.getStakedTokensUSDValue(tokensUSDValue);
      const rewardsUSDValue = this.getRewardsUSDValue(tokensUSDValue);

      if (stakedTokensUSDValue === 0) {
        return 0;
      }

      return (rewardsUSDValue * 60 * 60 * 24 * 365.25) / stakedTokensUSDValue * 100;
    } catch(e) {
      console.error('Fetch APR failed', e);
    }

    return 0;
  }

  async loadPoolData(): Promise<void> {
    const promises = [this.fetchTotalStakedAmount(), this.fetchRewardableRates()];

    const [totalStakedAmountResult, rewardableRatesResult] = await Promise.allSettled(promises);

    if (totalStakedAmountResult.status === 'fulfilled') {
      this.totalStakedAmount = totalStakedAmountResult.value as bigint;
    }

    if (rewardableRatesResult.status === 'fulfilled') {
      this.rewardableRates = rewardableRatesResult.value as bigint[];
    }

    this.APR = await this.fetchAPR();
  }

  async fetchStakedAmount(userAddress: string): Promise<bigint> {
    const contract = DokidokiStaking__factory.connect(this.metadata.address, provider);

    return contract.balanceOf(userAddress);
  }

  async fetchRewards(userAddress: string): Promise<Reward[]> {
    const rewards: Reward[] = [];
    if (this.totalStakedAmount === 0n || !this.rewardableTokens.length || !this.rewardableRates.length) {
      return rewards;
    }

    const contract = DokidokiStaking__factory.connect(this.metadata.address, provider);
    const claimable = await contract.getRewardsAmount(userAddress);
    if (claimable) {
      const oneMillion = 1000000n;
      const userRewardRatesCut = oneMillion * this.stakedAmount / this.totalStakedAmount;

      for (let i = 0; i < this.rewardableTokens.length; i++) {
        let userRewardRate = 0n;
        if (!this.metadata.isDepreciated) {
          userRewardRate = this.rewardableRates[i] * userRewardRatesCut / oneMillion;
        }

        if (!claimable) {
          continue;
        }

        rewards.push({ token: this.rewardableTokens[i], claimable: claimable, rate: userRewardRate });
      }
    }

    return rewards;
  }

  async loadUserData(userAddress: string): Promise<void> {
    this.stakedAmount = await this.fetchStakedAmount(userAddress);
    this.rewards = await this.fetchRewards(userAddress);
  }
}
