import ERC721Token from './ERC721Token';
import ERC1155Token from './ERC1155Token';
import {TransferableNFT} from '@/common/contracts/nfts/types';
import OwnedNFT from '@/common/types/OwnedNFT';

export function translateOwnedNFTtoTransferableNFT(ownedNft: OwnedNFT): TransferableNFT|null {
  if (ownedNft.standard === 'ERC721') {
    return new ERC721Token(ownedNft);
  } else if (ownedNft.standard === 'ERC1155') {
    return new ERC1155Token(ownedNft);
  }

  return null;
}

