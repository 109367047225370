import { defineComponent } from 'vue';
import BaseDialog from '@/components/Dialogs/BaseDialog.vue';
export default defineComponent({
  name: 'ErrorsDetailDialog',
  components: {
    BaseDialog
  },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    closeDialog: {
      type: Function
    },
    errors: {
      type: Object
    }
  }
});