import { defineComponent } from 'vue';
import ShadowedButton from '@/components/GachaButton/ShadowedButton.vue';
import QuantityInput from '@/components/Inputs/QuantityInput.vue';
import BaseDialog from '@/components/Dialogs/BaseDialog.vue';
import { ElMessage } from 'element-plus';
import { connectedWallet, getWalletSigner } from '@/common/utils/web3-onboard';
export default defineComponent({
  name: 'TransferNFTToGachaponDialog',
  components: {
    ShadowedButton,
    QuantityInput,
    BaseDialog
  },
  data() {
    return {
      connectedWallet,
      LOAD_INPUT_LIMIT: 800,
      quantity: 0,
      loading: false
    };
  },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    closeDialog: {
      type: Function
    },
    ownedNft: {
      type: Object,
      default: null
    },
    gachaponManager: {
      type: Object
    }
  },
  computed: {
    isERC1155() {
      return this.ownedNft.standard === 'ERC1155';
    },
    maxQuantity() {
      if (this.isERC1155) {
        return this.ownedNft.balance < this.LOAD_INPUT_LIMIT ? this.ownedNft.balance : this.LOAD_INPUT_LIMIT;
      }
      return 1;
    },
    walletAddress() {
      return this.connectedWallet?.accounts[0]?.address ?? '';
    }
  },
  methods: {
    async handleLoad() {
      if (this.quantity <= 0 || !this.connectedWallet || this.loading || !this.gachaponManager) {
        return;
      }
      this.loading = true;
      try {
        const walletSigner = await getWalletSigner(this.connectedWallet);
        await this.gachaponManager.loadNft(walletSigner, this.walletAddress, this.ownedNft, this.quantity);
        ElMessage({
          message: 'NFT loaded!',
          grouping: true,
          type: 'success'
        });
        this.$emit('nft-loaded');
      } catch (e) {
        ElMessage({
          message: 'Operation failed',
          grouping: true,
          type: 'error'
        });
        console.log(e);
      }
      this.loading = false;
    }
  },
  async created() {
    this.quantity = !this.isERC1155 ? 1 : 0;
  }
});