import { defineComponent } from 'vue';
import Carousel from '@/components/Carousel/index.vue';
import OwnerPanel from './OwnerPanel.vue';
import GachaponPanel from './GachaponPanel.vue';
import NFTsPanel from './NFTsPanel.vue';
import PrizesDialog from './PrizesDialog.vue';
export default defineComponent({
  name: 'DemoGachapon',
  components: {
    OwnerPanel,
    GachaponPanel,
    NFTsPanel,
    Carousel,
    PrizesDialog
  },
  data() {
    return {
      windowWidth: window?.innerWidth > 0 ? window.innerWidth : 0,
      nfts: ['/gachapons/nfts/0.png', '/gachapons/nfts/1.png', '/gachapons/nfts/2.png', '/gachapons/nfts/3.png', '/gachapons/nfts/4.png', '/gachapons/nfts/5.png'],
      prizes: new Array()
    };
  },
  computed: {
    carouselType() {
      return this.windowWidth >= 768 ? 'card' : '';
    }
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    handlePlay(spinsAmount) {
      const prizesIndexes = Array.from({
        length: spinsAmount
      }, () => Math.floor(Math.random() * 5));
      this.prizes = prizesIndexes.map(index => this.nfts[index]);
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  }
});