import { Loading as ElIconLoading, Picture as ElIconPicture } from '@element-plus/icons-vue';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'NFT',
  components: {
    ElIconLoading,
    ElIconPicture
  },
  data() {
    return {
      showTooltip: false
    };
  },
  props: {
    gachapon: {
      type: Object
    },
    nft: {
      type: Object
    }
  },
  computed: {
    dropRate() {
      if (!this.nft || !this.gachapon || !this.gachapon.prizesAmountLeft) {
        return '0';
      }
      return (this.nft.balance / this.gachapon.prizesAmountLeft * 100).toFixed(2);
    }
  }
});