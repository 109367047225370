import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "demo-gachapon-container"
};
const _hoisted_2 = {
  class: "desktop hidden-md-and-down"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_OwnerPanel = _resolveComponent("OwnerPanel");
  const _component_GachaponPanel = _resolveComponent("GachaponPanel");
  const _component_NFTsPanel = _resolveComponent("NFTsPanel");
  const _component_el_carousel_item = _resolveComponent("el-carousel-item");
  const _component_Carousel = _resolveComponent("Carousel");
  const _component_PrizesDialog = _resolveComponent("PrizesDialog");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_OwnerPanel, {
    class: "panel"
  }), _createVNode(_component_GachaponPanel, {
    class: "panel",
    onPlay: _ctx.handlePlay
  }, null, 8, ["onPlay"]), _createVNode(_component_NFTsPanel, {
    class: "panel",
    nfts: _ctx.nfts
  }, null, 8, ["nfts"])]), _createVNode(_component_Carousel, {
    interval: 0,
    trigger: "click",
    indicators: "outside",
    initialIndex: 1,
    type: _ctx.carouselType,
    class: "hidden-lg-and-up"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_carousel_item, {
      key: "owner"
    }, {
      default: _withCtx(() => [_createVNode(_component_OwnerPanel, {
        class: "panel"
      })]),
      _: 1
    }), _createVNode(_component_el_carousel_item, {
      key: "gachapon"
    }, {
      default: _withCtx(() => [_createVNode(_component_GachaponPanel, {
        class: "panel",
        onPlay: _ctx.handlePlay
      }, null, 8, ["onPlay"])]),
      _: 1
    }), _createVNode(_component_el_carousel_item, {
      key: "nfts"
    }, {
      default: _withCtx(() => [_createVNode(_component_NFTsPanel, {
        class: "panel",
        nfts: _ctx.nfts
      }, null, 8, ["nfts"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["type"]), _ctx.prizes.length > 0 ? (_openBlock(), _createBlock(_component_PrizesDialog, {
    key: 0,
    nfts: _ctx.prizes,
    isVisible: _ctx.prizes.length > 0,
    onClose: () => this.prizes = []
  }, null, 8, ["nfts", "isVisible", "onClose"])) : _createCommentVNode("", true)]);
}