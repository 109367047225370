import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "wide-content"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_OwnerPanel = _resolveComponent("OwnerPanel");
  const _component_GachaponPanel = _resolveComponent("GachaponPanel");
  const _component_FeaturedNFTsPanel = _resolveComponent("FeaturedNFTsPanel");
  const _component_el_carousel_item = _resolveComponent("el-carousel-item");
  const _component_Carousel = _resolveComponent("Carousel");
  const _component_PrizesDialog = _resolveComponent("PrizesDialog");
  return _ctx.playableGachapon ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "gachapon-view-container",
    style: _normalizeStyle(_ctx.customStyle)
  }, [_createElementVNode("div", {
    class: "content",
    style: _normalizeStyle(`background: url(${_ctx.displayDefaultBackgroundImage ? _ctx.defaultBackgroundImage : _ctx.backgroundImage}) center center no-repeat;`)
  }, [this.windowWidth >= 1400 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_OwnerPanel, {
    gachapon: _ctx.playableGachapon.gachapon,
    ownerAddress: _ctx.playableGachapon.gachapon.ownerAddress
  }, null, 8, ["gachapon", "ownerAddress"]), _createVNode(_component_GachaponPanel, {
    playableGachapon: _ctx.playableGachapon,
    prizes: _ctx.prizes,
    "onUpdate:prizes": _cache[0] || (_cache[0] = $event => _ctx.prizes = $event),
    customStyle: _ctx.customStyle
  }, null, 8, ["playableGachapon", "prizes", "customStyle"]), _createVNode(_component_FeaturedNFTsPanel, {
    gachapon: _ctx.playableGachapon.gachapon,
    customStyle: _ctx.customStyle
  }, null, 8, ["gachapon", "customStyle"])])) : (_openBlock(), _createBlock(_component_Carousel, {
    key: 1,
    interval: 0,
    trigger: "click",
    indicators: "outside",
    initialIndex: 1,
    type: _ctx.carouselType
  }, {
    default: _withCtx(() => [_createVNode(_component_el_carousel_item, {
      key: "owner"
    }, {
      default: _withCtx(() => [_createVNode(_component_OwnerPanel, {
        gachapon: _ctx.playableGachapon.gachapon,
        ownerAddress: _ctx.playableGachapon.gachapon.ownerAddress
      }, null, 8, ["gachapon", "ownerAddress"])]),
      _: 1
    }), _createVNode(_component_el_carousel_item, {
      key: "gachapon"
    }, {
      default: _withCtx(() => [_createVNode(_component_GachaponPanel, {
        playableGachapon: _ctx.playableGachapon,
        prizes: _ctx.prizes,
        "onUpdate:prizes": _cache[1] || (_cache[1] = $event => _ctx.prizes = $event),
        customStyle: _ctx.customStyle
      }, null, 8, ["playableGachapon", "prizes", "customStyle"])]),
      _: 1
    }), _createVNode(_component_el_carousel_item, {
      key: "nfts"
    }, {
      default: _withCtx(() => [_createVNode(_component_FeaturedNFTsPanel, {
        gachapon: _ctx.playableGachapon.gachapon,
        customStyle: _ctx.customStyle
      }, null, 8, ["gachapon", "customStyle"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["type"]))], 4), _ctx.prizes.length > 0 ? (_openBlock(), _createBlock(_component_PrizesDialog, {
    key: 0,
    nfts: _ctx.prizes,
    isVisible: _ctx.prizes.length > 0,
    onClose: _ctx.closePrizesDialog,
    playableGachapon: _ctx.playableGachapon
  }, null, 8, ["nfts", "isVisible", "onClose", "playableGachapon"])) : _createCommentVNode("", true), _createElementVNode("img", {
    src: _ctx.backgroundImage,
    onError: _cache[2] || (_cache[2] = $event => _ctx.displayDefaultBackgroundImage = true),
    style: {
      "display": "none"
    }
  }, null, 40, _hoisted_2)], 4)) : _createCommentVNode("", true);
}