import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "music-container"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MusicNoteIcon = _resolveComponent("MusicNoteIcon");
  const _component_ShadowedButton = _resolveComponent("ShadowedButton");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("audio", {
    ref: "music",
    src: _ctx.playDefaultMusic ? _ctx.defaultMusic : _ctx.music,
    onError: _cache[0] || (_cache[0] = $event => _ctx.playDefaultMusic = true)
  }, null, 40, _hoisted_2), _createVNode(_component_ShadowedButton, {
    theme: "custom",
    class: "play-button",
    onClick: _ctx.togglePlayMusic,
    isDisabled: !_ctx.isPlaying
  }, {
    default: _withCtx(() => [_createVNode(_component_MusicNoteIcon, {
      class: "icon",
      width: "22",
      height: "32",
      isDisabled: !_ctx.isPlaying
    }, null, 8, ["isDisabled"])]),
    _: 1
  }, 8, ["onClick", "isDisabled"])]);
}