import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "prize"
};
const _hoisted_2 = {
  class: "media-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_image = _resolveComponent("el-image");
  const _component_NFTVisual = _resolveComponent("NFTVisual");
  return _ctx.nft ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createElementVNode("div", null, [!_ctx.displayDefaultInitialState ? (_openBlock(), _createBlock(_component_el_image, {
    key: 0,
    class: "initial-state",
    src: _ctx.initialStateImage,
    onClick: _cache[0] || (_cache[0] = $event => _ctx.openPrize()),
    onError: _cache[1] || (_cache[1] = $event => _ctx.displayDefaultInitialState = true)
  }, null, 8, ["src"])) : (_openBlock(), _createBlock(_component_el_image, {
    key: 1,
    class: "initial-state",
    src: _ctx.defaultInitialStateImage,
    onClick: _cache[2] || (_cache[2] = $event => _ctx.openPrize())
  }, null, 8, ["src"]))], 512), [[_vShow, _ctx.currentState === 'initial']]), _withDirectives(_createElementVNode("div", null, [!_ctx.displayDefaultAnimatedState ? (_openBlock(), _createBlock(_component_el_image, {
    key: 0,
    class: "animated-state",
    src: _ctx.animatedStateImage,
    onError: _cache[3] || (_cache[3] = $event => _ctx.displayDefaultAnimatedState = true)
  }, null, 8, ["src"])) : (_openBlock(), _createBlock(_component_el_image, {
    key: 1,
    class: "animated-state",
    src: _ctx.defaultAnimatedStateImage
  }, null, 8, ["src"]))], 512), [[_vShow, _ctx.currentState === 'animated']]), _withDirectives(_createElementVNode("div", {
    class: "final-state",
    onClick: _cache[4] || (_cache[4] =
    //@ts-ignore
    (...args) => _ctx.openViewer && _ctx.openViewer(...args))
  }, [_createVNode(_component_el_image, {
    src: _ctx.nft.visual.previewSource,
    alt: "prize",
    fit: "contain"
  }, null, 8, ["src"])], 512), [[_vShow, _ctx.currentState === 'final']]), _ctx.isViewerOpened ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "viewer",
    onClick: _cache[5] || (_cache[5] =
    //@ts-ignore
    (...args) => _ctx.closeViewer && _ctx.closeViewer(...args))
  }, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_NFTVisual, {
    source: _ctx.nft.visual.source,
    isImage: _ctx.nft.visual.type === 'image',
    isVideo: _ctx.nft.visual.type === 'video'
  }, null, 8, ["source", "isImage", "isVideo"])])])) : _createCommentVNode("", true)])) : _createCommentVNode("", true);
}