import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "pools-list polygon-tab"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_StakingPool = _resolveComponent("StakingPool");
  const _component_SwitchNetworkToPolygonDialog = _resolveComponent("SwitchNetworkToPolygonDialog");
  return _ctx.stakingPools.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stakingPools, (stakingPool, index) => {
    return _openBlock(), _createBlock(_component_StakingPool, _mergeProps({
      key: index,
      stakingPool: stakingPool,
      walletBalance: _ctx.walletBalances[index],
      walletApprovedAmount: _ctx.walletApprovedAmounts[index],
      mustSwitchNetwork: !_ctx.isWalletConnectedToPolygon
    }, _ctx.$attrs, {
      onSwitchNetwork: _ctx.openSwitchNetworkDialog
    }), null, 16, ["stakingPool", "walletBalance", "walletApprovedAmount", "mustSwitchNetwork", "onSwitchNetwork"]);
  }), 128)), _createVNode(_component_SwitchNetworkToPolygonDialog, {
    isDialogVisible: _ctx.isSwitchNetworkDialogVisible,
    closeDialog: _ctx.closeSwitchNetworkDialog
  }, null, 8, ["isDialogVisible", "closeDialog"])])) : _createCommentVNode("", true);
}