export const L1_ETHEREUM_COLLECTION_ADDRESS = '0x7cdc0421469398e0f3aa8890693d86c840ac8931';
export const L1_GOERLI_COLLECTION_ADDRESS = '0x18699945abdcf30bfa7f67b5385c7d06ae54cfdd';
export const L2_POLYGON_COLLECTION_ADDRESS = '0x2d0d9b4075e231bff33141d69df49ffcf3be7642';
export const L2_MUMBAI_COLLECTION_ADDRESS = '0x6674d552c61861e89c603af01775f3514801043a';


export function isAddressDokidokiCollection(collectionAddress: string): boolean {
  if (!collectionAddress) {
    return false;
  }

  const nftCollection = collectionAddress.toLowerCase();

  return nftCollection === getDokidokiL1CollectionAddress() || nftCollection === getDokidokiL2CollectionAddress();
}

export function getDokidokiL1CollectionAddress() {
  return process.env.NODE_ENV == 'production' ? L1_ETHEREUM_COLLECTION_ADDRESS : L1_GOERLI_COLLECTION_ADDRESS;
}

export function getDokidokiL2CollectionAddress() {
  return process.env.NODE_ENV == 'production' ? L2_POLYGON_COLLECTION_ADDRESS : L2_MUMBAI_COLLECTION_ADDRESS;
}
