import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createBlock(_component_el_dialog, {
    class: "dialog-wrapper",
    "model-value": _ctx.isDialogVisible,
    title: _ctx.title,
    width: _ctx.width,
    "destroy-on-close": true,
    "append-to-body": true,
    "before-close": _ctx.onClose
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3
  }, 8, ["model-value", "title", "width", "before-close"]);
}