import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_el_main = _resolveComponent("el-main");
  const _component_el_container = _resolveComponent("el-container");
  return _openBlock(), _createBlock(_component_el_container, {
    id: "main-container",
    direction: "vertical",
    onScroll: _ctx.runOnScroll
  }, {
    default: _withCtx(() => [_createVNode(_component_el_container, {
      id: "content-container",
      direction: "horizontal"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_main, {
        id: "content",
        onClick: _ctx.closeAside
      }, {
        default: _withCtx(() => [(_openBlock(), _createBlock(_component_router_view, {
          key: _ctx.$route.fullPath
        }, {
          default: _withCtx(({
            Component
          }) => [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_KeepAlive, {
            key: 0
          }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1024)) : (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
            key: 1
          }))]),
          _: 1
        }))]),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["onScroll"]);
}