import { defineComponent } from 'vue';
import Currency from '@/components/Icons/Currency.vue';
import { getL1LPTokenAddressToNativeTokens, getL1TokenSymbol, getL2LPTokenAddressToNativeTokens, getL2TokenSymbol } from '@/common/api/erc20-token-api';
import { getL2BlockchainId, L1_MAINNET_ID } from '@/common/utils/blockchains';
export default defineComponent({
  name: 'StakingPoolLogo',
  components: {
    Currency
  },
  props: {
    stakingPool: {
      type: Object
    }
  },
  computed: {
    tokens() {
      let tokenSymbols = [];
      if (!this.stakingPool) {
        return tokenSymbols;
      }
      const stakingTokenAddress = this.stakingPool.metadata.stakingToken.address;
      if (this.stakingPool.metadata.chainId === L1_MAINNET_ID) {
        tokenSymbols = getL1LPTokenAddressToNativeTokens(stakingTokenAddress).map(getL1TokenSymbol);
      } else if (this.stakingPool.metadata.chainId === getL2BlockchainId()) {
        tokenSymbols = getL2LPTokenAddressToNativeTokens(stakingTokenAddress).map(getL2TokenSymbol);
      }
      return tokenSymbols.length > 0 ? tokenSymbols : [this.stakingPool.metadata.stakingToken.symbol];
    }
  }
});