import { defineComponent } from 'vue';
import NFTVisual from '@/components/NFT/NFTVisual.vue';
import NFT from './NFT.vue';
import InnerPanel from './InnerPanel.vue';
import OuterPanel from './OuterPanel.vue';
import { getGachaponNftsByGachapon } from '@/common/api/nfts';
import { truncateMiddle, truncate } from '@/common/utils/truncate';
export default defineComponent({
  name: 'FeaturedNFTsPanel',
  components: {
    NFT,
    InnerPanel,
    OuterPanel,
    NFTVisual
  },
  data() {
    return {
      showNft: false,
      nftToShow: 0,
      pageSize: 100,
      pageIndex: 0,
      nfts: [],
      isLoading: false,
      fetchedAllNfts: false
    };
  },
  props: {
    gachapon: {
      type: Object
    },
    customStyle: {
      type: Object
    }
  },
  computed: {
    dropRate() {
      if (!this.nfts[this.nftToShow] || !this.gachapon || !this.gachapon.prizesAmountLeft) {
        return '0';
      }
      return (this.nfts[this.nftToShow].balance / this.gachapon.prizesAmountLeft * 100).toFixed(2);
    },
    openseaL2CollectionUrl() {
      if (!this.nfts[this.nftToShow]) {
        return '';
      }
      return `https://opensea.io/assets/matic/${this.nfts[this.nftToShow].collectionAddress}`;
    },
    disabledScrolling() {
      return this.isLoading || this.fetchedAllNfts;
    }
  },
  methods: {
    truncate,
    truncateMiddle,
    async fetchNFTs() {
      if (!this.gachapon || this.disabledScrolling) {
        return;
      }
      this.isLoading = true;
      const nfts = await getGachaponNftsByGachapon(this.gachapon, this.pageIndex * this.pageSize, this.pageSize);
      this.nfts = [...this.nfts, ...nfts];
      this.isLoading = false;
      this.pageIndex++;
      this.fetchedAllNfts = nfts.length !== this.pageSize;
    },
    previousNft() {
      if (!this.nftToShow) {
        return;
      }
      this.nftToShow--;
    },
    nextNft() {
      if (this.nftToShow >= this.nfts?.length) {
        return;
      }
      this.nftToShow++;
    },
    expandNft(nftIndex) {
      this.nftToShow = nftIndex;
      this.showNft = true;
    },
    closeNft() {
      this.showNft = false;
    }
  },
  async created() {
    await this.fetchNFTs();
  }
});