import Gachapon from '@/common/types/Gachapon';
import {GachaponManager} from './types';
import LegacyGachaponManager from './LegacyGachaponManager';
import VRFGachaponManager from './VRFGachaponManager';
import { LEGACY_GACHAPON_VERSION_NUMBER, VRF_GACHAPON_VERSION_NUMBER } from '@/common/contracts/gachapons/const';


export async function getGachaponManagersIndexedByGachaponAddress(gachapons: Gachapon[]): Promise<Record<string, GachaponManager>> {
  const gachaponManagers: Record<string, GachaponManager> = {};
  for (const gachapon of gachapons) {
    if (gachapon.version === LEGACY_GACHAPON_VERSION_NUMBER) {
      gachaponManagers[gachapon.address] = new LegacyGachaponManager(gachapon);
    } else if (gachapon.version >= VRF_GACHAPON_VERSION_NUMBER) {
      gachaponManagers[gachapon.address] = new VRFGachaponManager(gachapon);
    }
  }

  return gachaponManagers;
}
